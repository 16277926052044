import React, { Component } from 'react'

import { debounce } from 'lodash'

class ResponsiveWrapper extends Component {
	constructor(props) {
		super(props)

		this.state = {
			width: null,
			height: null,
			offsetX: null,
			offsetY: null,
			expanded: props.expanded,
		}

		this.fitParentContainer = this.fitParentContainer.bind(this)
	}

	componentDidMount() {
		this.fitParentContainer()
		setTimeout(this.fitParentContainer, 25)
		window.addEventListener('resize', this.fitParentContainer)
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.fitParentContainer)
	}

	componentWillReceiveProps(props) {
		if (props.expanded !== this.state.expanded) {
			this.fitParentContainer(true)
			setTimeout(this.fitParentContainer, 25)
			this.setState({ expanded: props.expanded })
		}
	}

	fitParentContainer = debounce((force) => {
		const { width, height, offsetX, offsetY } = this.state

		const box = this.container.getBoundingClientRect()

		const currentwidth = box.width
		const currentheight = box.height
		const currentOffsetX = box.x
		const currentOffsetY = box.y

		const shouldResize =
			width !== currentwidth ||
			height !== currentheight ||
			offsetX !== currentOffsetX ||
			offsetY !== currentOffsetY ||
			force

		//console.log('shouldResize', shouldResize)

		if (shouldResize) {
			//console.log(this.container.getBoundingClientRect());
			this.setState({
				width: currentwidth,
				height: currentheight,
				offsetX: currentOffsetX,
				offsetY: currentOffsetY,
			})
		}
	}, 20)

	renderComponent() {
		return React.cloneElement(this.props.children, {
			width: this.state.height,
			height: this.state.height,
			offsetX: this.state.width ? this.state.offsetX : this.state.offsetX - this.state.height / 2,
			offsetY: this.state.offsetY,
			expanded: this.state.expanded,
		})
	}

	render() {
		const { width, height } = this.state
		const shouldRender = width !== null && height !== null

		return (
			<div
				ref={(el) => {
					this.container = el
				}}
				className={this.props.className || 'Responsive-wrapper'}
			>
				{shouldRender && this.renderComponent()}
			</div>
		)
	}
}

export default ResponsiveWrapper
