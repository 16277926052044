import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSJSON: string;
};


export type Indicator = {
   __typename?: 'Indicator';
  createdAt?: Maybe<Scalars['String']>;
  createdBy: Scalars['String'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  trend?: Maybe<Scalars['String']>;
  projectId: Scalars['String'];
  scenarios?: Maybe<Scalars['AWSJSON']>;
};

export type Constraint = {
   __typename?: 'Constraint';
  createdAt?: Maybe<Scalars['String']>;
  createdBy: Scalars['String'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  trend?: Maybe<Scalars['String']>;
  projectId: Scalars['String'];
  scenarios?: Maybe<Scalars['AWSJSON']>;
};

export type Scenario = {
   __typename?: 'Scenario';
  createdAt?: Maybe<Scalars['String']>;
  createdBy: Scalars['String'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  projectId: Scalars['String'];
};

export type Stakefactor = {
   __typename?: 'Stakefactor';
  createdAt?: Maybe<Scalars['String']>;
  createdBy: Scalars['String'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  impact?: Maybe<Scalars['String']>;
  trend?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  projectId: Scalars['String'];
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
  links?: Maybe<Array<Maybe<StakefactorLink>>>;
  impactedIndicators?: Maybe<Array<Maybe<StakefactorIndicatorImpact>>>;
  impactedConstraints?: Maybe<Array<Maybe<StakefactorConstraintImpact>>>;
  comments?: Maybe<Array<Maybe<StakefactorComment>>>;
  scenarios?: Maybe<Scalars['AWSJSON']>;
};

export type StakefactorLink = {
   __typename?: 'StakefactorLink';
  createdAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  originId: Scalars['String'];
  targetId: Scalars['String'];
  id: Scalars['ID'];
  strength?: Maybe<Scalars['Int']>;
};

export type StakefactorComment = {
   __typename?: 'StakefactorComment';
  createdAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  commentedAt?: Maybe<Scalars['String']>;
  stakefactorId: Scalars['String'];
  id: Scalars['ID'];
};

export type StakefactorIndicatorImpact = {
   __typename?: 'StakefactorIndicatorImpact';
  createdAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  strength?: Maybe<Scalars['Int']>;
  stakefactorId: Scalars['String'];
  indicatorId: Scalars['String'];
  id: Scalars['ID'];
};

export type StakefactorConstraintImpact = {
   __typename?: 'StakefactorConstraintImpact';
  createdAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  strength?: Maybe<Scalars['Int']>;
  stakefactorId: Scalars['String'];
  constraintId: Scalars['String'];
  id: Scalars['ID'];
};

export type Project = {
   __typename?: 'Project';
  createdAt?: Maybe<Scalars['String']>;
  createdBy: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  stakefactors?: Maybe<Array<Maybe<Stakefactor>>>;
  indicators?: Maybe<Array<Maybe<Indicator>>>;
  constraints?: Maybe<Array<Maybe<Constraint>>>;
  scenarios?: Maybe<Array<Maybe<Scenario>>>;
  fishboneBranchs?: Maybe<Array<Maybe<FishboneBranch>>>;
};

export type FishboneBranch = {
   __typename?: 'FishboneBranch';
  id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  projectId: Scalars['String'];
  name: Scalars['String'];
  weight?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<FishboneItem>>>;
};

export type FishboneItem = {
   __typename?: 'FishboneItem';
  id: Scalars['ID'];
  fishboneBranchId: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  trend?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  completion?: Maybe<Scalars['Int']>;
};

export type ProjectConnection = {
   __typename?: 'ProjectConnection';
  items?: Maybe<Array<Maybe<Project>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type PricePlan = {
   __typename?: 'PricePlan';
  id: Scalars['String'];
  currency: Scalars['String'];
  metadata?: Maybe<Scalars['String']>;
  recurring?: Maybe<Scalars['String']>;
  unit_amount?: Maybe<Scalars['Float']>;
};

export type SubscriptionStatus = {
   __typename?: 'SubscriptionStatus';
  email: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['String']>;
  endAt?: Maybe<Scalars['String']>;
  projects?: Maybe<Scalars['Float']>;
};

export type Mutation = {
   __typename?: 'Mutation';
  createProject?: Maybe<Project>;
  updateProject?: Maybe<Project>;
  cloneProject?: Maybe<Scalars['Boolean']>;
  deleteProject?: Maybe<Project>;
  createStakefactor?: Maybe<Stakefactor>;
  updateStakefactorScenarios?: Maybe<Stakefactor>;
  updateStakefactorPosition?: Maybe<Stakefactor>;
  updateStakefactorIcon?: Maybe<Stakefactor>;
  updateStakefactorImpact?: Maybe<Stakefactor>;
  updateStakefactorTrend?: Maybe<Stakefactor>;
  updateStakefactorTags?: Maybe<Stakefactor>;
  updateStakefactor?: Maybe<Stakefactor>;
  deleteStakefactor?: Maybe<Stakefactor>;
  createIndicator?: Maybe<Indicator>;
  updateIndicator?: Maybe<Indicator>;
  updateIndicatorScenarios?: Maybe<Indicator>;
  deleteIndicator?: Maybe<Indicator>;
  createScenario?: Maybe<Scenario>;
  updateScenario?: Maybe<Scenario>;
  deleteScenario?: Maybe<Scenario>;
  createConstraint?: Maybe<Constraint>;
  updateConstraint?: Maybe<Constraint>;
  updateConstraintScenarios?: Maybe<Constraint>;
  deleteConstraint?: Maybe<Constraint>;
  createStakefactorLink?: Maybe<StakefactorLink>;
  updateStakefactorLinkStrength?: Maybe<StakefactorLink>;
  deleteStakefactorLink?: Maybe<StakefactorLink>;
  createStakefactorIndicatorImpact?: Maybe<StakefactorIndicatorImpact>;
  updateStakefactorIndicatorImpactStrength?: Maybe<StakefactorIndicatorImpact>;
  deleteStakefactorIndicatorImpact?: Maybe<StakefactorIndicatorImpact>;
  createStakefactorConstraintImpact?: Maybe<StakefactorConstraintImpact>;
  updateStakefactorConstraintImpactStrength?: Maybe<StakefactorConstraintImpact>;
  deleteStakefactorConstraintImpact?: Maybe<StakefactorConstraintImpact>;
  createStakefactorComment?: Maybe<StakefactorComment>;
  updateStakefactorComment?: Maybe<StakefactorComment>;
  updateStakefactorCommentDescription?: Maybe<StakefactorComment>;
  updateStakefactorCommentedAtDescription?: Maybe<StakefactorComment>;
  deleteStakefactorComment?: Maybe<StakefactorComment>;
  createFishboneBranch?: Maybe<FishboneBranch>;
  updateFishboneBranch?: Maybe<FishboneBranch>;
  deleteFishboneBranch?: Maybe<FishboneBranch>;
  createFishboneItem?: Maybe<FishboneItem>;
  updateFishboneItem?: Maybe<FishboneItem>;
  deleteFishboneItem?: Maybe<FishboneItem>;
  makePayment?: Maybe<Scalars['String']>;
};


export type MutationCreateProjectArgs = {
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};


export type MutationUpdateProjectArgs = {
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};


export type MutationCloneProjectArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteProjectArgs = {
  id: Scalars['ID'];
};


export type MutationCreateStakefactorArgs = {
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  projectId: Scalars['String'];
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
  impact?: Maybe<Scalars['String']>;
  trend?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  icon?: Maybe<Scalars['String']>;
};


export type MutationUpdateStakefactorScenariosArgs = {
  id: Scalars['ID'];
  scenarios?: Maybe<Scalars['AWSJSON']>;
};


export type MutationUpdateStakefactorPositionArgs = {
  id: Scalars['ID'];
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
};


export type MutationUpdateStakefactorIconArgs = {
  id: Scalars['ID'];
  icon?: Maybe<Scalars['String']>;
};


export type MutationUpdateStakefactorImpactArgs = {
  id: Scalars['ID'];
  impact?: Maybe<Scalars['String']>;
};


export type MutationUpdateStakefactorTrendArgs = {
  id: Scalars['ID'];
  trend?: Maybe<Scalars['String']>;
};


export type MutationUpdateStakefactorTagsArgs = {
  id: Scalars['ID'];
  tags: Array<Maybe<Scalars['String']>>;
};


export type MutationUpdateStakefactorArgs = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  impact?: Maybe<Scalars['String']>;
  trend?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
};


export type MutationDeleteStakefactorArgs = {
  id: Scalars['ID'];
};


export type MutationCreateIndicatorArgs = {
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  weight?: Maybe<Scalars['Int']>;
  trend?: Maybe<Scalars['String']>;
  projectId: Scalars['String'];
};


export type MutationUpdateIndicatorArgs = {
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  trend?: Maybe<Scalars['String']>;
};


export type MutationUpdateIndicatorScenariosArgs = {
  id: Scalars['ID'];
  scenarios?: Maybe<Scalars['AWSJSON']>;
};


export type MutationDeleteIndicatorArgs = {
  id: Scalars['ID'];
};


export type MutationCreateScenarioArgs = {
  name: Scalars['String'];
  projectId: Scalars['String'];
};


export type MutationUpdateScenarioArgs = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};


export type MutationDeleteScenarioArgs = {
  id: Scalars['ID'];
};


export type MutationCreateConstraintArgs = {
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  weight?: Maybe<Scalars['Int']>;
  trend?: Maybe<Scalars['String']>;
  projectId: Scalars['String'];
};


export type MutationUpdateConstraintArgs = {
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  trend?: Maybe<Scalars['String']>;
};


export type MutationUpdateConstraintScenariosArgs = {
  id: Scalars['ID'];
  scenarios?: Maybe<Scalars['AWSJSON']>;
};


export type MutationDeleteConstraintArgs = {
  id: Scalars['ID'];
};


export type MutationCreateStakefactorLinkArgs = {
  originId: Scalars['String'];
  targetId: Scalars['String'];
};


export type MutationUpdateStakefactorLinkStrengthArgs = {
  id: Scalars['ID'];
  strength: Scalars['Int'];
};


export type MutationDeleteStakefactorLinkArgs = {
  id: Scalars['ID'];
};


export type MutationCreateStakefactorIndicatorImpactArgs = {
  stakefactorId: Scalars['String'];
  indicatorId: Scalars['String'];
};


export type MutationUpdateStakefactorIndicatorImpactStrengthArgs = {
  id: Scalars['ID'];
  strength: Scalars['Int'];
};


export type MutationDeleteStakefactorIndicatorImpactArgs = {
  id: Scalars['ID'];
};


export type MutationCreateStakefactorConstraintImpactArgs = {
  stakefactorId: Scalars['String'];
  constraintId: Scalars['String'];
};


export type MutationUpdateStakefactorConstraintImpactStrengthArgs = {
  id: Scalars['ID'];
  strength: Scalars['Int'];
};


export type MutationDeleteStakefactorConstraintImpactArgs = {
  id: Scalars['ID'];
};


export type MutationCreateStakefactorCommentArgs = {
  stakefactorId: Scalars['String'];
  commentedAt: Scalars['String'];
  description: Scalars['String'];
};


export type MutationUpdateStakefactorCommentArgs = {
  id: Scalars['ID'];
  description: Scalars['String'];
  commentedAt: Scalars['String'];
};


export type MutationUpdateStakefactorCommentDescriptionArgs = {
  id: Scalars['ID'];
  description: Scalars['String'];
};


export type MutationUpdateStakefactorCommentedAtDescriptionArgs = {
  id: Scalars['ID'];
  commentedAt: Scalars['String'];
};


export type MutationDeleteStakefactorCommentArgs = {
  id: Scalars['ID'];
};


export type MutationCreateFishboneBranchArgs = {
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  projectId: Scalars['String'];
  weight?: Maybe<Scalars['Int']>;
};


export type MutationUpdateFishboneBranchArgs = {
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
};


export type MutationDeleteFishboneBranchArgs = {
  id: Scalars['ID'];
};


export type MutationCreateFishboneItemArgs = {
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  fishboneBranchId: Scalars['String'];
  weight?: Maybe<Scalars['Int']>;
  trend?: Maybe<Scalars['String']>;
  completion?: Maybe<Scalars['Int']>;
};


export type MutationUpdateFishboneItemArgs = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  trend?: Maybe<Scalars['String']>;
  completion?: Maybe<Scalars['Int']>;
};


export type MutationDeleteFishboneItemArgs = {
  id: Scalars['ID'];
};


export type MutationMakePaymentArgs = {
  price: Scalars['String'];
  referer: Scalars['String'];
};

export type Query = {
   __typename?: 'Query';
  getProject?: Maybe<Project>;
  listProjects?: Maybe<ProjectConnection>;
  queryProjectsByCreatedByIndex?: Maybe<ProjectConnection>;
  getPrices?: Maybe<Array<Maybe<PricePlan>>>;
  getSubscriptionStatus?: Maybe<SubscriptionStatus>;
};


export type QueryGetProjectArgs = {
  id: Scalars['ID'];
};


export type QueryListProjectsArgs = {
  filter?: Maybe<TableProjectFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
  u?: Maybe<Scalars['String']>;
};


export type QueryQueryProjectsByCreatedByIndexArgs = {
  after?: Maybe<Scalars['String']>;
  createdBy: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
};


export type QueryGetPricesArgs = {
  filter?: Maybe<Scalars['String']>;
};

export type Subscription = {
   __typename?: 'Subscription';
  onCreateProject?: Maybe<Project>;
  onDeleteProject?: Maybe<Project>;
  onUpdateProject?: Maybe<Project>;
};


export type SubscriptionOnUpdateProjectArgs = {
  id?: Maybe<Scalars['ID']>;
};

export type TableBooleanFilterInput = {
  eq?: Maybe<Scalars['Boolean']>;
  ne?: Maybe<Scalars['Boolean']>;
};

export type TableFloatFilterInput = {
  between?: Maybe<Array<Maybe<Scalars['Float']>>>;
  contains?: Maybe<Scalars['Float']>;
  eq?: Maybe<Scalars['Float']>;
  ge?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  le?: Maybe<Scalars['Float']>;
  lt?: Maybe<Scalars['Float']>;
  ne?: Maybe<Scalars['Float']>;
  notContains?: Maybe<Scalars['Float']>;
};

export type TableIdFilterInput = {
  beginsWith?: Maybe<Scalars['ID']>;
  between?: Maybe<Array<Maybe<Scalars['ID']>>>;
  contains?: Maybe<Scalars['ID']>;
  eq?: Maybe<Scalars['ID']>;
  ge?: Maybe<Scalars['ID']>;
  gt?: Maybe<Scalars['ID']>;
  le?: Maybe<Scalars['ID']>;
  lt?: Maybe<Scalars['ID']>;
  ne?: Maybe<Scalars['ID']>;
  notContains?: Maybe<Scalars['ID']>;
};

export type TableIntFilterInput = {
  between?: Maybe<Array<Maybe<Scalars['Int']>>>;
  contains?: Maybe<Scalars['Int']>;
  eq?: Maybe<Scalars['Int']>;
  ge?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  le?: Maybe<Scalars['Int']>;
  lt?: Maybe<Scalars['Int']>;
  ne?: Maybe<Scalars['Int']>;
  notContains?: Maybe<Scalars['Int']>;
};

export type TableProjectFilterInput = {
  createdAt?: Maybe<TableStringFilterInput>;
  createdBy?: Maybe<TableStringFilterInput>;
  description?: Maybe<TableStringFilterInput>;
  id?: Maybe<TableIdFilterInput>;
  name?: Maybe<TableStringFilterInput>;
};

export type TableStringFilterInput = {
  beginsWith?: Maybe<Scalars['String']>;
  between?: Maybe<Array<Maybe<Scalars['String']>>>;
  contains?: Maybe<Scalars['String']>;
  eq?: Maybe<Scalars['String']>;
  ge?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  le?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  notContains?: Maybe<Scalars['String']>;
};

export type GetPricesQueryVariables = {
  filter?: Maybe<Scalars['String']>;
};


export type GetPricesQuery = (
  { __typename?: 'Query' }
  & { getPrices?: Maybe<Array<Maybe<(
    { __typename?: 'PricePlan' }
    & Pick<PricePlan, 'currency' | 'id' | 'metadata' | 'recurring' | 'unit_amount'>
  )>>> }
);

export type GetProjectQueryVariables = {
  id: Scalars['ID'];
};


export type GetProjectQuery = (
  { __typename?: 'Query' }
  & { getProject?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'createdAt' | 'createdBy' | 'description' | 'id' | 'name'>
    & { constraints?: Maybe<Array<Maybe<(
      { __typename?: 'Constraint' }
      & Pick<Constraint, 'createdAt' | 'createdBy' | 'description' | 'id' | 'name' | 'projectId' | 'scenarios' | 'trend' | 'weight'>
    )>>>, fishboneBranchs?: Maybe<Array<Maybe<(
      { __typename?: 'FishboneBranch' }
      & Pick<FishboneBranch, 'createdAt' | 'createdBy' | 'description' | 'id' | 'name' | 'projectId' | 'weight'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'FishboneItem' }
        & Pick<FishboneItem, 'completion' | 'createdAt' | 'createdBy' | 'description' | 'fishboneBranchId' | 'id' | 'name' | 'trend' | 'weight'>
      )>>> }
    )>>>, indicators?: Maybe<Array<Maybe<(
      { __typename?: 'Indicator' }
      & Pick<Indicator, 'createdAt' | 'createdBy' | 'description' | 'id' | 'name' | 'projectId' | 'scenarios' | 'trend' | 'weight'>
    )>>>, scenarios?: Maybe<Array<Maybe<(
      { __typename?: 'Scenario' }
      & Pick<Scenario, 'createdAt' | 'createdBy' | 'id' | 'name' | 'projectId'>
    )>>>, stakefactors?: Maybe<Array<Maybe<(
      { __typename?: 'Stakefactor' }
      & Pick<Stakefactor, 'createdAt' | 'createdBy' | 'icon' | 'id' | 'impact' | 'name' | 'projectId' | 'scenarios' | 'tags' | 'trend' | 'x' | 'y'>
      & { comments?: Maybe<Array<Maybe<(
        { __typename?: 'StakefactorComment' }
        & Pick<StakefactorComment, 'commentedAt' | 'createdAt' | 'createdBy' | 'description' | 'id' | 'stakefactorId'>
      )>>>, impactedConstraints?: Maybe<Array<Maybe<(
        { __typename?: 'StakefactorConstraintImpact' }
        & Pick<StakefactorConstraintImpact, 'constraintId' | 'createdAt' | 'createdBy' | 'id' | 'stakefactorId' | 'strength'>
      )>>>, impactedIndicators?: Maybe<Array<Maybe<(
        { __typename?: 'StakefactorIndicatorImpact' }
        & Pick<StakefactorIndicatorImpact, 'createdAt' | 'createdBy' | 'id' | 'indicatorId' | 'stakefactorId' | 'strength'>
      )>>>, links?: Maybe<Array<Maybe<(
        { __typename?: 'StakefactorLink' }
        & Pick<StakefactorLink, 'createdAt' | 'createdBy' | 'id' | 'originId' | 'strength' | 'targetId'>
      )>>> }
    )>>> }
  )> }
);

export type GetSubscriptionStatusQueryVariables = {};


export type GetSubscriptionStatusQuery = (
  { __typename?: 'Query' }
  & { getSubscriptionStatus?: Maybe<(
    { __typename?: 'SubscriptionStatus' }
    & Pick<SubscriptionStatus, 'email' | 'endAt' | 'projects' | 'startAt' | 'status'>
  )> }
);

export type ListProjectsQueryVariables = {
  filter?: Maybe<TableProjectFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
  u?: Maybe<Scalars['String']>;
};


export type ListProjectsQuery = (
  { __typename?: 'Query' }
  & { listProjects?: Maybe<(
    { __typename?: 'ProjectConnection' }
    & Pick<ProjectConnection, 'nextToken'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'createdAt' | 'createdBy' | 'description' | 'id' | 'name'>
      & { constraints?: Maybe<Array<Maybe<(
        { __typename?: 'Constraint' }
        & Pick<Constraint, 'createdAt' | 'createdBy' | 'description' | 'id' | 'name' | 'projectId' | 'scenarios' | 'trend' | 'weight'>
      )>>>, fishboneBranchs?: Maybe<Array<Maybe<(
        { __typename?: 'FishboneBranch' }
        & Pick<FishboneBranch, 'createdAt' | 'createdBy' | 'description' | 'id' | 'name' | 'projectId' | 'weight'>
        & { items?: Maybe<Array<Maybe<(
          { __typename?: 'FishboneItem' }
          & Pick<FishboneItem, 'completion' | 'createdAt' | 'createdBy' | 'description' | 'fishboneBranchId' | 'id' | 'name' | 'trend' | 'weight'>
        )>>> }
      )>>>, indicators?: Maybe<Array<Maybe<(
        { __typename?: 'Indicator' }
        & Pick<Indicator, 'createdAt' | 'createdBy' | 'description' | 'id' | 'name' | 'projectId' | 'scenarios' | 'trend' | 'weight'>
      )>>>, scenarios?: Maybe<Array<Maybe<(
        { __typename?: 'Scenario' }
        & Pick<Scenario, 'createdAt' | 'createdBy' | 'id' | 'name' | 'projectId'>
      )>>>, stakefactors?: Maybe<Array<Maybe<(
        { __typename?: 'Stakefactor' }
        & Pick<Stakefactor, 'createdAt' | 'createdBy' | 'icon' | 'id' | 'impact' | 'name' | 'projectId' | 'scenarios' | 'tags' | 'trend' | 'x' | 'y'>
        & { comments?: Maybe<Array<Maybe<(
          { __typename?: 'StakefactorComment' }
          & Pick<StakefactorComment, 'commentedAt' | 'createdAt' | 'createdBy' | 'description' | 'id' | 'stakefactorId'>
        )>>>, impactedConstraints?: Maybe<Array<Maybe<(
          { __typename?: 'StakefactorConstraintImpact' }
          & Pick<StakefactorConstraintImpact, 'constraintId' | 'createdAt' | 'createdBy' | 'id' | 'stakefactorId' | 'strength'>
        )>>>, impactedIndicators?: Maybe<Array<Maybe<(
          { __typename?: 'StakefactorIndicatorImpact' }
          & Pick<StakefactorIndicatorImpact, 'createdAt' | 'createdBy' | 'id' | 'indicatorId' | 'stakefactorId' | 'strength'>
        )>>>, links?: Maybe<Array<Maybe<(
          { __typename?: 'StakefactorLink' }
          & Pick<StakefactorLink, 'createdAt' | 'createdBy' | 'id' | 'originId' | 'strength' | 'targetId'>
        )>>> }
      )>>> }
    )>>> }
  )> }
);

export type QueryProjectsByCreatedByIndexQueryVariables = {
  after?: Maybe<Scalars['String']>;
  createdBy: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
};


export type QueryProjectsByCreatedByIndexQuery = (
  { __typename?: 'Query' }
  & { queryProjectsByCreatedByIndex?: Maybe<(
    { __typename?: 'ProjectConnection' }
    & Pick<ProjectConnection, 'nextToken'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'createdAt' | 'createdBy' | 'description' | 'id' | 'name'>
      & { constraints?: Maybe<Array<Maybe<(
        { __typename?: 'Constraint' }
        & Pick<Constraint, 'createdAt' | 'createdBy' | 'description' | 'id' | 'name' | 'projectId' | 'scenarios' | 'trend' | 'weight'>
      )>>>, fishboneBranchs?: Maybe<Array<Maybe<(
        { __typename?: 'FishboneBranch' }
        & Pick<FishboneBranch, 'createdAt' | 'createdBy' | 'description' | 'id' | 'name' | 'projectId' | 'weight'>
        & { items?: Maybe<Array<Maybe<(
          { __typename?: 'FishboneItem' }
          & Pick<FishboneItem, 'completion' | 'createdAt' | 'createdBy' | 'description' | 'fishboneBranchId' | 'id' | 'name' | 'trend' | 'weight'>
        )>>> }
      )>>>, indicators?: Maybe<Array<Maybe<(
        { __typename?: 'Indicator' }
        & Pick<Indicator, 'createdAt' | 'createdBy' | 'description' | 'id' | 'name' | 'projectId' | 'scenarios' | 'trend' | 'weight'>
      )>>>, scenarios?: Maybe<Array<Maybe<(
        { __typename?: 'Scenario' }
        & Pick<Scenario, 'createdAt' | 'createdBy' | 'id' | 'name' | 'projectId'>
      )>>>, stakefactors?: Maybe<Array<Maybe<(
        { __typename?: 'Stakefactor' }
        & Pick<Stakefactor, 'createdAt' | 'createdBy' | 'icon' | 'id' | 'impact' | 'name' | 'projectId' | 'scenarios' | 'tags' | 'trend' | 'x' | 'y'>
        & { comments?: Maybe<Array<Maybe<(
          { __typename?: 'StakefactorComment' }
          & Pick<StakefactorComment, 'commentedAt' | 'createdAt' | 'createdBy' | 'description' | 'id' | 'stakefactorId'>
        )>>>, impactedConstraints?: Maybe<Array<Maybe<(
          { __typename?: 'StakefactorConstraintImpact' }
          & Pick<StakefactorConstraintImpact, 'constraintId' | 'createdAt' | 'createdBy' | 'id' | 'stakefactorId' | 'strength'>
        )>>>, impactedIndicators?: Maybe<Array<Maybe<(
          { __typename?: 'StakefactorIndicatorImpact' }
          & Pick<StakefactorIndicatorImpact, 'createdAt' | 'createdBy' | 'id' | 'indicatorId' | 'stakefactorId' | 'strength'>
        )>>>, links?: Maybe<Array<Maybe<(
          { __typename?: 'StakefactorLink' }
          & Pick<StakefactorLink, 'createdAt' | 'createdBy' | 'id' | 'originId' | 'strength' | 'targetId'>
        )>>> }
      )>>> }
    )>>> }
  )> }
);

export type CloneProjectMutationVariables = {
  id: Scalars['ID'];
};


export type CloneProjectMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cloneProject'>
);

export type CreateConstraintMutationVariables = {
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  projectId: Scalars['String'];
  trend?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
};


export type CreateConstraintMutation = (
  { __typename?: 'Mutation' }
  & { createConstraint?: Maybe<(
    { __typename?: 'Constraint' }
    & Pick<Constraint, 'createdAt' | 'createdBy' | 'description' | 'id' | 'name' | 'projectId' | 'scenarios' | 'trend' | 'weight'>
  )> }
);

export type CreateFishboneBranchMutationVariables = {
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  projectId: Scalars['String'];
  weight?: Maybe<Scalars['Int']>;
};


export type CreateFishboneBranchMutation = (
  { __typename?: 'Mutation' }
  & { createFishboneBranch?: Maybe<(
    { __typename?: 'FishboneBranch' }
    & Pick<FishboneBranch, 'createdAt' | 'createdBy' | 'description' | 'id' | 'name' | 'projectId' | 'weight'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'FishboneItem' }
      & Pick<FishboneItem, 'completion' | 'createdAt' | 'createdBy' | 'description' | 'fishboneBranchId' | 'id' | 'name' | 'trend' | 'weight'>
    )>>> }
  )> }
);

export type CreateFishboneItemMutationVariables = {
  completion?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  fishboneBranchId: Scalars['String'];
  name: Scalars['String'];
  trend?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
};


export type CreateFishboneItemMutation = (
  { __typename?: 'Mutation' }
  & { createFishboneItem?: Maybe<(
    { __typename?: 'FishboneItem' }
    & Pick<FishboneItem, 'completion' | 'createdAt' | 'createdBy' | 'description' | 'fishboneBranchId' | 'id' | 'name' | 'trend' | 'weight'>
  )> }
);

export type CreateIndicatorMutationVariables = {
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  projectId: Scalars['String'];
  trend?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
};


export type CreateIndicatorMutation = (
  { __typename?: 'Mutation' }
  & { createIndicator?: Maybe<(
    { __typename?: 'Indicator' }
    & Pick<Indicator, 'createdAt' | 'createdBy' | 'description' | 'id' | 'name' | 'projectId' | 'scenarios' | 'trend' | 'weight'>
  )> }
);

export type CreateProjectMutationVariables = {
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};


export type CreateProjectMutation = (
  { __typename?: 'Mutation' }
  & { createProject?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'createdAt' | 'createdBy' | 'description' | 'id' | 'name'>
    & { constraints?: Maybe<Array<Maybe<(
      { __typename?: 'Constraint' }
      & Pick<Constraint, 'createdAt' | 'createdBy' | 'description' | 'id' | 'name' | 'projectId' | 'scenarios' | 'trend' | 'weight'>
    )>>>, fishboneBranchs?: Maybe<Array<Maybe<(
      { __typename?: 'FishboneBranch' }
      & Pick<FishboneBranch, 'createdAt' | 'createdBy' | 'description' | 'id' | 'name' | 'projectId' | 'weight'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'FishboneItem' }
        & Pick<FishboneItem, 'completion' | 'createdAt' | 'createdBy' | 'description' | 'fishboneBranchId' | 'id' | 'name' | 'trend' | 'weight'>
      )>>> }
    )>>>, indicators?: Maybe<Array<Maybe<(
      { __typename?: 'Indicator' }
      & Pick<Indicator, 'createdAt' | 'createdBy' | 'description' | 'id' | 'name' | 'projectId' | 'scenarios' | 'trend' | 'weight'>
    )>>>, scenarios?: Maybe<Array<Maybe<(
      { __typename?: 'Scenario' }
      & Pick<Scenario, 'createdAt' | 'createdBy' | 'id' | 'name' | 'projectId'>
    )>>>, stakefactors?: Maybe<Array<Maybe<(
      { __typename?: 'Stakefactor' }
      & Pick<Stakefactor, 'createdAt' | 'createdBy' | 'icon' | 'id' | 'impact' | 'name' | 'projectId' | 'scenarios' | 'tags' | 'trend' | 'x' | 'y'>
      & { comments?: Maybe<Array<Maybe<(
        { __typename?: 'StakefactorComment' }
        & Pick<StakefactorComment, 'commentedAt' | 'createdAt' | 'createdBy' | 'description' | 'id' | 'stakefactorId'>
      )>>>, impactedConstraints?: Maybe<Array<Maybe<(
        { __typename?: 'StakefactorConstraintImpact' }
        & Pick<StakefactorConstraintImpact, 'constraintId' | 'createdAt' | 'createdBy' | 'id' | 'stakefactorId' | 'strength'>
      )>>>, impactedIndicators?: Maybe<Array<Maybe<(
        { __typename?: 'StakefactorIndicatorImpact' }
        & Pick<StakefactorIndicatorImpact, 'createdAt' | 'createdBy' | 'id' | 'indicatorId' | 'stakefactorId' | 'strength'>
      )>>>, links?: Maybe<Array<Maybe<(
        { __typename?: 'StakefactorLink' }
        & Pick<StakefactorLink, 'createdAt' | 'createdBy' | 'id' | 'originId' | 'strength' | 'targetId'>
      )>>> }
    )>>> }
  )> }
);

export type CreateScenarioMutationVariables = {
  name: Scalars['String'];
  projectId: Scalars['String'];
};


export type CreateScenarioMutation = (
  { __typename?: 'Mutation' }
  & { createScenario?: Maybe<(
    { __typename?: 'Scenario' }
    & Pick<Scenario, 'createdAt' | 'createdBy' | 'id' | 'name' | 'projectId'>
  )> }
);

export type CreateStakefactorMutationVariables = {
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  impact?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  projectId: Scalars['String'];
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  trend?: Maybe<Scalars['String']>;
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
};


export type CreateStakefactorMutation = (
  { __typename?: 'Mutation' }
  & { createStakefactor?: Maybe<(
    { __typename?: 'Stakefactor' }
    & Pick<Stakefactor, 'createdAt' | 'createdBy' | 'icon' | 'id' | 'impact' | 'name' | 'projectId' | 'scenarios' | 'tags' | 'trend' | 'x' | 'y'>
    & { comments?: Maybe<Array<Maybe<(
      { __typename?: 'StakefactorComment' }
      & Pick<StakefactorComment, 'commentedAt' | 'createdAt' | 'createdBy' | 'description' | 'id' | 'stakefactorId'>
    )>>>, impactedConstraints?: Maybe<Array<Maybe<(
      { __typename?: 'StakefactorConstraintImpact' }
      & Pick<StakefactorConstraintImpact, 'constraintId' | 'createdAt' | 'createdBy' | 'id' | 'stakefactorId' | 'strength'>
    )>>>, impactedIndicators?: Maybe<Array<Maybe<(
      { __typename?: 'StakefactorIndicatorImpact' }
      & Pick<StakefactorIndicatorImpact, 'createdAt' | 'createdBy' | 'id' | 'indicatorId' | 'stakefactorId' | 'strength'>
    )>>>, links?: Maybe<Array<Maybe<(
      { __typename?: 'StakefactorLink' }
      & Pick<StakefactorLink, 'createdAt' | 'createdBy' | 'id' | 'originId' | 'strength' | 'targetId'>
    )>>> }
  )> }
);

export type CreateStakefactorCommentMutationVariables = {
  commentedAt: Scalars['String'];
  description: Scalars['String'];
  stakefactorId: Scalars['String'];
};


export type CreateStakefactorCommentMutation = (
  { __typename?: 'Mutation' }
  & { createStakefactorComment?: Maybe<(
    { __typename?: 'StakefactorComment' }
    & Pick<StakefactorComment, 'commentedAt' | 'createdAt' | 'createdBy' | 'description' | 'id' | 'stakefactorId'>
  )> }
);

export type CreateStakefactorConstraintImpactMutationVariables = {
  constraintId: Scalars['String'];
  stakefactorId: Scalars['String'];
};


export type CreateStakefactorConstraintImpactMutation = (
  { __typename?: 'Mutation' }
  & { createStakefactorConstraintImpact?: Maybe<(
    { __typename?: 'StakefactorConstraintImpact' }
    & Pick<StakefactorConstraintImpact, 'constraintId' | 'createdAt' | 'createdBy' | 'id' | 'stakefactorId' | 'strength'>
  )> }
);

export type CreateStakefactorIndicatorImpactMutationVariables = {
  indicatorId: Scalars['String'];
  stakefactorId: Scalars['String'];
};


export type CreateStakefactorIndicatorImpactMutation = (
  { __typename?: 'Mutation' }
  & { createStakefactorIndicatorImpact?: Maybe<(
    { __typename?: 'StakefactorIndicatorImpact' }
    & Pick<StakefactorIndicatorImpact, 'createdAt' | 'createdBy' | 'id' | 'indicatorId' | 'stakefactorId' | 'strength'>
  )> }
);

export type CreateStakefactorLinkMutationVariables = {
  originId: Scalars['String'];
  targetId: Scalars['String'];
};


export type CreateStakefactorLinkMutation = (
  { __typename?: 'Mutation' }
  & { createStakefactorLink?: Maybe<(
    { __typename?: 'StakefactorLink' }
    & Pick<StakefactorLink, 'createdAt' | 'createdBy' | 'id' | 'originId' | 'strength' | 'targetId'>
  )> }
);

export type DeleteConstraintMutationVariables = {
  id: Scalars['ID'];
};


export type DeleteConstraintMutation = (
  { __typename?: 'Mutation' }
  & { deleteConstraint?: Maybe<(
    { __typename?: 'Constraint' }
    & Pick<Constraint, 'createdAt' | 'createdBy' | 'description' | 'id' | 'name' | 'projectId' | 'scenarios' | 'trend' | 'weight'>
  )> }
);

export type DeleteFishboneBranchMutationVariables = {
  id: Scalars['ID'];
};


export type DeleteFishboneBranchMutation = (
  { __typename?: 'Mutation' }
  & { deleteFishboneBranch?: Maybe<(
    { __typename?: 'FishboneBranch' }
    & Pick<FishboneBranch, 'createdAt' | 'createdBy' | 'description' | 'id' | 'name' | 'projectId' | 'weight'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'FishboneItem' }
      & Pick<FishboneItem, 'completion' | 'createdAt' | 'createdBy' | 'description' | 'fishboneBranchId' | 'id' | 'name' | 'trend' | 'weight'>
    )>>> }
  )> }
);

export type DeleteFishboneItemMutationVariables = {
  id: Scalars['ID'];
};


export type DeleteFishboneItemMutation = (
  { __typename?: 'Mutation' }
  & { deleteFishboneItem?: Maybe<(
    { __typename?: 'FishboneItem' }
    & Pick<FishboneItem, 'completion' | 'createdAt' | 'createdBy' | 'description' | 'fishboneBranchId' | 'id' | 'name' | 'trend' | 'weight'>
  )> }
);

export type DeleteIndicatorMutationVariables = {
  id: Scalars['ID'];
};


export type DeleteIndicatorMutation = (
  { __typename?: 'Mutation' }
  & { deleteIndicator?: Maybe<(
    { __typename?: 'Indicator' }
    & Pick<Indicator, 'createdAt' | 'createdBy' | 'description' | 'id' | 'name' | 'projectId' | 'scenarios' | 'trend' | 'weight'>
  )> }
);

export type DeleteProjectMutationVariables = {
  id: Scalars['ID'];
};


export type DeleteProjectMutation = (
  { __typename?: 'Mutation' }
  & { deleteProject?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'createdAt' | 'createdBy' | 'description' | 'id' | 'name'>
    & { constraints?: Maybe<Array<Maybe<(
      { __typename?: 'Constraint' }
      & Pick<Constraint, 'createdAt' | 'createdBy' | 'description' | 'id' | 'name' | 'projectId' | 'scenarios' | 'trend' | 'weight'>
    )>>>, fishboneBranchs?: Maybe<Array<Maybe<(
      { __typename?: 'FishboneBranch' }
      & Pick<FishboneBranch, 'createdAt' | 'createdBy' | 'description' | 'id' | 'name' | 'projectId' | 'weight'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'FishboneItem' }
        & Pick<FishboneItem, 'completion' | 'createdAt' | 'createdBy' | 'description' | 'fishboneBranchId' | 'id' | 'name' | 'trend' | 'weight'>
      )>>> }
    )>>>, indicators?: Maybe<Array<Maybe<(
      { __typename?: 'Indicator' }
      & Pick<Indicator, 'createdAt' | 'createdBy' | 'description' | 'id' | 'name' | 'projectId' | 'scenarios' | 'trend' | 'weight'>
    )>>>, scenarios?: Maybe<Array<Maybe<(
      { __typename?: 'Scenario' }
      & Pick<Scenario, 'createdAt' | 'createdBy' | 'id' | 'name' | 'projectId'>
    )>>>, stakefactors?: Maybe<Array<Maybe<(
      { __typename?: 'Stakefactor' }
      & Pick<Stakefactor, 'createdAt' | 'createdBy' | 'icon' | 'id' | 'impact' | 'name' | 'projectId' | 'scenarios' | 'tags' | 'trend' | 'x' | 'y'>
      & { comments?: Maybe<Array<Maybe<(
        { __typename?: 'StakefactorComment' }
        & Pick<StakefactorComment, 'commentedAt' | 'createdAt' | 'createdBy' | 'description' | 'id' | 'stakefactorId'>
      )>>>, impactedConstraints?: Maybe<Array<Maybe<(
        { __typename?: 'StakefactorConstraintImpact' }
        & Pick<StakefactorConstraintImpact, 'constraintId' | 'createdAt' | 'createdBy' | 'id' | 'stakefactorId' | 'strength'>
      )>>>, impactedIndicators?: Maybe<Array<Maybe<(
        { __typename?: 'StakefactorIndicatorImpact' }
        & Pick<StakefactorIndicatorImpact, 'createdAt' | 'createdBy' | 'id' | 'indicatorId' | 'stakefactorId' | 'strength'>
      )>>>, links?: Maybe<Array<Maybe<(
        { __typename?: 'StakefactorLink' }
        & Pick<StakefactorLink, 'createdAt' | 'createdBy' | 'id' | 'originId' | 'strength' | 'targetId'>
      )>>> }
    )>>> }
  )> }
);

export type DeleteScenarioMutationVariables = {
  id: Scalars['ID'];
};


export type DeleteScenarioMutation = (
  { __typename?: 'Mutation' }
  & { deleteScenario?: Maybe<(
    { __typename?: 'Scenario' }
    & Pick<Scenario, 'createdAt' | 'createdBy' | 'id' | 'name' | 'projectId'>
  )> }
);

export type DeleteStakefactorMutationVariables = {
  id: Scalars['ID'];
};


export type DeleteStakefactorMutation = (
  { __typename?: 'Mutation' }
  & { deleteStakefactor?: Maybe<(
    { __typename?: 'Stakefactor' }
    & Pick<Stakefactor, 'createdAt' | 'createdBy' | 'icon' | 'id' | 'impact' | 'name' | 'projectId' | 'scenarios' | 'tags' | 'trend' | 'x' | 'y'>
    & { comments?: Maybe<Array<Maybe<(
      { __typename?: 'StakefactorComment' }
      & Pick<StakefactorComment, 'commentedAt' | 'createdAt' | 'createdBy' | 'description' | 'id' | 'stakefactorId'>
    )>>>, impactedConstraints?: Maybe<Array<Maybe<(
      { __typename?: 'StakefactorConstraintImpact' }
      & Pick<StakefactorConstraintImpact, 'constraintId' | 'createdAt' | 'createdBy' | 'id' | 'stakefactorId' | 'strength'>
    )>>>, impactedIndicators?: Maybe<Array<Maybe<(
      { __typename?: 'StakefactorIndicatorImpact' }
      & Pick<StakefactorIndicatorImpact, 'createdAt' | 'createdBy' | 'id' | 'indicatorId' | 'stakefactorId' | 'strength'>
    )>>>, links?: Maybe<Array<Maybe<(
      { __typename?: 'StakefactorLink' }
      & Pick<StakefactorLink, 'createdAt' | 'createdBy' | 'id' | 'originId' | 'strength' | 'targetId'>
    )>>> }
  )> }
);

export type DeleteStakefactorCommentMutationVariables = {
  id: Scalars['ID'];
};


export type DeleteStakefactorCommentMutation = (
  { __typename?: 'Mutation' }
  & { deleteStakefactorComment?: Maybe<(
    { __typename?: 'StakefactorComment' }
    & Pick<StakefactorComment, 'commentedAt' | 'createdAt' | 'createdBy' | 'description' | 'id' | 'stakefactorId'>
  )> }
);

export type DeleteStakefactorConstraintImpactMutationVariables = {
  id: Scalars['ID'];
};


export type DeleteStakefactorConstraintImpactMutation = (
  { __typename?: 'Mutation' }
  & { deleteStakefactorConstraintImpact?: Maybe<(
    { __typename?: 'StakefactorConstraintImpact' }
    & Pick<StakefactorConstraintImpact, 'constraintId' | 'createdAt' | 'createdBy' | 'id' | 'stakefactorId' | 'strength'>
  )> }
);

export type DeleteStakefactorIndicatorImpactMutationVariables = {
  id: Scalars['ID'];
};


export type DeleteStakefactorIndicatorImpactMutation = (
  { __typename?: 'Mutation' }
  & { deleteStakefactorIndicatorImpact?: Maybe<(
    { __typename?: 'StakefactorIndicatorImpact' }
    & Pick<StakefactorIndicatorImpact, 'createdAt' | 'createdBy' | 'id' | 'indicatorId' | 'stakefactorId' | 'strength'>
  )> }
);

export type DeleteStakefactorLinkMutationVariables = {
  id: Scalars['ID'];
};


export type DeleteStakefactorLinkMutation = (
  { __typename?: 'Mutation' }
  & { deleteStakefactorLink?: Maybe<(
    { __typename?: 'StakefactorLink' }
    & Pick<StakefactorLink, 'createdAt' | 'createdBy' | 'id' | 'originId' | 'strength' | 'targetId'>
  )> }
);

export type MakePaymentMutationVariables = {
  price: Scalars['String'];
  referer: Scalars['String'];
};


export type MakePaymentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'makePayment'>
);

export type UpdateConstraintMutationVariables = {
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  trend?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
};


export type UpdateConstraintMutation = (
  { __typename?: 'Mutation' }
  & { updateConstraint?: Maybe<(
    { __typename?: 'Constraint' }
    & Pick<Constraint, 'createdAt' | 'createdBy' | 'description' | 'id' | 'name' | 'projectId' | 'scenarios' | 'trend' | 'weight'>
  )> }
);

export type UpdateConstraintScenariosMutationVariables = {
  id: Scalars['ID'];
  scenarios?: Maybe<Scalars['AWSJSON']>;
};


export type UpdateConstraintScenariosMutation = (
  { __typename?: 'Mutation' }
  & { updateConstraintScenarios?: Maybe<(
    { __typename?: 'Constraint' }
    & Pick<Constraint, 'createdAt' | 'createdBy' | 'description' | 'id' | 'name' | 'projectId' | 'scenarios' | 'trend' | 'weight'>
  )> }
);

export type UpdateFishboneBranchMutationVariables = {
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
};


export type UpdateFishboneBranchMutation = (
  { __typename?: 'Mutation' }
  & { updateFishboneBranch?: Maybe<(
    { __typename?: 'FishboneBranch' }
    & Pick<FishboneBranch, 'createdAt' | 'createdBy' | 'description' | 'id' | 'name' | 'projectId' | 'weight'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'FishboneItem' }
      & Pick<FishboneItem, 'completion' | 'createdAt' | 'createdBy' | 'description' | 'fishboneBranchId' | 'id' | 'name' | 'trend' | 'weight'>
    )>>> }
  )> }
);

export type UpdateFishboneItemMutationVariables = {
  completion?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  trend?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
};


export type UpdateFishboneItemMutation = (
  { __typename?: 'Mutation' }
  & { updateFishboneItem?: Maybe<(
    { __typename?: 'FishboneItem' }
    & Pick<FishboneItem, 'completion' | 'createdAt' | 'createdBy' | 'description' | 'fishboneBranchId' | 'id' | 'name' | 'trend' | 'weight'>
  )> }
);

export type UpdateIndicatorMutationVariables = {
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  trend?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
};


export type UpdateIndicatorMutation = (
  { __typename?: 'Mutation' }
  & { updateIndicator?: Maybe<(
    { __typename?: 'Indicator' }
    & Pick<Indicator, 'createdAt' | 'createdBy' | 'description' | 'id' | 'name' | 'projectId' | 'scenarios' | 'trend' | 'weight'>
  )> }
);

export type UpdateIndicatorScenariosMutationVariables = {
  id: Scalars['ID'];
  scenarios?: Maybe<Scalars['AWSJSON']>;
};


export type UpdateIndicatorScenariosMutation = (
  { __typename?: 'Mutation' }
  & { updateIndicatorScenarios?: Maybe<(
    { __typename?: 'Indicator' }
    & Pick<Indicator, 'createdAt' | 'createdBy' | 'description' | 'id' | 'name' | 'projectId' | 'scenarios' | 'trend' | 'weight'>
  )> }
);

export type UpdateProjectMutationVariables = {
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};


export type UpdateProjectMutation = (
  { __typename?: 'Mutation' }
  & { updateProject?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'createdAt' | 'createdBy' | 'description' | 'id' | 'name'>
    & { constraints?: Maybe<Array<Maybe<(
      { __typename?: 'Constraint' }
      & Pick<Constraint, 'createdAt' | 'createdBy' | 'description' | 'id' | 'name' | 'projectId' | 'scenarios' | 'trend' | 'weight'>
    )>>>, fishboneBranchs?: Maybe<Array<Maybe<(
      { __typename?: 'FishboneBranch' }
      & Pick<FishboneBranch, 'createdAt' | 'createdBy' | 'description' | 'id' | 'name' | 'projectId' | 'weight'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'FishboneItem' }
        & Pick<FishboneItem, 'completion' | 'createdAt' | 'createdBy' | 'description' | 'fishboneBranchId' | 'id' | 'name' | 'trend' | 'weight'>
      )>>> }
    )>>>, indicators?: Maybe<Array<Maybe<(
      { __typename?: 'Indicator' }
      & Pick<Indicator, 'createdAt' | 'createdBy' | 'description' | 'id' | 'name' | 'projectId' | 'scenarios' | 'trend' | 'weight'>
    )>>>, scenarios?: Maybe<Array<Maybe<(
      { __typename?: 'Scenario' }
      & Pick<Scenario, 'createdAt' | 'createdBy' | 'id' | 'name' | 'projectId'>
    )>>>, stakefactors?: Maybe<Array<Maybe<(
      { __typename?: 'Stakefactor' }
      & Pick<Stakefactor, 'createdAt' | 'createdBy' | 'icon' | 'id' | 'impact' | 'name' | 'projectId' | 'scenarios' | 'tags' | 'trend' | 'x' | 'y'>
      & { comments?: Maybe<Array<Maybe<(
        { __typename?: 'StakefactorComment' }
        & Pick<StakefactorComment, 'commentedAt' | 'createdAt' | 'createdBy' | 'description' | 'id' | 'stakefactorId'>
      )>>>, impactedConstraints?: Maybe<Array<Maybe<(
        { __typename?: 'StakefactorConstraintImpact' }
        & Pick<StakefactorConstraintImpact, 'constraintId' | 'createdAt' | 'createdBy' | 'id' | 'stakefactorId' | 'strength'>
      )>>>, impactedIndicators?: Maybe<Array<Maybe<(
        { __typename?: 'StakefactorIndicatorImpact' }
        & Pick<StakefactorIndicatorImpact, 'createdAt' | 'createdBy' | 'id' | 'indicatorId' | 'stakefactorId' | 'strength'>
      )>>>, links?: Maybe<Array<Maybe<(
        { __typename?: 'StakefactorLink' }
        & Pick<StakefactorLink, 'createdAt' | 'createdBy' | 'id' | 'originId' | 'strength' | 'targetId'>
      )>>> }
    )>>> }
  )> }
);

export type UpdateScenarioMutationVariables = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};


export type UpdateScenarioMutation = (
  { __typename?: 'Mutation' }
  & { updateScenario?: Maybe<(
    { __typename?: 'Scenario' }
    & Pick<Scenario, 'createdAt' | 'createdBy' | 'id' | 'name' | 'projectId'>
  )> }
);

export type UpdateStakefactorMutationVariables = {
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  impact?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  trend?: Maybe<Scalars['String']>;
};


export type UpdateStakefactorMutation = (
  { __typename?: 'Mutation' }
  & { updateStakefactor?: Maybe<(
    { __typename?: 'Stakefactor' }
    & Pick<Stakefactor, 'createdAt' | 'createdBy' | 'icon' | 'id' | 'impact' | 'name' | 'projectId' | 'scenarios' | 'tags' | 'trend' | 'x' | 'y'>
    & { comments?: Maybe<Array<Maybe<(
      { __typename?: 'StakefactorComment' }
      & Pick<StakefactorComment, 'commentedAt' | 'createdAt' | 'createdBy' | 'description' | 'id' | 'stakefactorId'>
    )>>>, impactedConstraints?: Maybe<Array<Maybe<(
      { __typename?: 'StakefactorConstraintImpact' }
      & Pick<StakefactorConstraintImpact, 'constraintId' | 'createdAt' | 'createdBy' | 'id' | 'stakefactorId' | 'strength'>
    )>>>, impactedIndicators?: Maybe<Array<Maybe<(
      { __typename?: 'StakefactorIndicatorImpact' }
      & Pick<StakefactorIndicatorImpact, 'createdAt' | 'createdBy' | 'id' | 'indicatorId' | 'stakefactorId' | 'strength'>
    )>>>, links?: Maybe<Array<Maybe<(
      { __typename?: 'StakefactorLink' }
      & Pick<StakefactorLink, 'createdAt' | 'createdBy' | 'id' | 'originId' | 'strength' | 'targetId'>
    )>>> }
  )> }
);

export type UpdateStakefactorCommentMutationVariables = {
  commentedAt: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['ID'];
};


export type UpdateStakefactorCommentMutation = (
  { __typename?: 'Mutation' }
  & { updateStakefactorComment?: Maybe<(
    { __typename?: 'StakefactorComment' }
    & Pick<StakefactorComment, 'commentedAt' | 'createdAt' | 'createdBy' | 'description' | 'id' | 'stakefactorId'>
  )> }
);

export type UpdateStakefactorCommentDescriptionMutationVariables = {
  description: Scalars['String'];
  id: Scalars['ID'];
};


export type UpdateStakefactorCommentDescriptionMutation = (
  { __typename?: 'Mutation' }
  & { updateStakefactorCommentDescription?: Maybe<(
    { __typename?: 'StakefactorComment' }
    & Pick<StakefactorComment, 'commentedAt' | 'createdAt' | 'createdBy' | 'description' | 'id' | 'stakefactorId'>
  )> }
);

export type UpdateStakefactorCommentedAtDescriptionMutationVariables = {
  commentedAt: Scalars['String'];
  id: Scalars['ID'];
};


export type UpdateStakefactorCommentedAtDescriptionMutation = (
  { __typename?: 'Mutation' }
  & { updateStakefactorCommentedAtDescription?: Maybe<(
    { __typename?: 'StakefactorComment' }
    & Pick<StakefactorComment, 'commentedAt' | 'createdAt' | 'createdBy' | 'description' | 'id' | 'stakefactorId'>
  )> }
);

export type UpdateStakefactorConstraintImpactStrengthMutationVariables = {
  id: Scalars['ID'];
  strength: Scalars['Int'];
};


export type UpdateStakefactorConstraintImpactStrengthMutation = (
  { __typename?: 'Mutation' }
  & { updateStakefactorConstraintImpactStrength?: Maybe<(
    { __typename?: 'StakefactorConstraintImpact' }
    & Pick<StakefactorConstraintImpact, 'constraintId' | 'createdAt' | 'createdBy' | 'id' | 'stakefactorId' | 'strength'>
  )> }
);

export type UpdateStakefactorIconMutationVariables = {
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};


export type UpdateStakefactorIconMutation = (
  { __typename?: 'Mutation' }
  & { updateStakefactorIcon?: Maybe<(
    { __typename?: 'Stakefactor' }
    & Pick<Stakefactor, 'createdAt' | 'createdBy' | 'icon' | 'id' | 'impact' | 'name' | 'projectId' | 'scenarios' | 'tags' | 'trend' | 'x' | 'y'>
    & { comments?: Maybe<Array<Maybe<(
      { __typename?: 'StakefactorComment' }
      & Pick<StakefactorComment, 'commentedAt' | 'createdAt' | 'createdBy' | 'description' | 'id' | 'stakefactorId'>
    )>>>, impactedConstraints?: Maybe<Array<Maybe<(
      { __typename?: 'StakefactorConstraintImpact' }
      & Pick<StakefactorConstraintImpact, 'constraintId' | 'createdAt' | 'createdBy' | 'id' | 'stakefactorId' | 'strength'>
    )>>>, impactedIndicators?: Maybe<Array<Maybe<(
      { __typename?: 'StakefactorIndicatorImpact' }
      & Pick<StakefactorIndicatorImpact, 'createdAt' | 'createdBy' | 'id' | 'indicatorId' | 'stakefactorId' | 'strength'>
    )>>>, links?: Maybe<Array<Maybe<(
      { __typename?: 'StakefactorLink' }
      & Pick<StakefactorLink, 'createdAt' | 'createdBy' | 'id' | 'originId' | 'strength' | 'targetId'>
    )>>> }
  )> }
);

export type UpdateStakefactorImpactMutationVariables = {
  id: Scalars['ID'];
  impact?: Maybe<Scalars['String']>;
};


export type UpdateStakefactorImpactMutation = (
  { __typename?: 'Mutation' }
  & { updateStakefactorImpact?: Maybe<(
    { __typename?: 'Stakefactor' }
    & Pick<Stakefactor, 'createdAt' | 'createdBy' | 'icon' | 'id' | 'impact' | 'name' | 'projectId' | 'scenarios' | 'tags' | 'trend' | 'x' | 'y'>
    & { comments?: Maybe<Array<Maybe<(
      { __typename?: 'StakefactorComment' }
      & Pick<StakefactorComment, 'commentedAt' | 'createdAt' | 'createdBy' | 'description' | 'id' | 'stakefactorId'>
    )>>>, impactedConstraints?: Maybe<Array<Maybe<(
      { __typename?: 'StakefactorConstraintImpact' }
      & Pick<StakefactorConstraintImpact, 'constraintId' | 'createdAt' | 'createdBy' | 'id' | 'stakefactorId' | 'strength'>
    )>>>, impactedIndicators?: Maybe<Array<Maybe<(
      { __typename?: 'StakefactorIndicatorImpact' }
      & Pick<StakefactorIndicatorImpact, 'createdAt' | 'createdBy' | 'id' | 'indicatorId' | 'stakefactorId' | 'strength'>
    )>>>, links?: Maybe<Array<Maybe<(
      { __typename?: 'StakefactorLink' }
      & Pick<StakefactorLink, 'createdAt' | 'createdBy' | 'id' | 'originId' | 'strength' | 'targetId'>
    )>>> }
  )> }
);

export type UpdateStakefactorIndicatorImpactStrengthMutationVariables = {
  id: Scalars['ID'];
  strength: Scalars['Int'];
};


export type UpdateStakefactorIndicatorImpactStrengthMutation = (
  { __typename?: 'Mutation' }
  & { updateStakefactorIndicatorImpactStrength?: Maybe<(
    { __typename?: 'StakefactorIndicatorImpact' }
    & Pick<StakefactorIndicatorImpact, 'createdAt' | 'createdBy' | 'id' | 'indicatorId' | 'stakefactorId' | 'strength'>
  )> }
);

export type UpdateStakefactorLinkStrengthMutationVariables = {
  id: Scalars['ID'];
  strength: Scalars['Int'];
};


export type UpdateStakefactorLinkStrengthMutation = (
  { __typename?: 'Mutation' }
  & { updateStakefactorLinkStrength?: Maybe<(
    { __typename?: 'StakefactorLink' }
    & Pick<StakefactorLink, 'createdAt' | 'createdBy' | 'id' | 'originId' | 'strength' | 'targetId'>
  )> }
);

export type UpdateStakefactorPositionMutationVariables = {
  id: Scalars['ID'];
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
};


export type UpdateStakefactorPositionMutation = (
  { __typename?: 'Mutation' }
  & { updateStakefactorPosition?: Maybe<(
    { __typename?: 'Stakefactor' }
    & Pick<Stakefactor, 'createdAt' | 'createdBy' | 'icon' | 'id' | 'impact' | 'name' | 'projectId' | 'scenarios' | 'tags' | 'trend' | 'x' | 'y'>
    & { comments?: Maybe<Array<Maybe<(
      { __typename?: 'StakefactorComment' }
      & Pick<StakefactorComment, 'commentedAt' | 'createdAt' | 'createdBy' | 'description' | 'id' | 'stakefactorId'>
    )>>>, impactedConstraints?: Maybe<Array<Maybe<(
      { __typename?: 'StakefactorConstraintImpact' }
      & Pick<StakefactorConstraintImpact, 'constraintId' | 'createdAt' | 'createdBy' | 'id' | 'stakefactorId' | 'strength'>
    )>>>, impactedIndicators?: Maybe<Array<Maybe<(
      { __typename?: 'StakefactorIndicatorImpact' }
      & Pick<StakefactorIndicatorImpact, 'createdAt' | 'createdBy' | 'id' | 'indicatorId' | 'stakefactorId' | 'strength'>
    )>>>, links?: Maybe<Array<Maybe<(
      { __typename?: 'StakefactorLink' }
      & Pick<StakefactorLink, 'createdAt' | 'createdBy' | 'id' | 'originId' | 'strength' | 'targetId'>
    )>>> }
  )> }
);

export type UpdateStakefactorScenariosMutationVariables = {
  id: Scalars['ID'];
  scenarios?: Maybe<Scalars['AWSJSON']>;
};


export type UpdateStakefactorScenariosMutation = (
  { __typename?: 'Mutation' }
  & { updateStakefactorScenarios?: Maybe<(
    { __typename?: 'Stakefactor' }
    & Pick<Stakefactor, 'createdAt' | 'createdBy' | 'icon' | 'id' | 'impact' | 'name' | 'projectId' | 'scenarios' | 'tags' | 'trend' | 'x' | 'y'>
    & { comments?: Maybe<Array<Maybe<(
      { __typename?: 'StakefactorComment' }
      & Pick<StakefactorComment, 'commentedAt' | 'createdAt' | 'createdBy' | 'description' | 'id' | 'stakefactorId'>
    )>>>, impactedConstraints?: Maybe<Array<Maybe<(
      { __typename?: 'StakefactorConstraintImpact' }
      & Pick<StakefactorConstraintImpact, 'constraintId' | 'createdAt' | 'createdBy' | 'id' | 'stakefactorId' | 'strength'>
    )>>>, impactedIndicators?: Maybe<Array<Maybe<(
      { __typename?: 'StakefactorIndicatorImpact' }
      & Pick<StakefactorIndicatorImpact, 'createdAt' | 'createdBy' | 'id' | 'indicatorId' | 'stakefactorId' | 'strength'>
    )>>>, links?: Maybe<Array<Maybe<(
      { __typename?: 'StakefactorLink' }
      & Pick<StakefactorLink, 'createdAt' | 'createdBy' | 'id' | 'originId' | 'strength' | 'targetId'>
    )>>> }
  )> }
);

export type UpdateStakefactorTagsMutationVariables = {
  id: Scalars['ID'];
  tags: Array<Maybe<Scalars['String']>>;
};


export type UpdateStakefactorTagsMutation = (
  { __typename?: 'Mutation' }
  & { updateStakefactorTags?: Maybe<(
    { __typename?: 'Stakefactor' }
    & Pick<Stakefactor, 'createdAt' | 'createdBy' | 'icon' | 'id' | 'impact' | 'name' | 'projectId' | 'scenarios' | 'tags' | 'trend' | 'x' | 'y'>
    & { comments?: Maybe<Array<Maybe<(
      { __typename?: 'StakefactorComment' }
      & Pick<StakefactorComment, 'commentedAt' | 'createdAt' | 'createdBy' | 'description' | 'id' | 'stakefactorId'>
    )>>>, impactedConstraints?: Maybe<Array<Maybe<(
      { __typename?: 'StakefactorConstraintImpact' }
      & Pick<StakefactorConstraintImpact, 'constraintId' | 'createdAt' | 'createdBy' | 'id' | 'stakefactorId' | 'strength'>
    )>>>, impactedIndicators?: Maybe<Array<Maybe<(
      { __typename?: 'StakefactorIndicatorImpact' }
      & Pick<StakefactorIndicatorImpact, 'createdAt' | 'createdBy' | 'id' | 'indicatorId' | 'stakefactorId' | 'strength'>
    )>>>, links?: Maybe<Array<Maybe<(
      { __typename?: 'StakefactorLink' }
      & Pick<StakefactorLink, 'createdAt' | 'createdBy' | 'id' | 'originId' | 'strength' | 'targetId'>
    )>>> }
  )> }
);

export type UpdateStakefactorTrendMutationVariables = {
  id: Scalars['ID'];
  trend?: Maybe<Scalars['String']>;
};


export type UpdateStakefactorTrendMutation = (
  { __typename?: 'Mutation' }
  & { updateStakefactorTrend?: Maybe<(
    { __typename?: 'Stakefactor' }
    & Pick<Stakefactor, 'createdAt' | 'createdBy' | 'icon' | 'id' | 'impact' | 'name' | 'projectId' | 'scenarios' | 'tags' | 'trend' | 'x' | 'y'>
    & { comments?: Maybe<Array<Maybe<(
      { __typename?: 'StakefactorComment' }
      & Pick<StakefactorComment, 'commentedAt' | 'createdAt' | 'createdBy' | 'description' | 'id' | 'stakefactorId'>
    )>>>, impactedConstraints?: Maybe<Array<Maybe<(
      { __typename?: 'StakefactorConstraintImpact' }
      & Pick<StakefactorConstraintImpact, 'constraintId' | 'createdAt' | 'createdBy' | 'id' | 'stakefactorId' | 'strength'>
    )>>>, impactedIndicators?: Maybe<Array<Maybe<(
      { __typename?: 'StakefactorIndicatorImpact' }
      & Pick<StakefactorIndicatorImpact, 'createdAt' | 'createdBy' | 'id' | 'indicatorId' | 'stakefactorId' | 'strength'>
    )>>>, links?: Maybe<Array<Maybe<(
      { __typename?: 'StakefactorLink' }
      & Pick<StakefactorLink, 'createdAt' | 'createdBy' | 'id' | 'originId' | 'strength' | 'targetId'>
    )>>> }
  )> }
);

export type OnCreateProjectSubscriptionVariables = {};


export type OnCreateProjectSubscription = (
  { __typename?: 'Subscription' }
  & { onCreateProject?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'createdAt' | 'createdBy' | 'description' | 'id' | 'name'>
    & { constraints?: Maybe<Array<Maybe<(
      { __typename?: 'Constraint' }
      & Pick<Constraint, 'createdAt' | 'createdBy' | 'description' | 'id' | 'name' | 'projectId' | 'scenarios' | 'trend' | 'weight'>
    )>>>, fishboneBranchs?: Maybe<Array<Maybe<(
      { __typename?: 'FishboneBranch' }
      & Pick<FishboneBranch, 'createdAt' | 'createdBy' | 'description' | 'id' | 'name' | 'projectId' | 'weight'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'FishboneItem' }
        & Pick<FishboneItem, 'completion' | 'createdAt' | 'createdBy' | 'description' | 'fishboneBranchId' | 'id' | 'name' | 'trend' | 'weight'>
      )>>> }
    )>>>, indicators?: Maybe<Array<Maybe<(
      { __typename?: 'Indicator' }
      & Pick<Indicator, 'createdAt' | 'createdBy' | 'description' | 'id' | 'name' | 'projectId' | 'scenarios' | 'trend' | 'weight'>
    )>>>, scenarios?: Maybe<Array<Maybe<(
      { __typename?: 'Scenario' }
      & Pick<Scenario, 'createdAt' | 'createdBy' | 'id' | 'name' | 'projectId'>
    )>>>, stakefactors?: Maybe<Array<Maybe<(
      { __typename?: 'Stakefactor' }
      & Pick<Stakefactor, 'createdAt' | 'createdBy' | 'icon' | 'id' | 'impact' | 'name' | 'projectId' | 'scenarios' | 'tags' | 'trend' | 'x' | 'y'>
      & { comments?: Maybe<Array<Maybe<(
        { __typename?: 'StakefactorComment' }
        & Pick<StakefactorComment, 'commentedAt' | 'createdAt' | 'createdBy' | 'description' | 'id' | 'stakefactorId'>
      )>>>, impactedConstraints?: Maybe<Array<Maybe<(
        { __typename?: 'StakefactorConstraintImpact' }
        & Pick<StakefactorConstraintImpact, 'constraintId' | 'createdAt' | 'createdBy' | 'id' | 'stakefactorId' | 'strength'>
      )>>>, impactedIndicators?: Maybe<Array<Maybe<(
        { __typename?: 'StakefactorIndicatorImpact' }
        & Pick<StakefactorIndicatorImpact, 'createdAt' | 'createdBy' | 'id' | 'indicatorId' | 'stakefactorId' | 'strength'>
      )>>>, links?: Maybe<Array<Maybe<(
        { __typename?: 'StakefactorLink' }
        & Pick<StakefactorLink, 'createdAt' | 'createdBy' | 'id' | 'originId' | 'strength' | 'targetId'>
      )>>> }
    )>>> }
  )> }
);

export type OnDeleteProjectSubscriptionVariables = {};


export type OnDeleteProjectSubscription = (
  { __typename?: 'Subscription' }
  & { onDeleteProject?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'createdAt' | 'createdBy' | 'description' | 'id' | 'name'>
    & { constraints?: Maybe<Array<Maybe<(
      { __typename?: 'Constraint' }
      & Pick<Constraint, 'createdAt' | 'createdBy' | 'description' | 'id' | 'name' | 'projectId' | 'scenarios' | 'trend' | 'weight'>
    )>>>, fishboneBranchs?: Maybe<Array<Maybe<(
      { __typename?: 'FishboneBranch' }
      & Pick<FishboneBranch, 'createdAt' | 'createdBy' | 'description' | 'id' | 'name' | 'projectId' | 'weight'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'FishboneItem' }
        & Pick<FishboneItem, 'completion' | 'createdAt' | 'createdBy' | 'description' | 'fishboneBranchId' | 'id' | 'name' | 'trend' | 'weight'>
      )>>> }
    )>>>, indicators?: Maybe<Array<Maybe<(
      { __typename?: 'Indicator' }
      & Pick<Indicator, 'createdAt' | 'createdBy' | 'description' | 'id' | 'name' | 'projectId' | 'scenarios' | 'trend' | 'weight'>
    )>>>, scenarios?: Maybe<Array<Maybe<(
      { __typename?: 'Scenario' }
      & Pick<Scenario, 'createdAt' | 'createdBy' | 'id' | 'name' | 'projectId'>
    )>>>, stakefactors?: Maybe<Array<Maybe<(
      { __typename?: 'Stakefactor' }
      & Pick<Stakefactor, 'createdAt' | 'createdBy' | 'icon' | 'id' | 'impact' | 'name' | 'projectId' | 'scenarios' | 'tags' | 'trend' | 'x' | 'y'>
      & { comments?: Maybe<Array<Maybe<(
        { __typename?: 'StakefactorComment' }
        & Pick<StakefactorComment, 'commentedAt' | 'createdAt' | 'createdBy' | 'description' | 'id' | 'stakefactorId'>
      )>>>, impactedConstraints?: Maybe<Array<Maybe<(
        { __typename?: 'StakefactorConstraintImpact' }
        & Pick<StakefactorConstraintImpact, 'constraintId' | 'createdAt' | 'createdBy' | 'id' | 'stakefactorId' | 'strength'>
      )>>>, impactedIndicators?: Maybe<Array<Maybe<(
        { __typename?: 'StakefactorIndicatorImpact' }
        & Pick<StakefactorIndicatorImpact, 'createdAt' | 'createdBy' | 'id' | 'indicatorId' | 'stakefactorId' | 'strength'>
      )>>>, links?: Maybe<Array<Maybe<(
        { __typename?: 'StakefactorLink' }
        & Pick<StakefactorLink, 'createdAt' | 'createdBy' | 'id' | 'originId' | 'strength' | 'targetId'>
      )>>> }
    )>>> }
  )> }
);

export type OnUpdateProjectSubscriptionVariables = {
  id?: Maybe<Scalars['ID']>;
};


export type OnUpdateProjectSubscription = (
  { __typename?: 'Subscription' }
  & { onUpdateProject?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'createdAt' | 'createdBy' | 'description' | 'id' | 'name'>
    & { constraints?: Maybe<Array<Maybe<(
      { __typename?: 'Constraint' }
      & Pick<Constraint, 'createdAt' | 'createdBy' | 'description' | 'id' | 'name' | 'projectId' | 'scenarios' | 'trend' | 'weight'>
    )>>>, fishboneBranchs?: Maybe<Array<Maybe<(
      { __typename?: 'FishboneBranch' }
      & Pick<FishboneBranch, 'createdAt' | 'createdBy' | 'description' | 'id' | 'name' | 'projectId' | 'weight'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'FishboneItem' }
        & Pick<FishboneItem, 'completion' | 'createdAt' | 'createdBy' | 'description' | 'fishboneBranchId' | 'id' | 'name' | 'trend' | 'weight'>
      )>>> }
    )>>>, indicators?: Maybe<Array<Maybe<(
      { __typename?: 'Indicator' }
      & Pick<Indicator, 'createdAt' | 'createdBy' | 'description' | 'id' | 'name' | 'projectId' | 'scenarios' | 'trend' | 'weight'>
    )>>>, scenarios?: Maybe<Array<Maybe<(
      { __typename?: 'Scenario' }
      & Pick<Scenario, 'createdAt' | 'createdBy' | 'id' | 'name' | 'projectId'>
    )>>>, stakefactors?: Maybe<Array<Maybe<(
      { __typename?: 'Stakefactor' }
      & Pick<Stakefactor, 'createdAt' | 'createdBy' | 'icon' | 'id' | 'impact' | 'name' | 'projectId' | 'scenarios' | 'tags' | 'trend' | 'x' | 'y'>
      & { comments?: Maybe<Array<Maybe<(
        { __typename?: 'StakefactorComment' }
        & Pick<StakefactorComment, 'commentedAt' | 'createdAt' | 'createdBy' | 'description' | 'id' | 'stakefactorId'>
      )>>>, impactedConstraints?: Maybe<Array<Maybe<(
        { __typename?: 'StakefactorConstraintImpact' }
        & Pick<StakefactorConstraintImpact, 'constraintId' | 'createdAt' | 'createdBy' | 'id' | 'stakefactorId' | 'strength'>
      )>>>, impactedIndicators?: Maybe<Array<Maybe<(
        { __typename?: 'StakefactorIndicatorImpact' }
        & Pick<StakefactorIndicatorImpact, 'createdAt' | 'createdBy' | 'id' | 'indicatorId' | 'stakefactorId' | 'strength'>
      )>>>, links?: Maybe<Array<Maybe<(
        { __typename?: 'StakefactorLink' }
        & Pick<StakefactorLink, 'createdAt' | 'createdBy' | 'id' | 'originId' | 'strength' | 'targetId'>
      )>>> }
    )>>> }
  )> }
);


export const GetPricesDocument = gql`
    query GetPrices($filter: String) {
  getPrices(filter: $filter) {
    currency
    id
    metadata
    recurring
    unit_amount
  }
}
    `;

/**
 * __useGetPricesQuery__
 *
 * To run a query within a React component, call `useGetPricesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPricesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPricesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetPricesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPricesQuery, GetPricesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPricesQuery, GetPricesQueryVariables>(GetPricesDocument, baseOptions);
      }
export function useGetPricesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPricesQuery, GetPricesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPricesQuery, GetPricesQueryVariables>(GetPricesDocument, baseOptions);
        }
export type GetPricesQueryHookResult = ReturnType<typeof useGetPricesQuery>;
export type GetPricesLazyQueryHookResult = ReturnType<typeof useGetPricesLazyQuery>;
export type GetPricesQueryResult = ApolloReactCommon.QueryResult<GetPricesQuery, GetPricesQueryVariables>;
export const GetProjectDocument = gql`
    query GetProject($id: ID!) {
  getProject(id: $id) {
    constraints {
      createdAt
      createdBy
      description
      id
      name
      projectId
      scenarios
      trend
      weight
    }
    createdAt
    createdBy
    description
    fishboneBranchs {
      createdAt
      createdBy
      description
      id
      items {
        completion
        createdAt
        createdBy
        description
        fishboneBranchId
        id
        name
        trend
        weight
      }
      name
      projectId
      weight
    }
    id
    indicators {
      createdAt
      createdBy
      description
      id
      name
      projectId
      scenarios
      trend
      weight
    }
    name
    scenarios {
      createdAt
      createdBy
      id
      name
      projectId
    }
    stakefactors {
      comments {
        commentedAt
        createdAt
        createdBy
        description
        id
        stakefactorId
      }
      createdAt
      createdBy
      icon
      id
      impact
      impactedConstraints {
        constraintId
        createdAt
        createdBy
        id
        stakefactorId
        strength
      }
      impactedIndicators {
        createdAt
        createdBy
        id
        indicatorId
        stakefactorId
        strength
      }
      links {
        createdAt
        createdBy
        id
        originId
        strength
        targetId
      }
      name
      projectId
      scenarios
      tags
      trend
      x
      y
    }
  }
}
    `;

/**
 * __useGetProjectQuery__
 *
 * To run a query within a React component, call `useGetProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProjectQuery, GetProjectQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProjectQuery, GetProjectQueryVariables>(GetProjectDocument, baseOptions);
      }
export function useGetProjectLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectQuery, GetProjectQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProjectQuery, GetProjectQueryVariables>(GetProjectDocument, baseOptions);
        }
export type GetProjectQueryHookResult = ReturnType<typeof useGetProjectQuery>;
export type GetProjectLazyQueryHookResult = ReturnType<typeof useGetProjectLazyQuery>;
export type GetProjectQueryResult = ApolloReactCommon.QueryResult<GetProjectQuery, GetProjectQueryVariables>;
export const GetSubscriptionStatusDocument = gql`
    query GetSubscriptionStatus {
  getSubscriptionStatus {
    email
    endAt
    projects
    startAt
    status
  }
}
    `;

/**
 * __useGetSubscriptionStatusQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSubscriptionStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubscriptionStatusQuery, GetSubscriptionStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubscriptionStatusQuery, GetSubscriptionStatusQueryVariables>(GetSubscriptionStatusDocument, baseOptions);
      }
export function useGetSubscriptionStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubscriptionStatusQuery, GetSubscriptionStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubscriptionStatusQuery, GetSubscriptionStatusQueryVariables>(GetSubscriptionStatusDocument, baseOptions);
        }
export type GetSubscriptionStatusQueryHookResult = ReturnType<typeof useGetSubscriptionStatusQuery>;
export type GetSubscriptionStatusLazyQueryHookResult = ReturnType<typeof useGetSubscriptionStatusLazyQuery>;
export type GetSubscriptionStatusQueryResult = ApolloReactCommon.QueryResult<GetSubscriptionStatusQuery, GetSubscriptionStatusQueryVariables>;
export const ListProjectsDocument = gql`
    query ListProjects($filter: TableProjectFilterInput, $limit: Int, $nextToken: String, $u: String) {
  listProjects(filter: $filter, limit: $limit, nextToken: $nextToken, u: $u) {
    items {
      constraints {
        createdAt
        createdBy
        description
        id
        name
        projectId
        scenarios
        trend
        weight
      }
      createdAt
      createdBy
      description
      fishboneBranchs {
        createdAt
        createdBy
        description
        id
        items {
          completion
          createdAt
          createdBy
          description
          fishboneBranchId
          id
          name
          trend
          weight
        }
        name
        projectId
        weight
      }
      id
      indicators {
        createdAt
        createdBy
        description
        id
        name
        projectId
        scenarios
        trend
        weight
      }
      name
      scenarios {
        createdAt
        createdBy
        id
        name
        projectId
      }
      stakefactors {
        comments {
          commentedAt
          createdAt
          createdBy
          description
          id
          stakefactorId
        }
        createdAt
        createdBy
        icon
        id
        impact
        impactedConstraints {
          constraintId
          createdAt
          createdBy
          id
          stakefactorId
          strength
        }
        impactedIndicators {
          createdAt
          createdBy
          id
          indicatorId
          stakefactorId
          strength
        }
        links {
          createdAt
          createdBy
          id
          originId
          strength
          targetId
        }
        name
        projectId
        scenarios
        tags
        trend
        x
        y
      }
    }
    nextToken
  }
}
    `;

/**
 * __useListProjectsQuery__
 *
 * To run a query within a React component, call `useListProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListProjectsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *      u: // value for 'u'
 *   },
 * });
 */
export function useListProjectsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListProjectsQuery, ListProjectsQueryVariables>) {
        return ApolloReactHooks.useQuery<ListProjectsQuery, ListProjectsQueryVariables>(ListProjectsDocument, baseOptions);
      }
export function useListProjectsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListProjectsQuery, ListProjectsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListProjectsQuery, ListProjectsQueryVariables>(ListProjectsDocument, baseOptions);
        }
export type ListProjectsQueryHookResult = ReturnType<typeof useListProjectsQuery>;
export type ListProjectsLazyQueryHookResult = ReturnType<typeof useListProjectsLazyQuery>;
export type ListProjectsQueryResult = ApolloReactCommon.QueryResult<ListProjectsQuery, ListProjectsQueryVariables>;
export const QueryProjectsByCreatedByIndexDocument = gql`
    query QueryProjectsByCreatedByIndex($after: String, $createdBy: String!, $first: Int) {
  queryProjectsByCreatedByIndex(after: $after, createdBy: $createdBy, first: $first) {
    items {
      constraints {
        createdAt
        createdBy
        description
        id
        name
        projectId
        scenarios
        trend
        weight
      }
      createdAt
      createdBy
      description
      fishboneBranchs {
        createdAt
        createdBy
        description
        id
        items {
          completion
          createdAt
          createdBy
          description
          fishboneBranchId
          id
          name
          trend
          weight
        }
        name
        projectId
        weight
      }
      id
      indicators {
        createdAt
        createdBy
        description
        id
        name
        projectId
        scenarios
        trend
        weight
      }
      name
      scenarios {
        createdAt
        createdBy
        id
        name
        projectId
      }
      stakefactors {
        comments {
          commentedAt
          createdAt
          createdBy
          description
          id
          stakefactorId
        }
        createdAt
        createdBy
        icon
        id
        impact
        impactedConstraints {
          constraintId
          createdAt
          createdBy
          id
          stakefactorId
          strength
        }
        impactedIndicators {
          createdAt
          createdBy
          id
          indicatorId
          stakefactorId
          strength
        }
        links {
          createdAt
          createdBy
          id
          originId
          strength
          targetId
        }
        name
        projectId
        scenarios
        tags
        trend
        x
        y
      }
    }
    nextToken
  }
}
    `;

/**
 * __useQueryProjectsByCreatedByIndexQuery__
 *
 * To run a query within a React component, call `useQueryProjectsByCreatedByIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryProjectsByCreatedByIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryProjectsByCreatedByIndexQuery({
 *   variables: {
 *      after: // value for 'after'
 *      createdBy: // value for 'createdBy'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useQueryProjectsByCreatedByIndexQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<QueryProjectsByCreatedByIndexQuery, QueryProjectsByCreatedByIndexQueryVariables>) {
        return ApolloReactHooks.useQuery<QueryProjectsByCreatedByIndexQuery, QueryProjectsByCreatedByIndexQueryVariables>(QueryProjectsByCreatedByIndexDocument, baseOptions);
      }
export function useQueryProjectsByCreatedByIndexLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<QueryProjectsByCreatedByIndexQuery, QueryProjectsByCreatedByIndexQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<QueryProjectsByCreatedByIndexQuery, QueryProjectsByCreatedByIndexQueryVariables>(QueryProjectsByCreatedByIndexDocument, baseOptions);
        }
export type QueryProjectsByCreatedByIndexQueryHookResult = ReturnType<typeof useQueryProjectsByCreatedByIndexQuery>;
export type QueryProjectsByCreatedByIndexLazyQueryHookResult = ReturnType<typeof useQueryProjectsByCreatedByIndexLazyQuery>;
export type QueryProjectsByCreatedByIndexQueryResult = ApolloReactCommon.QueryResult<QueryProjectsByCreatedByIndexQuery, QueryProjectsByCreatedByIndexQueryVariables>;
export const CloneProjectDocument = gql`
    mutation CloneProject($id: ID!) {
  cloneProject(id: $id)
}
    `;
export type CloneProjectMutationFn = ApolloReactCommon.MutationFunction<CloneProjectMutation, CloneProjectMutationVariables>;

/**
 * __useCloneProjectMutation__
 *
 * To run a mutation, you first call `useCloneProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloneProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cloneProjectMutation, { data, loading, error }] = useCloneProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCloneProjectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CloneProjectMutation, CloneProjectMutationVariables>) {
        return ApolloReactHooks.useMutation<CloneProjectMutation, CloneProjectMutationVariables>(CloneProjectDocument, baseOptions);
      }
export type CloneProjectMutationHookResult = ReturnType<typeof useCloneProjectMutation>;
export type CloneProjectMutationResult = ApolloReactCommon.MutationResult<CloneProjectMutation>;
export type CloneProjectMutationOptions = ApolloReactCommon.BaseMutationOptions<CloneProjectMutation, CloneProjectMutationVariables>;
export const CreateConstraintDocument = gql`
    mutation CreateConstraint($description: String, $name: String!, $projectId: String!, $trend: String, $weight: Int) {
  createConstraint(description: $description, name: $name, projectId: $projectId, trend: $trend, weight: $weight) {
    createdAt
    createdBy
    description
    id
    name
    projectId
    scenarios
    trend
    weight
  }
}
    `;
export type CreateConstraintMutationFn = ApolloReactCommon.MutationFunction<CreateConstraintMutation, CreateConstraintMutationVariables>;

/**
 * __useCreateConstraintMutation__
 *
 * To run a mutation, you first call `useCreateConstraintMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConstraintMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConstraintMutation, { data, loading, error }] = useCreateConstraintMutation({
 *   variables: {
 *      description: // value for 'description'
 *      name: // value for 'name'
 *      projectId: // value for 'projectId'
 *      trend: // value for 'trend'
 *      weight: // value for 'weight'
 *   },
 * });
 */
export function useCreateConstraintMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateConstraintMutation, CreateConstraintMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateConstraintMutation, CreateConstraintMutationVariables>(CreateConstraintDocument, baseOptions);
      }
export type CreateConstraintMutationHookResult = ReturnType<typeof useCreateConstraintMutation>;
export type CreateConstraintMutationResult = ApolloReactCommon.MutationResult<CreateConstraintMutation>;
export type CreateConstraintMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateConstraintMutation, CreateConstraintMutationVariables>;
export const CreateFishboneBranchDocument = gql`
    mutation CreateFishboneBranch($description: String, $name: String!, $projectId: String!, $weight: Int) {
  createFishboneBranch(description: $description, name: $name, projectId: $projectId, weight: $weight) {
    createdAt
    createdBy
    description
    id
    items {
      completion
      createdAt
      createdBy
      description
      fishboneBranchId
      id
      name
      trend
      weight
    }
    name
    projectId
    weight
  }
}
    `;
export type CreateFishboneBranchMutationFn = ApolloReactCommon.MutationFunction<CreateFishboneBranchMutation, CreateFishboneBranchMutationVariables>;

/**
 * __useCreateFishboneBranchMutation__
 *
 * To run a mutation, you first call `useCreateFishboneBranchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFishboneBranchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFishboneBranchMutation, { data, loading, error }] = useCreateFishboneBranchMutation({
 *   variables: {
 *      description: // value for 'description'
 *      name: // value for 'name'
 *      projectId: // value for 'projectId'
 *      weight: // value for 'weight'
 *   },
 * });
 */
export function useCreateFishboneBranchMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateFishboneBranchMutation, CreateFishboneBranchMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateFishboneBranchMutation, CreateFishboneBranchMutationVariables>(CreateFishboneBranchDocument, baseOptions);
      }
export type CreateFishboneBranchMutationHookResult = ReturnType<typeof useCreateFishboneBranchMutation>;
export type CreateFishboneBranchMutationResult = ApolloReactCommon.MutationResult<CreateFishboneBranchMutation>;
export type CreateFishboneBranchMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateFishboneBranchMutation, CreateFishboneBranchMutationVariables>;
export const CreateFishboneItemDocument = gql`
    mutation CreateFishboneItem($completion: Int, $description: String, $fishboneBranchId: String!, $name: String!, $trend: String, $weight: Int) {
  createFishboneItem(completion: $completion, description: $description, fishboneBranchId: $fishboneBranchId, name: $name, trend: $trend, weight: $weight) {
    completion
    createdAt
    createdBy
    description
    fishboneBranchId
    id
    name
    trend
    weight
  }
}
    `;
export type CreateFishboneItemMutationFn = ApolloReactCommon.MutationFunction<CreateFishboneItemMutation, CreateFishboneItemMutationVariables>;

/**
 * __useCreateFishboneItemMutation__
 *
 * To run a mutation, you first call `useCreateFishboneItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFishboneItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFishboneItemMutation, { data, loading, error }] = useCreateFishboneItemMutation({
 *   variables: {
 *      completion: // value for 'completion'
 *      description: // value for 'description'
 *      fishboneBranchId: // value for 'fishboneBranchId'
 *      name: // value for 'name'
 *      trend: // value for 'trend'
 *      weight: // value for 'weight'
 *   },
 * });
 */
export function useCreateFishboneItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateFishboneItemMutation, CreateFishboneItemMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateFishboneItemMutation, CreateFishboneItemMutationVariables>(CreateFishboneItemDocument, baseOptions);
      }
export type CreateFishboneItemMutationHookResult = ReturnType<typeof useCreateFishboneItemMutation>;
export type CreateFishboneItemMutationResult = ApolloReactCommon.MutationResult<CreateFishboneItemMutation>;
export type CreateFishboneItemMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateFishboneItemMutation, CreateFishboneItemMutationVariables>;
export const CreateIndicatorDocument = gql`
    mutation CreateIndicator($description: String, $name: String!, $projectId: String!, $trend: String, $weight: Int) {
  createIndicator(description: $description, name: $name, projectId: $projectId, trend: $trend, weight: $weight) {
    createdAt
    createdBy
    description
    id
    name
    projectId
    scenarios
    trend
    weight
  }
}
    `;
export type CreateIndicatorMutationFn = ApolloReactCommon.MutationFunction<CreateIndicatorMutation, CreateIndicatorMutationVariables>;

/**
 * __useCreateIndicatorMutation__
 *
 * To run a mutation, you first call `useCreateIndicatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIndicatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIndicatorMutation, { data, loading, error }] = useCreateIndicatorMutation({
 *   variables: {
 *      description: // value for 'description'
 *      name: // value for 'name'
 *      projectId: // value for 'projectId'
 *      trend: // value for 'trend'
 *      weight: // value for 'weight'
 *   },
 * });
 */
export function useCreateIndicatorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateIndicatorMutation, CreateIndicatorMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateIndicatorMutation, CreateIndicatorMutationVariables>(CreateIndicatorDocument, baseOptions);
      }
export type CreateIndicatorMutationHookResult = ReturnType<typeof useCreateIndicatorMutation>;
export type CreateIndicatorMutationResult = ApolloReactCommon.MutationResult<CreateIndicatorMutation>;
export type CreateIndicatorMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateIndicatorMutation, CreateIndicatorMutationVariables>;
export const CreateProjectDocument = gql`
    mutation CreateProject($description: String, $name: String!) {
  createProject(description: $description, name: $name) {
    constraints {
      createdAt
      createdBy
      description
      id
      name
      projectId
      scenarios
      trend
      weight
    }
    createdAt
    createdBy
    description
    fishboneBranchs {
      createdAt
      createdBy
      description
      id
      items {
        completion
        createdAt
        createdBy
        description
        fishboneBranchId
        id
        name
        trend
        weight
      }
      name
      projectId
      weight
    }
    id
    indicators {
      createdAt
      createdBy
      description
      id
      name
      projectId
      scenarios
      trend
      weight
    }
    name
    scenarios {
      createdAt
      createdBy
      id
      name
      projectId
    }
    stakefactors {
      comments {
        commentedAt
        createdAt
        createdBy
        description
        id
        stakefactorId
      }
      createdAt
      createdBy
      icon
      id
      impact
      impactedConstraints {
        constraintId
        createdAt
        createdBy
        id
        stakefactorId
        strength
      }
      impactedIndicators {
        createdAt
        createdBy
        id
        indicatorId
        stakefactorId
        strength
      }
      links {
        createdAt
        createdBy
        id
        originId
        strength
        targetId
      }
      name
      projectId
      scenarios
      tags
      trend
      x
      y
    }
  }
}
    `;
export type CreateProjectMutationFn = ApolloReactCommon.MutationFunction<CreateProjectMutation, CreateProjectMutationVariables>;

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      description: // value for 'description'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateProjectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateProjectMutation, CreateProjectMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateProjectMutation, CreateProjectMutationVariables>(CreateProjectDocument, baseOptions);
      }
export type CreateProjectMutationHookResult = ReturnType<typeof useCreateProjectMutation>;
export type CreateProjectMutationResult = ApolloReactCommon.MutationResult<CreateProjectMutation>;
export type CreateProjectMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateProjectMutation, CreateProjectMutationVariables>;
export const CreateScenarioDocument = gql`
    mutation CreateScenario($name: String!, $projectId: String!) {
  createScenario(name: $name, projectId: $projectId) {
    createdAt
    createdBy
    id
    name
    projectId
  }
}
    `;
export type CreateScenarioMutationFn = ApolloReactCommon.MutationFunction<CreateScenarioMutation, CreateScenarioMutationVariables>;

/**
 * __useCreateScenarioMutation__
 *
 * To run a mutation, you first call `useCreateScenarioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScenarioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScenarioMutation, { data, loading, error }] = useCreateScenarioMutation({
 *   variables: {
 *      name: // value for 'name'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useCreateScenarioMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateScenarioMutation, CreateScenarioMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateScenarioMutation, CreateScenarioMutationVariables>(CreateScenarioDocument, baseOptions);
      }
export type CreateScenarioMutationHookResult = ReturnType<typeof useCreateScenarioMutation>;
export type CreateScenarioMutationResult = ApolloReactCommon.MutationResult<CreateScenarioMutation>;
export type CreateScenarioMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateScenarioMutation, CreateScenarioMutationVariables>;
export const CreateStakefactorDocument = gql`
    mutation CreateStakefactor($description: String, $icon: String, $impact: String, $name: String!, $projectId: String!, $tags: [String], $trend: String, $x: Float, $y: Float) {
  createStakefactor(description: $description, icon: $icon, impact: $impact, name: $name, projectId: $projectId, tags: $tags, trend: $trend, x: $x, y: $y) {
    comments {
      commentedAt
      createdAt
      createdBy
      description
      id
      stakefactorId
    }
    createdAt
    createdBy
    icon
    id
    impact
    impactedConstraints {
      constraintId
      createdAt
      createdBy
      id
      stakefactorId
      strength
    }
    impactedIndicators {
      createdAt
      createdBy
      id
      indicatorId
      stakefactorId
      strength
    }
    links {
      createdAt
      createdBy
      id
      originId
      strength
      targetId
    }
    name
    projectId
    scenarios
    tags
    trend
    x
    y
  }
}
    `;
export type CreateStakefactorMutationFn = ApolloReactCommon.MutationFunction<CreateStakefactorMutation, CreateStakefactorMutationVariables>;

/**
 * __useCreateStakefactorMutation__
 *
 * To run a mutation, you first call `useCreateStakefactorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStakefactorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStakefactorMutation, { data, loading, error }] = useCreateStakefactorMutation({
 *   variables: {
 *      description: // value for 'description'
 *      icon: // value for 'icon'
 *      impact: // value for 'impact'
 *      name: // value for 'name'
 *      projectId: // value for 'projectId'
 *      tags: // value for 'tags'
 *      trend: // value for 'trend'
 *      x: // value for 'x'
 *      y: // value for 'y'
 *   },
 * });
 */
export function useCreateStakefactorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateStakefactorMutation, CreateStakefactorMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateStakefactorMutation, CreateStakefactorMutationVariables>(CreateStakefactorDocument, baseOptions);
      }
export type CreateStakefactorMutationHookResult = ReturnType<typeof useCreateStakefactorMutation>;
export type CreateStakefactorMutationResult = ApolloReactCommon.MutationResult<CreateStakefactorMutation>;
export type CreateStakefactorMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateStakefactorMutation, CreateStakefactorMutationVariables>;
export const CreateStakefactorCommentDocument = gql`
    mutation CreateStakefactorComment($commentedAt: String!, $description: String!, $stakefactorId: String!) {
  createStakefactorComment(commentedAt: $commentedAt, description: $description, stakefactorId: $stakefactorId) {
    commentedAt
    createdAt
    createdBy
    description
    id
    stakefactorId
  }
}
    `;
export type CreateStakefactorCommentMutationFn = ApolloReactCommon.MutationFunction<CreateStakefactorCommentMutation, CreateStakefactorCommentMutationVariables>;

/**
 * __useCreateStakefactorCommentMutation__
 *
 * To run a mutation, you first call `useCreateStakefactorCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStakefactorCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStakefactorCommentMutation, { data, loading, error }] = useCreateStakefactorCommentMutation({
 *   variables: {
 *      commentedAt: // value for 'commentedAt'
 *      description: // value for 'description'
 *      stakefactorId: // value for 'stakefactorId'
 *   },
 * });
 */
export function useCreateStakefactorCommentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateStakefactorCommentMutation, CreateStakefactorCommentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateStakefactorCommentMutation, CreateStakefactorCommentMutationVariables>(CreateStakefactorCommentDocument, baseOptions);
      }
export type CreateStakefactorCommentMutationHookResult = ReturnType<typeof useCreateStakefactorCommentMutation>;
export type CreateStakefactorCommentMutationResult = ApolloReactCommon.MutationResult<CreateStakefactorCommentMutation>;
export type CreateStakefactorCommentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateStakefactorCommentMutation, CreateStakefactorCommentMutationVariables>;
export const CreateStakefactorConstraintImpactDocument = gql`
    mutation CreateStakefactorConstraintImpact($constraintId: String!, $stakefactorId: String!) {
  createStakefactorConstraintImpact(constraintId: $constraintId, stakefactorId: $stakefactorId) {
    constraintId
    createdAt
    createdBy
    id
    stakefactorId
    strength
  }
}
    `;
export type CreateStakefactorConstraintImpactMutationFn = ApolloReactCommon.MutationFunction<CreateStakefactorConstraintImpactMutation, CreateStakefactorConstraintImpactMutationVariables>;

/**
 * __useCreateStakefactorConstraintImpactMutation__
 *
 * To run a mutation, you first call `useCreateStakefactorConstraintImpactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStakefactorConstraintImpactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStakefactorConstraintImpactMutation, { data, loading, error }] = useCreateStakefactorConstraintImpactMutation({
 *   variables: {
 *      constraintId: // value for 'constraintId'
 *      stakefactorId: // value for 'stakefactorId'
 *   },
 * });
 */
export function useCreateStakefactorConstraintImpactMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateStakefactorConstraintImpactMutation, CreateStakefactorConstraintImpactMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateStakefactorConstraintImpactMutation, CreateStakefactorConstraintImpactMutationVariables>(CreateStakefactorConstraintImpactDocument, baseOptions);
      }
export type CreateStakefactorConstraintImpactMutationHookResult = ReturnType<typeof useCreateStakefactorConstraintImpactMutation>;
export type CreateStakefactorConstraintImpactMutationResult = ApolloReactCommon.MutationResult<CreateStakefactorConstraintImpactMutation>;
export type CreateStakefactorConstraintImpactMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateStakefactorConstraintImpactMutation, CreateStakefactorConstraintImpactMutationVariables>;
export const CreateStakefactorIndicatorImpactDocument = gql`
    mutation CreateStakefactorIndicatorImpact($indicatorId: String!, $stakefactorId: String!) {
  createStakefactorIndicatorImpact(indicatorId: $indicatorId, stakefactorId: $stakefactorId) {
    createdAt
    createdBy
    id
    indicatorId
    stakefactorId
    strength
  }
}
    `;
export type CreateStakefactorIndicatorImpactMutationFn = ApolloReactCommon.MutationFunction<CreateStakefactorIndicatorImpactMutation, CreateStakefactorIndicatorImpactMutationVariables>;

/**
 * __useCreateStakefactorIndicatorImpactMutation__
 *
 * To run a mutation, you first call `useCreateStakefactorIndicatorImpactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStakefactorIndicatorImpactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStakefactorIndicatorImpactMutation, { data, loading, error }] = useCreateStakefactorIndicatorImpactMutation({
 *   variables: {
 *      indicatorId: // value for 'indicatorId'
 *      stakefactorId: // value for 'stakefactorId'
 *   },
 * });
 */
export function useCreateStakefactorIndicatorImpactMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateStakefactorIndicatorImpactMutation, CreateStakefactorIndicatorImpactMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateStakefactorIndicatorImpactMutation, CreateStakefactorIndicatorImpactMutationVariables>(CreateStakefactorIndicatorImpactDocument, baseOptions);
      }
export type CreateStakefactorIndicatorImpactMutationHookResult = ReturnType<typeof useCreateStakefactorIndicatorImpactMutation>;
export type CreateStakefactorIndicatorImpactMutationResult = ApolloReactCommon.MutationResult<CreateStakefactorIndicatorImpactMutation>;
export type CreateStakefactorIndicatorImpactMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateStakefactorIndicatorImpactMutation, CreateStakefactorIndicatorImpactMutationVariables>;
export const CreateStakefactorLinkDocument = gql`
    mutation CreateStakefactorLink($originId: String!, $targetId: String!) {
  createStakefactorLink(originId: $originId, targetId: $targetId) {
    createdAt
    createdBy
    id
    originId
    strength
    targetId
  }
}
    `;
export type CreateStakefactorLinkMutationFn = ApolloReactCommon.MutationFunction<CreateStakefactorLinkMutation, CreateStakefactorLinkMutationVariables>;

/**
 * __useCreateStakefactorLinkMutation__
 *
 * To run a mutation, you first call `useCreateStakefactorLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStakefactorLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStakefactorLinkMutation, { data, loading, error }] = useCreateStakefactorLinkMutation({
 *   variables: {
 *      originId: // value for 'originId'
 *      targetId: // value for 'targetId'
 *   },
 * });
 */
export function useCreateStakefactorLinkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateStakefactorLinkMutation, CreateStakefactorLinkMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateStakefactorLinkMutation, CreateStakefactorLinkMutationVariables>(CreateStakefactorLinkDocument, baseOptions);
      }
export type CreateStakefactorLinkMutationHookResult = ReturnType<typeof useCreateStakefactorLinkMutation>;
export type CreateStakefactorLinkMutationResult = ApolloReactCommon.MutationResult<CreateStakefactorLinkMutation>;
export type CreateStakefactorLinkMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateStakefactorLinkMutation, CreateStakefactorLinkMutationVariables>;
export const DeleteConstraintDocument = gql`
    mutation DeleteConstraint($id: ID!) {
  deleteConstraint(id: $id) {
    createdAt
    createdBy
    description
    id
    name
    projectId
    scenarios
    trend
    weight
  }
}
    `;
export type DeleteConstraintMutationFn = ApolloReactCommon.MutationFunction<DeleteConstraintMutation, DeleteConstraintMutationVariables>;

/**
 * __useDeleteConstraintMutation__
 *
 * To run a mutation, you first call `useDeleteConstraintMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConstraintMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteConstraintMutation, { data, loading, error }] = useDeleteConstraintMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteConstraintMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteConstraintMutation, DeleteConstraintMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteConstraintMutation, DeleteConstraintMutationVariables>(DeleteConstraintDocument, baseOptions);
      }
export type DeleteConstraintMutationHookResult = ReturnType<typeof useDeleteConstraintMutation>;
export type DeleteConstraintMutationResult = ApolloReactCommon.MutationResult<DeleteConstraintMutation>;
export type DeleteConstraintMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteConstraintMutation, DeleteConstraintMutationVariables>;
export const DeleteFishboneBranchDocument = gql`
    mutation DeleteFishboneBranch($id: ID!) {
  deleteFishboneBranch(id: $id) {
    createdAt
    createdBy
    description
    id
    items {
      completion
      createdAt
      createdBy
      description
      fishboneBranchId
      id
      name
      trend
      weight
    }
    name
    projectId
    weight
  }
}
    `;
export type DeleteFishboneBranchMutationFn = ApolloReactCommon.MutationFunction<DeleteFishboneBranchMutation, DeleteFishboneBranchMutationVariables>;

/**
 * __useDeleteFishboneBranchMutation__
 *
 * To run a mutation, you first call `useDeleteFishboneBranchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFishboneBranchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFishboneBranchMutation, { data, loading, error }] = useDeleteFishboneBranchMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFishboneBranchMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteFishboneBranchMutation, DeleteFishboneBranchMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteFishboneBranchMutation, DeleteFishboneBranchMutationVariables>(DeleteFishboneBranchDocument, baseOptions);
      }
export type DeleteFishboneBranchMutationHookResult = ReturnType<typeof useDeleteFishboneBranchMutation>;
export type DeleteFishboneBranchMutationResult = ApolloReactCommon.MutationResult<DeleteFishboneBranchMutation>;
export type DeleteFishboneBranchMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteFishboneBranchMutation, DeleteFishboneBranchMutationVariables>;
export const DeleteFishboneItemDocument = gql`
    mutation DeleteFishboneItem($id: ID!) {
  deleteFishboneItem(id: $id) {
    completion
    createdAt
    createdBy
    description
    fishboneBranchId
    id
    name
    trend
    weight
  }
}
    `;
export type DeleteFishboneItemMutationFn = ApolloReactCommon.MutationFunction<DeleteFishboneItemMutation, DeleteFishboneItemMutationVariables>;

/**
 * __useDeleteFishboneItemMutation__
 *
 * To run a mutation, you first call `useDeleteFishboneItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFishboneItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFishboneItemMutation, { data, loading, error }] = useDeleteFishboneItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFishboneItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteFishboneItemMutation, DeleteFishboneItemMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteFishboneItemMutation, DeleteFishboneItemMutationVariables>(DeleteFishboneItemDocument, baseOptions);
      }
export type DeleteFishboneItemMutationHookResult = ReturnType<typeof useDeleteFishboneItemMutation>;
export type DeleteFishboneItemMutationResult = ApolloReactCommon.MutationResult<DeleteFishboneItemMutation>;
export type DeleteFishboneItemMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteFishboneItemMutation, DeleteFishboneItemMutationVariables>;
export const DeleteIndicatorDocument = gql`
    mutation DeleteIndicator($id: ID!) {
  deleteIndicator(id: $id) {
    createdAt
    createdBy
    description
    id
    name
    projectId
    scenarios
    trend
    weight
  }
}
    `;
export type DeleteIndicatorMutationFn = ApolloReactCommon.MutationFunction<DeleteIndicatorMutation, DeleteIndicatorMutationVariables>;

/**
 * __useDeleteIndicatorMutation__
 *
 * To run a mutation, you first call `useDeleteIndicatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteIndicatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteIndicatorMutation, { data, loading, error }] = useDeleteIndicatorMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteIndicatorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteIndicatorMutation, DeleteIndicatorMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteIndicatorMutation, DeleteIndicatorMutationVariables>(DeleteIndicatorDocument, baseOptions);
      }
export type DeleteIndicatorMutationHookResult = ReturnType<typeof useDeleteIndicatorMutation>;
export type DeleteIndicatorMutationResult = ApolloReactCommon.MutationResult<DeleteIndicatorMutation>;
export type DeleteIndicatorMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteIndicatorMutation, DeleteIndicatorMutationVariables>;
export const DeleteProjectDocument = gql`
    mutation DeleteProject($id: ID!) {
  deleteProject(id: $id) {
    constraints {
      createdAt
      createdBy
      description
      id
      name
      projectId
      scenarios
      trend
      weight
    }
    createdAt
    createdBy
    description
    fishboneBranchs {
      createdAt
      createdBy
      description
      id
      items {
        completion
        createdAt
        createdBy
        description
        fishboneBranchId
        id
        name
        trend
        weight
      }
      name
      projectId
      weight
    }
    id
    indicators {
      createdAt
      createdBy
      description
      id
      name
      projectId
      scenarios
      trend
      weight
    }
    name
    scenarios {
      createdAt
      createdBy
      id
      name
      projectId
    }
    stakefactors {
      comments {
        commentedAt
        createdAt
        createdBy
        description
        id
        stakefactorId
      }
      createdAt
      createdBy
      icon
      id
      impact
      impactedConstraints {
        constraintId
        createdAt
        createdBy
        id
        stakefactorId
        strength
      }
      impactedIndicators {
        createdAt
        createdBy
        id
        indicatorId
        stakefactorId
        strength
      }
      links {
        createdAt
        createdBy
        id
        originId
        strength
        targetId
      }
      name
      projectId
      scenarios
      tags
      trend
      x
      y
    }
  }
}
    `;
export type DeleteProjectMutationFn = ApolloReactCommon.MutationFunction<DeleteProjectMutation, DeleteProjectMutationVariables>;

/**
 * __useDeleteProjectMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectMutation, { data, loading, error }] = useDeleteProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProjectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteProjectMutation, DeleteProjectMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteProjectMutation, DeleteProjectMutationVariables>(DeleteProjectDocument, baseOptions);
      }
export type DeleteProjectMutationHookResult = ReturnType<typeof useDeleteProjectMutation>;
export type DeleteProjectMutationResult = ApolloReactCommon.MutationResult<DeleteProjectMutation>;
export type DeleteProjectMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteProjectMutation, DeleteProjectMutationVariables>;
export const DeleteScenarioDocument = gql`
    mutation DeleteScenario($id: ID!) {
  deleteScenario(id: $id) {
    createdAt
    createdBy
    id
    name
    projectId
  }
}
    `;
export type DeleteScenarioMutationFn = ApolloReactCommon.MutationFunction<DeleteScenarioMutation, DeleteScenarioMutationVariables>;

/**
 * __useDeleteScenarioMutation__
 *
 * To run a mutation, you first call `useDeleteScenarioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteScenarioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteScenarioMutation, { data, loading, error }] = useDeleteScenarioMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteScenarioMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteScenarioMutation, DeleteScenarioMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteScenarioMutation, DeleteScenarioMutationVariables>(DeleteScenarioDocument, baseOptions);
      }
export type DeleteScenarioMutationHookResult = ReturnType<typeof useDeleteScenarioMutation>;
export type DeleteScenarioMutationResult = ApolloReactCommon.MutationResult<DeleteScenarioMutation>;
export type DeleteScenarioMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteScenarioMutation, DeleteScenarioMutationVariables>;
export const DeleteStakefactorDocument = gql`
    mutation DeleteStakefactor($id: ID!) {
  deleteStakefactor(id: $id) {
    comments {
      commentedAt
      createdAt
      createdBy
      description
      id
      stakefactorId
    }
    createdAt
    createdBy
    icon
    id
    impact
    impactedConstraints {
      constraintId
      createdAt
      createdBy
      id
      stakefactorId
      strength
    }
    impactedIndicators {
      createdAt
      createdBy
      id
      indicatorId
      stakefactorId
      strength
    }
    links {
      createdAt
      createdBy
      id
      originId
      strength
      targetId
    }
    name
    projectId
    scenarios
    tags
    trend
    x
    y
  }
}
    `;
export type DeleteStakefactorMutationFn = ApolloReactCommon.MutationFunction<DeleteStakefactorMutation, DeleteStakefactorMutationVariables>;

/**
 * __useDeleteStakefactorMutation__
 *
 * To run a mutation, you first call `useDeleteStakefactorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStakefactorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStakefactorMutation, { data, loading, error }] = useDeleteStakefactorMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteStakefactorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteStakefactorMutation, DeleteStakefactorMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteStakefactorMutation, DeleteStakefactorMutationVariables>(DeleteStakefactorDocument, baseOptions);
      }
export type DeleteStakefactorMutationHookResult = ReturnType<typeof useDeleteStakefactorMutation>;
export type DeleteStakefactorMutationResult = ApolloReactCommon.MutationResult<DeleteStakefactorMutation>;
export type DeleteStakefactorMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteStakefactorMutation, DeleteStakefactorMutationVariables>;
export const DeleteStakefactorCommentDocument = gql`
    mutation DeleteStakefactorComment($id: ID!) {
  deleteStakefactorComment(id: $id) {
    commentedAt
    createdAt
    createdBy
    description
    id
    stakefactorId
  }
}
    `;
export type DeleteStakefactorCommentMutationFn = ApolloReactCommon.MutationFunction<DeleteStakefactorCommentMutation, DeleteStakefactorCommentMutationVariables>;

/**
 * __useDeleteStakefactorCommentMutation__
 *
 * To run a mutation, you first call `useDeleteStakefactorCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStakefactorCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStakefactorCommentMutation, { data, loading, error }] = useDeleteStakefactorCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteStakefactorCommentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteStakefactorCommentMutation, DeleteStakefactorCommentMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteStakefactorCommentMutation, DeleteStakefactorCommentMutationVariables>(DeleteStakefactorCommentDocument, baseOptions);
      }
export type DeleteStakefactorCommentMutationHookResult = ReturnType<typeof useDeleteStakefactorCommentMutation>;
export type DeleteStakefactorCommentMutationResult = ApolloReactCommon.MutationResult<DeleteStakefactorCommentMutation>;
export type DeleteStakefactorCommentMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteStakefactorCommentMutation, DeleteStakefactorCommentMutationVariables>;
export const DeleteStakefactorConstraintImpactDocument = gql`
    mutation DeleteStakefactorConstraintImpact($id: ID!) {
  deleteStakefactorConstraintImpact(id: $id) {
    constraintId
    createdAt
    createdBy
    id
    stakefactorId
    strength
  }
}
    `;
export type DeleteStakefactorConstraintImpactMutationFn = ApolloReactCommon.MutationFunction<DeleteStakefactorConstraintImpactMutation, DeleteStakefactorConstraintImpactMutationVariables>;

/**
 * __useDeleteStakefactorConstraintImpactMutation__
 *
 * To run a mutation, you first call `useDeleteStakefactorConstraintImpactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStakefactorConstraintImpactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStakefactorConstraintImpactMutation, { data, loading, error }] = useDeleteStakefactorConstraintImpactMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteStakefactorConstraintImpactMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteStakefactorConstraintImpactMutation, DeleteStakefactorConstraintImpactMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteStakefactorConstraintImpactMutation, DeleteStakefactorConstraintImpactMutationVariables>(DeleteStakefactorConstraintImpactDocument, baseOptions);
      }
export type DeleteStakefactorConstraintImpactMutationHookResult = ReturnType<typeof useDeleteStakefactorConstraintImpactMutation>;
export type DeleteStakefactorConstraintImpactMutationResult = ApolloReactCommon.MutationResult<DeleteStakefactorConstraintImpactMutation>;
export type DeleteStakefactorConstraintImpactMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteStakefactorConstraintImpactMutation, DeleteStakefactorConstraintImpactMutationVariables>;
export const DeleteStakefactorIndicatorImpactDocument = gql`
    mutation DeleteStakefactorIndicatorImpact($id: ID!) {
  deleteStakefactorIndicatorImpact(id: $id) {
    createdAt
    createdBy
    id
    indicatorId
    stakefactorId
    strength
  }
}
    `;
export type DeleteStakefactorIndicatorImpactMutationFn = ApolloReactCommon.MutationFunction<DeleteStakefactorIndicatorImpactMutation, DeleteStakefactorIndicatorImpactMutationVariables>;

/**
 * __useDeleteStakefactorIndicatorImpactMutation__
 *
 * To run a mutation, you first call `useDeleteStakefactorIndicatorImpactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStakefactorIndicatorImpactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStakefactorIndicatorImpactMutation, { data, loading, error }] = useDeleteStakefactorIndicatorImpactMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteStakefactorIndicatorImpactMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteStakefactorIndicatorImpactMutation, DeleteStakefactorIndicatorImpactMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteStakefactorIndicatorImpactMutation, DeleteStakefactorIndicatorImpactMutationVariables>(DeleteStakefactorIndicatorImpactDocument, baseOptions);
      }
export type DeleteStakefactorIndicatorImpactMutationHookResult = ReturnType<typeof useDeleteStakefactorIndicatorImpactMutation>;
export type DeleteStakefactorIndicatorImpactMutationResult = ApolloReactCommon.MutationResult<DeleteStakefactorIndicatorImpactMutation>;
export type DeleteStakefactorIndicatorImpactMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteStakefactorIndicatorImpactMutation, DeleteStakefactorIndicatorImpactMutationVariables>;
export const DeleteStakefactorLinkDocument = gql`
    mutation DeleteStakefactorLink($id: ID!) {
  deleteStakefactorLink(id: $id) {
    createdAt
    createdBy
    id
    originId
    strength
    targetId
  }
}
    `;
export type DeleteStakefactorLinkMutationFn = ApolloReactCommon.MutationFunction<DeleteStakefactorLinkMutation, DeleteStakefactorLinkMutationVariables>;

/**
 * __useDeleteStakefactorLinkMutation__
 *
 * To run a mutation, you first call `useDeleteStakefactorLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStakefactorLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStakefactorLinkMutation, { data, loading, error }] = useDeleteStakefactorLinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteStakefactorLinkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteStakefactorLinkMutation, DeleteStakefactorLinkMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteStakefactorLinkMutation, DeleteStakefactorLinkMutationVariables>(DeleteStakefactorLinkDocument, baseOptions);
      }
export type DeleteStakefactorLinkMutationHookResult = ReturnType<typeof useDeleteStakefactorLinkMutation>;
export type DeleteStakefactorLinkMutationResult = ApolloReactCommon.MutationResult<DeleteStakefactorLinkMutation>;
export type DeleteStakefactorLinkMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteStakefactorLinkMutation, DeleteStakefactorLinkMutationVariables>;
export const MakePaymentDocument = gql`
    mutation MakePayment($price: String!, $referer: String!) {
  makePayment(price: $price, referer: $referer)
}
    `;
export type MakePaymentMutationFn = ApolloReactCommon.MutationFunction<MakePaymentMutation, MakePaymentMutationVariables>;

/**
 * __useMakePaymentMutation__
 *
 * To run a mutation, you first call `useMakePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makePaymentMutation, { data, loading, error }] = useMakePaymentMutation({
 *   variables: {
 *      price: // value for 'price'
 *      referer: // value for 'referer'
 *   },
 * });
 */
export function useMakePaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MakePaymentMutation, MakePaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<MakePaymentMutation, MakePaymentMutationVariables>(MakePaymentDocument, baseOptions);
      }
export type MakePaymentMutationHookResult = ReturnType<typeof useMakePaymentMutation>;
export type MakePaymentMutationResult = ApolloReactCommon.MutationResult<MakePaymentMutation>;
export type MakePaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<MakePaymentMutation, MakePaymentMutationVariables>;
export const UpdateConstraintDocument = gql`
    mutation UpdateConstraint($description: String, $id: ID!, $name: String, $trend: String, $weight: Int) {
  updateConstraint(description: $description, id: $id, name: $name, trend: $trend, weight: $weight) {
    createdAt
    createdBy
    description
    id
    name
    projectId
    scenarios
    trend
    weight
  }
}
    `;
export type UpdateConstraintMutationFn = ApolloReactCommon.MutationFunction<UpdateConstraintMutation, UpdateConstraintMutationVariables>;

/**
 * __useUpdateConstraintMutation__
 *
 * To run a mutation, you first call `useUpdateConstraintMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConstraintMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConstraintMutation, { data, loading, error }] = useUpdateConstraintMutation({
 *   variables: {
 *      description: // value for 'description'
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      trend: // value for 'trend'
 *      weight: // value for 'weight'
 *   },
 * });
 */
export function useUpdateConstraintMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateConstraintMutation, UpdateConstraintMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateConstraintMutation, UpdateConstraintMutationVariables>(UpdateConstraintDocument, baseOptions);
      }
export type UpdateConstraintMutationHookResult = ReturnType<typeof useUpdateConstraintMutation>;
export type UpdateConstraintMutationResult = ApolloReactCommon.MutationResult<UpdateConstraintMutation>;
export type UpdateConstraintMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateConstraintMutation, UpdateConstraintMutationVariables>;
export const UpdateConstraintScenariosDocument = gql`
    mutation UpdateConstraintScenarios($id: ID!, $scenarios: AWSJSON) {
  updateConstraintScenarios(id: $id, scenarios: $scenarios) {
    createdAt
    createdBy
    description
    id
    name
    projectId
    scenarios
    trend
    weight
  }
}
    `;
export type UpdateConstraintScenariosMutationFn = ApolloReactCommon.MutationFunction<UpdateConstraintScenariosMutation, UpdateConstraintScenariosMutationVariables>;

/**
 * __useUpdateConstraintScenariosMutation__
 *
 * To run a mutation, you first call `useUpdateConstraintScenariosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConstraintScenariosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConstraintScenariosMutation, { data, loading, error }] = useUpdateConstraintScenariosMutation({
 *   variables: {
 *      id: // value for 'id'
 *      scenarios: // value for 'scenarios'
 *   },
 * });
 */
export function useUpdateConstraintScenariosMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateConstraintScenariosMutation, UpdateConstraintScenariosMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateConstraintScenariosMutation, UpdateConstraintScenariosMutationVariables>(UpdateConstraintScenariosDocument, baseOptions);
      }
export type UpdateConstraintScenariosMutationHookResult = ReturnType<typeof useUpdateConstraintScenariosMutation>;
export type UpdateConstraintScenariosMutationResult = ApolloReactCommon.MutationResult<UpdateConstraintScenariosMutation>;
export type UpdateConstraintScenariosMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateConstraintScenariosMutation, UpdateConstraintScenariosMutationVariables>;
export const UpdateFishboneBranchDocument = gql`
    mutation UpdateFishboneBranch($description: String, $id: ID!, $name: String, $weight: Int) {
  updateFishboneBranch(description: $description, id: $id, name: $name, weight: $weight) {
    createdAt
    createdBy
    description
    id
    items {
      completion
      createdAt
      createdBy
      description
      fishboneBranchId
      id
      name
      trend
      weight
    }
    name
    projectId
    weight
  }
}
    `;
export type UpdateFishboneBranchMutationFn = ApolloReactCommon.MutationFunction<UpdateFishboneBranchMutation, UpdateFishboneBranchMutationVariables>;

/**
 * __useUpdateFishboneBranchMutation__
 *
 * To run a mutation, you first call `useUpdateFishboneBranchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFishboneBranchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFishboneBranchMutation, { data, loading, error }] = useUpdateFishboneBranchMutation({
 *   variables: {
 *      description: // value for 'description'
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      weight: // value for 'weight'
 *   },
 * });
 */
export function useUpdateFishboneBranchMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateFishboneBranchMutation, UpdateFishboneBranchMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateFishboneBranchMutation, UpdateFishboneBranchMutationVariables>(UpdateFishboneBranchDocument, baseOptions);
      }
export type UpdateFishboneBranchMutationHookResult = ReturnType<typeof useUpdateFishboneBranchMutation>;
export type UpdateFishboneBranchMutationResult = ApolloReactCommon.MutationResult<UpdateFishboneBranchMutation>;
export type UpdateFishboneBranchMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateFishboneBranchMutation, UpdateFishboneBranchMutationVariables>;
export const UpdateFishboneItemDocument = gql`
    mutation UpdateFishboneItem($completion: Int, $description: String, $id: ID!, $name: String, $trend: String, $weight: Int) {
  updateFishboneItem(completion: $completion, description: $description, id: $id, name: $name, trend: $trend, weight: $weight) {
    completion
    createdAt
    createdBy
    description
    fishboneBranchId
    id
    name
    trend
    weight
  }
}
    `;
export type UpdateFishboneItemMutationFn = ApolloReactCommon.MutationFunction<UpdateFishboneItemMutation, UpdateFishboneItemMutationVariables>;

/**
 * __useUpdateFishboneItemMutation__
 *
 * To run a mutation, you first call `useUpdateFishboneItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFishboneItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFishboneItemMutation, { data, loading, error }] = useUpdateFishboneItemMutation({
 *   variables: {
 *      completion: // value for 'completion'
 *      description: // value for 'description'
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      trend: // value for 'trend'
 *      weight: // value for 'weight'
 *   },
 * });
 */
export function useUpdateFishboneItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateFishboneItemMutation, UpdateFishboneItemMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateFishboneItemMutation, UpdateFishboneItemMutationVariables>(UpdateFishboneItemDocument, baseOptions);
      }
export type UpdateFishboneItemMutationHookResult = ReturnType<typeof useUpdateFishboneItemMutation>;
export type UpdateFishboneItemMutationResult = ApolloReactCommon.MutationResult<UpdateFishboneItemMutation>;
export type UpdateFishboneItemMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateFishboneItemMutation, UpdateFishboneItemMutationVariables>;
export const UpdateIndicatorDocument = gql`
    mutation UpdateIndicator($description: String, $id: ID!, $name: String, $trend: String, $weight: Int) {
  updateIndicator(description: $description, id: $id, name: $name, trend: $trend, weight: $weight) {
    createdAt
    createdBy
    description
    id
    name
    projectId
    scenarios
    trend
    weight
  }
}
    `;
export type UpdateIndicatorMutationFn = ApolloReactCommon.MutationFunction<UpdateIndicatorMutation, UpdateIndicatorMutationVariables>;

/**
 * __useUpdateIndicatorMutation__
 *
 * To run a mutation, you first call `useUpdateIndicatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIndicatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIndicatorMutation, { data, loading, error }] = useUpdateIndicatorMutation({
 *   variables: {
 *      description: // value for 'description'
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      trend: // value for 'trend'
 *      weight: // value for 'weight'
 *   },
 * });
 */
export function useUpdateIndicatorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateIndicatorMutation, UpdateIndicatorMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateIndicatorMutation, UpdateIndicatorMutationVariables>(UpdateIndicatorDocument, baseOptions);
      }
export type UpdateIndicatorMutationHookResult = ReturnType<typeof useUpdateIndicatorMutation>;
export type UpdateIndicatorMutationResult = ApolloReactCommon.MutationResult<UpdateIndicatorMutation>;
export type UpdateIndicatorMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateIndicatorMutation, UpdateIndicatorMutationVariables>;
export const UpdateIndicatorScenariosDocument = gql`
    mutation UpdateIndicatorScenarios($id: ID!, $scenarios: AWSJSON) {
  updateIndicatorScenarios(id: $id, scenarios: $scenarios) {
    createdAt
    createdBy
    description
    id
    name
    projectId
    scenarios
    trend
    weight
  }
}
    `;
export type UpdateIndicatorScenariosMutationFn = ApolloReactCommon.MutationFunction<UpdateIndicatorScenariosMutation, UpdateIndicatorScenariosMutationVariables>;

/**
 * __useUpdateIndicatorScenariosMutation__
 *
 * To run a mutation, you first call `useUpdateIndicatorScenariosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIndicatorScenariosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIndicatorScenariosMutation, { data, loading, error }] = useUpdateIndicatorScenariosMutation({
 *   variables: {
 *      id: // value for 'id'
 *      scenarios: // value for 'scenarios'
 *   },
 * });
 */
export function useUpdateIndicatorScenariosMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateIndicatorScenariosMutation, UpdateIndicatorScenariosMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateIndicatorScenariosMutation, UpdateIndicatorScenariosMutationVariables>(UpdateIndicatorScenariosDocument, baseOptions);
      }
export type UpdateIndicatorScenariosMutationHookResult = ReturnType<typeof useUpdateIndicatorScenariosMutation>;
export type UpdateIndicatorScenariosMutationResult = ApolloReactCommon.MutationResult<UpdateIndicatorScenariosMutation>;
export type UpdateIndicatorScenariosMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateIndicatorScenariosMutation, UpdateIndicatorScenariosMutationVariables>;
export const UpdateProjectDocument = gql`
    mutation UpdateProject($description: String, $id: ID!, $name: String) {
  updateProject(description: $description, id: $id, name: $name) {
    constraints {
      createdAt
      createdBy
      description
      id
      name
      projectId
      scenarios
      trend
      weight
    }
    createdAt
    createdBy
    description
    fishboneBranchs {
      createdAt
      createdBy
      description
      id
      items {
        completion
        createdAt
        createdBy
        description
        fishboneBranchId
        id
        name
        trend
        weight
      }
      name
      projectId
      weight
    }
    id
    indicators {
      createdAt
      createdBy
      description
      id
      name
      projectId
      scenarios
      trend
      weight
    }
    name
    scenarios {
      createdAt
      createdBy
      id
      name
      projectId
    }
    stakefactors {
      comments {
        commentedAt
        createdAt
        createdBy
        description
        id
        stakefactorId
      }
      createdAt
      createdBy
      icon
      id
      impact
      impactedConstraints {
        constraintId
        createdAt
        createdBy
        id
        stakefactorId
        strength
      }
      impactedIndicators {
        createdAt
        createdBy
        id
        indicatorId
        stakefactorId
        strength
      }
      links {
        createdAt
        createdBy
        id
        originId
        strength
        targetId
      }
      name
      projectId
      scenarios
      tags
      trend
      x
      y
    }
  }
}
    `;
export type UpdateProjectMutationFn = ApolloReactCommon.MutationFunction<UpdateProjectMutation, UpdateProjectMutationVariables>;

/**
 * __useUpdateProjectMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMutation, { data, loading, error }] = useUpdateProjectMutation({
 *   variables: {
 *      description: // value for 'description'
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateProjectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateProjectMutation, UpdateProjectMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateProjectMutation, UpdateProjectMutationVariables>(UpdateProjectDocument, baseOptions);
      }
export type UpdateProjectMutationHookResult = ReturnType<typeof useUpdateProjectMutation>;
export type UpdateProjectMutationResult = ApolloReactCommon.MutationResult<UpdateProjectMutation>;
export type UpdateProjectMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateProjectMutation, UpdateProjectMutationVariables>;
export const UpdateScenarioDocument = gql`
    mutation UpdateScenario($id: ID!, $name: String) {
  updateScenario(id: $id, name: $name) {
    createdAt
    createdBy
    id
    name
    projectId
  }
}
    `;
export type UpdateScenarioMutationFn = ApolloReactCommon.MutationFunction<UpdateScenarioMutation, UpdateScenarioMutationVariables>;

/**
 * __useUpdateScenarioMutation__
 *
 * To run a mutation, you first call `useUpdateScenarioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScenarioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScenarioMutation, { data, loading, error }] = useUpdateScenarioMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateScenarioMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateScenarioMutation, UpdateScenarioMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateScenarioMutation, UpdateScenarioMutationVariables>(UpdateScenarioDocument, baseOptions);
      }
export type UpdateScenarioMutationHookResult = ReturnType<typeof useUpdateScenarioMutation>;
export type UpdateScenarioMutationResult = ApolloReactCommon.MutationResult<UpdateScenarioMutation>;
export type UpdateScenarioMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateScenarioMutation, UpdateScenarioMutationVariables>;
export const UpdateStakefactorDocument = gql`
    mutation UpdateStakefactor($description: String, $icon: String, $id: ID!, $impact: String, $name: String, $trend: String) {
  updateStakefactor(description: $description, icon: $icon, id: $id, impact: $impact, name: $name, trend: $trend) {
    comments {
      commentedAt
      createdAt
      createdBy
      description
      id
      stakefactorId
    }
    createdAt
    createdBy
    icon
    id
    impact
    impactedConstraints {
      constraintId
      createdAt
      createdBy
      id
      stakefactorId
      strength
    }
    impactedIndicators {
      createdAt
      createdBy
      id
      indicatorId
      stakefactorId
      strength
    }
    links {
      createdAt
      createdBy
      id
      originId
      strength
      targetId
    }
    name
    projectId
    scenarios
    tags
    trend
    x
    y
  }
}
    `;
export type UpdateStakefactorMutationFn = ApolloReactCommon.MutationFunction<UpdateStakefactorMutation, UpdateStakefactorMutationVariables>;

/**
 * __useUpdateStakefactorMutation__
 *
 * To run a mutation, you first call `useUpdateStakefactorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStakefactorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStakefactorMutation, { data, loading, error }] = useUpdateStakefactorMutation({
 *   variables: {
 *      description: // value for 'description'
 *      icon: // value for 'icon'
 *      id: // value for 'id'
 *      impact: // value for 'impact'
 *      name: // value for 'name'
 *      trend: // value for 'trend'
 *   },
 * });
 */
export function useUpdateStakefactorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateStakefactorMutation, UpdateStakefactorMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateStakefactorMutation, UpdateStakefactorMutationVariables>(UpdateStakefactorDocument, baseOptions);
      }
export type UpdateStakefactorMutationHookResult = ReturnType<typeof useUpdateStakefactorMutation>;
export type UpdateStakefactorMutationResult = ApolloReactCommon.MutationResult<UpdateStakefactorMutation>;
export type UpdateStakefactorMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateStakefactorMutation, UpdateStakefactorMutationVariables>;
export const UpdateStakefactorCommentDocument = gql`
    mutation UpdateStakefactorComment($commentedAt: String!, $description: String!, $id: ID!) {
  updateStakefactorComment(commentedAt: $commentedAt, description: $description, id: $id) {
    commentedAt
    createdAt
    createdBy
    description
    id
    stakefactorId
  }
}
    `;
export type UpdateStakefactorCommentMutationFn = ApolloReactCommon.MutationFunction<UpdateStakefactorCommentMutation, UpdateStakefactorCommentMutationVariables>;

/**
 * __useUpdateStakefactorCommentMutation__
 *
 * To run a mutation, you first call `useUpdateStakefactorCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStakefactorCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStakefactorCommentMutation, { data, loading, error }] = useUpdateStakefactorCommentMutation({
 *   variables: {
 *      commentedAt: // value for 'commentedAt'
 *      description: // value for 'description'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateStakefactorCommentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateStakefactorCommentMutation, UpdateStakefactorCommentMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateStakefactorCommentMutation, UpdateStakefactorCommentMutationVariables>(UpdateStakefactorCommentDocument, baseOptions);
      }
export type UpdateStakefactorCommentMutationHookResult = ReturnType<typeof useUpdateStakefactorCommentMutation>;
export type UpdateStakefactorCommentMutationResult = ApolloReactCommon.MutationResult<UpdateStakefactorCommentMutation>;
export type UpdateStakefactorCommentMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateStakefactorCommentMutation, UpdateStakefactorCommentMutationVariables>;
export const UpdateStakefactorCommentDescriptionDocument = gql`
    mutation UpdateStakefactorCommentDescription($description: String!, $id: ID!) {
  updateStakefactorCommentDescription(description: $description, id: $id) {
    commentedAt
    createdAt
    createdBy
    description
    id
    stakefactorId
  }
}
    `;
export type UpdateStakefactorCommentDescriptionMutationFn = ApolloReactCommon.MutationFunction<UpdateStakefactorCommentDescriptionMutation, UpdateStakefactorCommentDescriptionMutationVariables>;

/**
 * __useUpdateStakefactorCommentDescriptionMutation__
 *
 * To run a mutation, you first call `useUpdateStakefactorCommentDescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStakefactorCommentDescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStakefactorCommentDescriptionMutation, { data, loading, error }] = useUpdateStakefactorCommentDescriptionMutation({
 *   variables: {
 *      description: // value for 'description'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateStakefactorCommentDescriptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateStakefactorCommentDescriptionMutation, UpdateStakefactorCommentDescriptionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateStakefactorCommentDescriptionMutation, UpdateStakefactorCommentDescriptionMutationVariables>(UpdateStakefactorCommentDescriptionDocument, baseOptions);
      }
export type UpdateStakefactorCommentDescriptionMutationHookResult = ReturnType<typeof useUpdateStakefactorCommentDescriptionMutation>;
export type UpdateStakefactorCommentDescriptionMutationResult = ApolloReactCommon.MutationResult<UpdateStakefactorCommentDescriptionMutation>;
export type UpdateStakefactorCommentDescriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateStakefactorCommentDescriptionMutation, UpdateStakefactorCommentDescriptionMutationVariables>;
export const UpdateStakefactorCommentedAtDescriptionDocument = gql`
    mutation UpdateStakefactorCommentedAtDescription($commentedAt: String!, $id: ID!) {
  updateStakefactorCommentedAtDescription(commentedAt: $commentedAt, id: $id) {
    commentedAt
    createdAt
    createdBy
    description
    id
    stakefactorId
  }
}
    `;
export type UpdateStakefactorCommentedAtDescriptionMutationFn = ApolloReactCommon.MutationFunction<UpdateStakefactorCommentedAtDescriptionMutation, UpdateStakefactorCommentedAtDescriptionMutationVariables>;

/**
 * __useUpdateStakefactorCommentedAtDescriptionMutation__
 *
 * To run a mutation, you first call `useUpdateStakefactorCommentedAtDescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStakefactorCommentedAtDescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStakefactorCommentedAtDescriptionMutation, { data, loading, error }] = useUpdateStakefactorCommentedAtDescriptionMutation({
 *   variables: {
 *      commentedAt: // value for 'commentedAt'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateStakefactorCommentedAtDescriptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateStakefactorCommentedAtDescriptionMutation, UpdateStakefactorCommentedAtDescriptionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateStakefactorCommentedAtDescriptionMutation, UpdateStakefactorCommentedAtDescriptionMutationVariables>(UpdateStakefactorCommentedAtDescriptionDocument, baseOptions);
      }
export type UpdateStakefactorCommentedAtDescriptionMutationHookResult = ReturnType<typeof useUpdateStakefactorCommentedAtDescriptionMutation>;
export type UpdateStakefactorCommentedAtDescriptionMutationResult = ApolloReactCommon.MutationResult<UpdateStakefactorCommentedAtDescriptionMutation>;
export type UpdateStakefactorCommentedAtDescriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateStakefactorCommentedAtDescriptionMutation, UpdateStakefactorCommentedAtDescriptionMutationVariables>;
export const UpdateStakefactorConstraintImpactStrengthDocument = gql`
    mutation UpdateStakefactorConstraintImpactStrength($id: ID!, $strength: Int!) {
  updateStakefactorConstraintImpactStrength(id: $id, strength: $strength) {
    constraintId
    createdAt
    createdBy
    id
    stakefactorId
    strength
  }
}
    `;
export type UpdateStakefactorConstraintImpactStrengthMutationFn = ApolloReactCommon.MutationFunction<UpdateStakefactorConstraintImpactStrengthMutation, UpdateStakefactorConstraintImpactStrengthMutationVariables>;

/**
 * __useUpdateStakefactorConstraintImpactStrengthMutation__
 *
 * To run a mutation, you first call `useUpdateStakefactorConstraintImpactStrengthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStakefactorConstraintImpactStrengthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStakefactorConstraintImpactStrengthMutation, { data, loading, error }] = useUpdateStakefactorConstraintImpactStrengthMutation({
 *   variables: {
 *      id: // value for 'id'
 *      strength: // value for 'strength'
 *   },
 * });
 */
export function useUpdateStakefactorConstraintImpactStrengthMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateStakefactorConstraintImpactStrengthMutation, UpdateStakefactorConstraintImpactStrengthMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateStakefactorConstraintImpactStrengthMutation, UpdateStakefactorConstraintImpactStrengthMutationVariables>(UpdateStakefactorConstraintImpactStrengthDocument, baseOptions);
      }
export type UpdateStakefactorConstraintImpactStrengthMutationHookResult = ReturnType<typeof useUpdateStakefactorConstraintImpactStrengthMutation>;
export type UpdateStakefactorConstraintImpactStrengthMutationResult = ApolloReactCommon.MutationResult<UpdateStakefactorConstraintImpactStrengthMutation>;
export type UpdateStakefactorConstraintImpactStrengthMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateStakefactorConstraintImpactStrengthMutation, UpdateStakefactorConstraintImpactStrengthMutationVariables>;
export const UpdateStakefactorIconDocument = gql`
    mutation UpdateStakefactorIcon($icon: String, $id: ID!) {
  updateStakefactorIcon(icon: $icon, id: $id) {
    comments {
      commentedAt
      createdAt
      createdBy
      description
      id
      stakefactorId
    }
    createdAt
    createdBy
    icon
    id
    impact
    impactedConstraints {
      constraintId
      createdAt
      createdBy
      id
      stakefactorId
      strength
    }
    impactedIndicators {
      createdAt
      createdBy
      id
      indicatorId
      stakefactorId
      strength
    }
    links {
      createdAt
      createdBy
      id
      originId
      strength
      targetId
    }
    name
    projectId
    scenarios
    tags
    trend
    x
    y
  }
}
    `;
export type UpdateStakefactorIconMutationFn = ApolloReactCommon.MutationFunction<UpdateStakefactorIconMutation, UpdateStakefactorIconMutationVariables>;

/**
 * __useUpdateStakefactorIconMutation__
 *
 * To run a mutation, you first call `useUpdateStakefactorIconMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStakefactorIconMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStakefactorIconMutation, { data, loading, error }] = useUpdateStakefactorIconMutation({
 *   variables: {
 *      icon: // value for 'icon'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateStakefactorIconMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateStakefactorIconMutation, UpdateStakefactorIconMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateStakefactorIconMutation, UpdateStakefactorIconMutationVariables>(UpdateStakefactorIconDocument, baseOptions);
      }
export type UpdateStakefactorIconMutationHookResult = ReturnType<typeof useUpdateStakefactorIconMutation>;
export type UpdateStakefactorIconMutationResult = ApolloReactCommon.MutationResult<UpdateStakefactorIconMutation>;
export type UpdateStakefactorIconMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateStakefactorIconMutation, UpdateStakefactorIconMutationVariables>;
export const UpdateStakefactorImpactDocument = gql`
    mutation UpdateStakefactorImpact($id: ID!, $impact: String) {
  updateStakefactorImpact(id: $id, impact: $impact) {
    comments {
      commentedAt
      createdAt
      createdBy
      description
      id
      stakefactorId
    }
    createdAt
    createdBy
    icon
    id
    impact
    impactedConstraints {
      constraintId
      createdAt
      createdBy
      id
      stakefactorId
      strength
    }
    impactedIndicators {
      createdAt
      createdBy
      id
      indicatorId
      stakefactorId
      strength
    }
    links {
      createdAt
      createdBy
      id
      originId
      strength
      targetId
    }
    name
    projectId
    scenarios
    tags
    trend
    x
    y
  }
}
    `;
export type UpdateStakefactorImpactMutationFn = ApolloReactCommon.MutationFunction<UpdateStakefactorImpactMutation, UpdateStakefactorImpactMutationVariables>;

/**
 * __useUpdateStakefactorImpactMutation__
 *
 * To run a mutation, you first call `useUpdateStakefactorImpactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStakefactorImpactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStakefactorImpactMutation, { data, loading, error }] = useUpdateStakefactorImpactMutation({
 *   variables: {
 *      id: // value for 'id'
 *      impact: // value for 'impact'
 *   },
 * });
 */
export function useUpdateStakefactorImpactMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateStakefactorImpactMutation, UpdateStakefactorImpactMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateStakefactorImpactMutation, UpdateStakefactorImpactMutationVariables>(UpdateStakefactorImpactDocument, baseOptions);
      }
export type UpdateStakefactorImpactMutationHookResult = ReturnType<typeof useUpdateStakefactorImpactMutation>;
export type UpdateStakefactorImpactMutationResult = ApolloReactCommon.MutationResult<UpdateStakefactorImpactMutation>;
export type UpdateStakefactorImpactMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateStakefactorImpactMutation, UpdateStakefactorImpactMutationVariables>;
export const UpdateStakefactorIndicatorImpactStrengthDocument = gql`
    mutation UpdateStakefactorIndicatorImpactStrength($id: ID!, $strength: Int!) {
  updateStakefactorIndicatorImpactStrength(id: $id, strength: $strength) {
    createdAt
    createdBy
    id
    indicatorId
    stakefactorId
    strength
  }
}
    `;
export type UpdateStakefactorIndicatorImpactStrengthMutationFn = ApolloReactCommon.MutationFunction<UpdateStakefactorIndicatorImpactStrengthMutation, UpdateStakefactorIndicatorImpactStrengthMutationVariables>;

/**
 * __useUpdateStakefactorIndicatorImpactStrengthMutation__
 *
 * To run a mutation, you first call `useUpdateStakefactorIndicatorImpactStrengthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStakefactorIndicatorImpactStrengthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStakefactorIndicatorImpactStrengthMutation, { data, loading, error }] = useUpdateStakefactorIndicatorImpactStrengthMutation({
 *   variables: {
 *      id: // value for 'id'
 *      strength: // value for 'strength'
 *   },
 * });
 */
export function useUpdateStakefactorIndicatorImpactStrengthMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateStakefactorIndicatorImpactStrengthMutation, UpdateStakefactorIndicatorImpactStrengthMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateStakefactorIndicatorImpactStrengthMutation, UpdateStakefactorIndicatorImpactStrengthMutationVariables>(UpdateStakefactorIndicatorImpactStrengthDocument, baseOptions);
      }
export type UpdateStakefactorIndicatorImpactStrengthMutationHookResult = ReturnType<typeof useUpdateStakefactorIndicatorImpactStrengthMutation>;
export type UpdateStakefactorIndicatorImpactStrengthMutationResult = ApolloReactCommon.MutationResult<UpdateStakefactorIndicatorImpactStrengthMutation>;
export type UpdateStakefactorIndicatorImpactStrengthMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateStakefactorIndicatorImpactStrengthMutation, UpdateStakefactorIndicatorImpactStrengthMutationVariables>;
export const UpdateStakefactorLinkStrengthDocument = gql`
    mutation UpdateStakefactorLinkStrength($id: ID!, $strength: Int!) {
  updateStakefactorLinkStrength(id: $id, strength: $strength) {
    createdAt
    createdBy
    id
    originId
    strength
    targetId
  }
}
    `;
export type UpdateStakefactorLinkStrengthMutationFn = ApolloReactCommon.MutationFunction<UpdateStakefactorLinkStrengthMutation, UpdateStakefactorLinkStrengthMutationVariables>;

/**
 * __useUpdateStakefactorLinkStrengthMutation__
 *
 * To run a mutation, you first call `useUpdateStakefactorLinkStrengthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStakefactorLinkStrengthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStakefactorLinkStrengthMutation, { data, loading, error }] = useUpdateStakefactorLinkStrengthMutation({
 *   variables: {
 *      id: // value for 'id'
 *      strength: // value for 'strength'
 *   },
 * });
 */
export function useUpdateStakefactorLinkStrengthMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateStakefactorLinkStrengthMutation, UpdateStakefactorLinkStrengthMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateStakefactorLinkStrengthMutation, UpdateStakefactorLinkStrengthMutationVariables>(UpdateStakefactorLinkStrengthDocument, baseOptions);
      }
export type UpdateStakefactorLinkStrengthMutationHookResult = ReturnType<typeof useUpdateStakefactorLinkStrengthMutation>;
export type UpdateStakefactorLinkStrengthMutationResult = ApolloReactCommon.MutationResult<UpdateStakefactorLinkStrengthMutation>;
export type UpdateStakefactorLinkStrengthMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateStakefactorLinkStrengthMutation, UpdateStakefactorLinkStrengthMutationVariables>;
export const UpdateStakefactorPositionDocument = gql`
    mutation UpdateStakefactorPosition($id: ID!, $x: Float, $y: Float) {
  updateStakefactorPosition(id: $id, x: $x, y: $y) {
    comments {
      commentedAt
      createdAt
      createdBy
      description
      id
      stakefactorId
    }
    createdAt
    createdBy
    icon
    id
    impact
    impactedConstraints {
      constraintId
      createdAt
      createdBy
      id
      stakefactorId
      strength
    }
    impactedIndicators {
      createdAt
      createdBy
      id
      indicatorId
      stakefactorId
      strength
    }
    links {
      createdAt
      createdBy
      id
      originId
      strength
      targetId
    }
    name
    projectId
    scenarios
    tags
    trend
    x
    y
  }
}
    `;
export type UpdateStakefactorPositionMutationFn = ApolloReactCommon.MutationFunction<UpdateStakefactorPositionMutation, UpdateStakefactorPositionMutationVariables>;

/**
 * __useUpdateStakefactorPositionMutation__
 *
 * To run a mutation, you first call `useUpdateStakefactorPositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStakefactorPositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStakefactorPositionMutation, { data, loading, error }] = useUpdateStakefactorPositionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      x: // value for 'x'
 *      y: // value for 'y'
 *   },
 * });
 */
export function useUpdateStakefactorPositionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateStakefactorPositionMutation, UpdateStakefactorPositionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateStakefactorPositionMutation, UpdateStakefactorPositionMutationVariables>(UpdateStakefactorPositionDocument, baseOptions);
      }
export type UpdateStakefactorPositionMutationHookResult = ReturnType<typeof useUpdateStakefactorPositionMutation>;
export type UpdateStakefactorPositionMutationResult = ApolloReactCommon.MutationResult<UpdateStakefactorPositionMutation>;
export type UpdateStakefactorPositionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateStakefactorPositionMutation, UpdateStakefactorPositionMutationVariables>;
export const UpdateStakefactorScenariosDocument = gql`
    mutation UpdateStakefactorScenarios($id: ID!, $scenarios: AWSJSON) {
  updateStakefactorScenarios(id: $id, scenarios: $scenarios) {
    comments {
      commentedAt
      createdAt
      createdBy
      description
      id
      stakefactorId
    }
    createdAt
    createdBy
    icon
    id
    impact
    impactedConstraints {
      constraintId
      createdAt
      createdBy
      id
      stakefactorId
      strength
    }
    impactedIndicators {
      createdAt
      createdBy
      id
      indicatorId
      stakefactorId
      strength
    }
    links {
      createdAt
      createdBy
      id
      originId
      strength
      targetId
    }
    name
    projectId
    scenarios
    tags
    trend
    x
    y
  }
}
    `;
export type UpdateStakefactorScenariosMutationFn = ApolloReactCommon.MutationFunction<UpdateStakefactorScenariosMutation, UpdateStakefactorScenariosMutationVariables>;

/**
 * __useUpdateStakefactorScenariosMutation__
 *
 * To run a mutation, you first call `useUpdateStakefactorScenariosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStakefactorScenariosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStakefactorScenariosMutation, { data, loading, error }] = useUpdateStakefactorScenariosMutation({
 *   variables: {
 *      id: // value for 'id'
 *      scenarios: // value for 'scenarios'
 *   },
 * });
 */
export function useUpdateStakefactorScenariosMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateStakefactorScenariosMutation, UpdateStakefactorScenariosMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateStakefactorScenariosMutation, UpdateStakefactorScenariosMutationVariables>(UpdateStakefactorScenariosDocument, baseOptions);
      }
export type UpdateStakefactorScenariosMutationHookResult = ReturnType<typeof useUpdateStakefactorScenariosMutation>;
export type UpdateStakefactorScenariosMutationResult = ApolloReactCommon.MutationResult<UpdateStakefactorScenariosMutation>;
export type UpdateStakefactorScenariosMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateStakefactorScenariosMutation, UpdateStakefactorScenariosMutationVariables>;
export const UpdateStakefactorTagsDocument = gql`
    mutation UpdateStakefactorTags($id: ID!, $tags: [String]!) {
  updateStakefactorTags(id: $id, tags: $tags) {
    comments {
      commentedAt
      createdAt
      createdBy
      description
      id
      stakefactorId
    }
    createdAt
    createdBy
    icon
    id
    impact
    impactedConstraints {
      constraintId
      createdAt
      createdBy
      id
      stakefactorId
      strength
    }
    impactedIndicators {
      createdAt
      createdBy
      id
      indicatorId
      stakefactorId
      strength
    }
    links {
      createdAt
      createdBy
      id
      originId
      strength
      targetId
    }
    name
    projectId
    scenarios
    tags
    trend
    x
    y
  }
}
    `;
export type UpdateStakefactorTagsMutationFn = ApolloReactCommon.MutationFunction<UpdateStakefactorTagsMutation, UpdateStakefactorTagsMutationVariables>;

/**
 * __useUpdateStakefactorTagsMutation__
 *
 * To run a mutation, you first call `useUpdateStakefactorTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStakefactorTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStakefactorTagsMutation, { data, loading, error }] = useUpdateStakefactorTagsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useUpdateStakefactorTagsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateStakefactorTagsMutation, UpdateStakefactorTagsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateStakefactorTagsMutation, UpdateStakefactorTagsMutationVariables>(UpdateStakefactorTagsDocument, baseOptions);
      }
export type UpdateStakefactorTagsMutationHookResult = ReturnType<typeof useUpdateStakefactorTagsMutation>;
export type UpdateStakefactorTagsMutationResult = ApolloReactCommon.MutationResult<UpdateStakefactorTagsMutation>;
export type UpdateStakefactorTagsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateStakefactorTagsMutation, UpdateStakefactorTagsMutationVariables>;
export const UpdateStakefactorTrendDocument = gql`
    mutation UpdateStakefactorTrend($id: ID!, $trend: String) {
  updateStakefactorTrend(id: $id, trend: $trend) {
    comments {
      commentedAt
      createdAt
      createdBy
      description
      id
      stakefactorId
    }
    createdAt
    createdBy
    icon
    id
    impact
    impactedConstraints {
      constraintId
      createdAt
      createdBy
      id
      stakefactorId
      strength
    }
    impactedIndicators {
      createdAt
      createdBy
      id
      indicatorId
      stakefactorId
      strength
    }
    links {
      createdAt
      createdBy
      id
      originId
      strength
      targetId
    }
    name
    projectId
    scenarios
    tags
    trend
    x
    y
  }
}
    `;
export type UpdateStakefactorTrendMutationFn = ApolloReactCommon.MutationFunction<UpdateStakefactorTrendMutation, UpdateStakefactorTrendMutationVariables>;

/**
 * __useUpdateStakefactorTrendMutation__
 *
 * To run a mutation, you first call `useUpdateStakefactorTrendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStakefactorTrendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStakefactorTrendMutation, { data, loading, error }] = useUpdateStakefactorTrendMutation({
 *   variables: {
 *      id: // value for 'id'
 *      trend: // value for 'trend'
 *   },
 * });
 */
export function useUpdateStakefactorTrendMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateStakefactorTrendMutation, UpdateStakefactorTrendMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateStakefactorTrendMutation, UpdateStakefactorTrendMutationVariables>(UpdateStakefactorTrendDocument, baseOptions);
      }
export type UpdateStakefactorTrendMutationHookResult = ReturnType<typeof useUpdateStakefactorTrendMutation>;
export type UpdateStakefactorTrendMutationResult = ApolloReactCommon.MutationResult<UpdateStakefactorTrendMutation>;
export type UpdateStakefactorTrendMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateStakefactorTrendMutation, UpdateStakefactorTrendMutationVariables>;
export const OnCreateProjectDocument = gql`
    subscription OnCreateProject {
  onCreateProject {
    constraints {
      createdAt
      createdBy
      description
      id
      name
      projectId
      scenarios
      trend
      weight
    }
    createdAt
    createdBy
    description
    fishboneBranchs {
      createdAt
      createdBy
      description
      id
      items {
        completion
        createdAt
        createdBy
        description
        fishboneBranchId
        id
        name
        trend
        weight
      }
      name
      projectId
      weight
    }
    id
    indicators {
      createdAt
      createdBy
      description
      id
      name
      projectId
      scenarios
      trend
      weight
    }
    name
    scenarios {
      createdAt
      createdBy
      id
      name
      projectId
    }
    stakefactors {
      comments {
        commentedAt
        createdAt
        createdBy
        description
        id
        stakefactorId
      }
      createdAt
      createdBy
      icon
      id
      impact
      impactedConstraints {
        constraintId
        createdAt
        createdBy
        id
        stakefactorId
        strength
      }
      impactedIndicators {
        createdAt
        createdBy
        id
        indicatorId
        stakefactorId
        strength
      }
      links {
        createdAt
        createdBy
        id
        originId
        strength
        targetId
      }
      name
      projectId
      scenarios
      tags
      trend
      x
      y
    }
  }
}
    `;

/**
 * __useOnCreateProjectSubscription__
 *
 * To run a query within a React component, call `useOnCreateProjectSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnCreateProjectSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnCreateProjectSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnCreateProjectSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<OnCreateProjectSubscription, OnCreateProjectSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<OnCreateProjectSubscription, OnCreateProjectSubscriptionVariables>(OnCreateProjectDocument, baseOptions);
      }
export type OnCreateProjectSubscriptionHookResult = ReturnType<typeof useOnCreateProjectSubscription>;
export type OnCreateProjectSubscriptionResult = ApolloReactCommon.SubscriptionResult<OnCreateProjectSubscription>;
export const OnDeleteProjectDocument = gql`
    subscription OnDeleteProject {
  onDeleteProject {
    constraints {
      createdAt
      createdBy
      description
      id
      name
      projectId
      scenarios
      trend
      weight
    }
    createdAt
    createdBy
    description
    fishboneBranchs {
      createdAt
      createdBy
      description
      id
      items {
        completion
        createdAt
        createdBy
        description
        fishboneBranchId
        id
        name
        trend
        weight
      }
      name
      projectId
      weight
    }
    id
    indicators {
      createdAt
      createdBy
      description
      id
      name
      projectId
      scenarios
      trend
      weight
    }
    name
    scenarios {
      createdAt
      createdBy
      id
      name
      projectId
    }
    stakefactors {
      comments {
        commentedAt
        createdAt
        createdBy
        description
        id
        stakefactorId
      }
      createdAt
      createdBy
      icon
      id
      impact
      impactedConstraints {
        constraintId
        createdAt
        createdBy
        id
        stakefactorId
        strength
      }
      impactedIndicators {
        createdAt
        createdBy
        id
        indicatorId
        stakefactorId
        strength
      }
      links {
        createdAt
        createdBy
        id
        originId
        strength
        targetId
      }
      name
      projectId
      scenarios
      tags
      trend
      x
      y
    }
  }
}
    `;

/**
 * __useOnDeleteProjectSubscription__
 *
 * To run a query within a React component, call `useOnDeleteProjectSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnDeleteProjectSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnDeleteProjectSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnDeleteProjectSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<OnDeleteProjectSubscription, OnDeleteProjectSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<OnDeleteProjectSubscription, OnDeleteProjectSubscriptionVariables>(OnDeleteProjectDocument, baseOptions);
      }
export type OnDeleteProjectSubscriptionHookResult = ReturnType<typeof useOnDeleteProjectSubscription>;
export type OnDeleteProjectSubscriptionResult = ApolloReactCommon.SubscriptionResult<OnDeleteProjectSubscription>;
export const OnUpdateProjectDocument = gql`
    subscription OnUpdateProject($id: ID) {
  onUpdateProject(id: $id) {
    constraints {
      createdAt
      createdBy
      description
      id
      name
      projectId
      scenarios
      trend
      weight
    }
    createdAt
    createdBy
    description
    fishboneBranchs {
      createdAt
      createdBy
      description
      id
      items {
        completion
        createdAt
        createdBy
        description
        fishboneBranchId
        id
        name
        trend
        weight
      }
      name
      projectId
      weight
    }
    id
    indicators {
      createdAt
      createdBy
      description
      id
      name
      projectId
      scenarios
      trend
      weight
    }
    name
    scenarios {
      createdAt
      createdBy
      id
      name
      projectId
    }
    stakefactors {
      comments {
        commentedAt
        createdAt
        createdBy
        description
        id
        stakefactorId
      }
      createdAt
      createdBy
      icon
      id
      impact
      impactedConstraints {
        constraintId
        createdAt
        createdBy
        id
        stakefactorId
        strength
      }
      impactedIndicators {
        createdAt
        createdBy
        id
        indicatorId
        stakefactorId
        strength
      }
      links {
        createdAt
        createdBy
        id
        originId
        strength
        targetId
      }
      name
      projectId
      scenarios
      tags
      trend
      x
      y
    }
  }
}
    `;

/**
 * __useOnUpdateProjectSubscription__
 *
 * To run a query within a React component, call `useOnUpdateProjectSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnUpdateProjectSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUpdateProjectSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOnUpdateProjectSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<OnUpdateProjectSubscription, OnUpdateProjectSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<OnUpdateProjectSubscription, OnUpdateProjectSubscriptionVariables>(OnUpdateProjectDocument, baseOptions);
      }
export type OnUpdateProjectSubscriptionHookResult = ReturnType<typeof useOnUpdateProjectSubscription>;
export type OnUpdateProjectSubscriptionResult = ApolloReactCommon.SubscriptionResult<OnUpdateProjectSubscription>;