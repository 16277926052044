import React from 'react'
import { useAuth0 } from './react-auth0-spa'
import useAsyncEffect from 'use-async-effect'
import { ApolloClient, InMemoryCache } from '@apollo/client'
import { ApolloProvider } from '@apollo/react-hooks'
import { createAppSyncHybridLink } from './utils/graphql/appSyncHybridLink'

import AppSyncConfig from './app-sync-config.json'
import Loader from './components/Loading/Loader'

// @ts-ignore
const config: any = AppSyncConfig[process.env.REACT_APP_MODE][process.env.REACT_APP_ENV]

interface IProps {
	children: any
	// any other props that come into the component
}

export const AuthorizedApolloProvider: any = ({ children }: IProps) => {
	const [token, setToken] = React.useState<string>('')
	const [isLoggingIn, setIsLoggingIn] = React.useState<boolean>(false)
	const [client, setClient] = React.useState<any>(null)
	const { loading, getIdTokenClaims } = useAuth0()

	const getJwtToken = async () => {
		const claims = await getIdTokenClaims()
		return claims ? claims.__raw : null
	}

	useAsyncEffect(async () => {
		if (loading) return <Loader></Loader>
		console.log('useAsyncEffect')

		const getClient = async () => {
			return new ApolloClient({
				link: await createAppSyncHybridLink({
					appSyncApiUrl: config.ApiUrl,
					getJwtToken,
				}),
				cache: new InMemoryCache(),
			})
		}

		const finalClient = await getClient()
		setClient(finalClient)
	}, [loading])

	return client ? <ApolloProvider client={client as any}>{children}</ApolloProvider> : null
}
