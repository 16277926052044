import React from 'react'

import { withTranslation } from 'react-i18next'

import { filter, sumBy } from 'lodash'

import { vulnerability } from 'utils/ranking'
import { getItemAlteredByScenario } from 'utils/rules'
import { Tooltip } from 'antd'

import('./VulnerabilityScale.sass')

class VulnerabilityScale extends React.Component {
	render() {
		const { value, t } = this.props
		const innerHeight = this.props.height - 30

		let originalStakefactors = this.props.stakefactors.map((s) => s.o || s)

		let vulnerabilities = (this.props.scenarios || []).map((sc) => {
			return {
				id: sc.id,
				name: sc.name,
				vulnerability: vulnerability(originalStakefactors.map((s) => getItemAlteredByScenario(s, sc))),
			}
		})

		vulnerabilities.push({
			id: null,
			vulnerability: vulnerability(originalStakefactors),
		})

		return (
			<div className="VulnerabilityScale" style={{ height: innerHeight }}>
				<div className="top noselection">{t('pages.navigator.context_best')}</div>
				<div className="middle">
					<hr />
				</div>
				<div className="bottom noselection">{t('pages.navigator.context_worse')}</div>

				{this.props.initialValue !== null && this.props.initialValue !== undefined ? (
					<div
						id="initial_bar_marker"
						style={{ top: (this.props.initialValue / 100) * innerHeight + 2 + 'px' }}
					></div>
				) : null}

				{vulnerabilities.map((v) => (
					<Tooltip placement="left" key={v.id} title={<div>{v.name || 'Baseline'}</div>}>
						<div
							id={v.id == this.props.scenario?.id ? 'bar_marker' : 'initial_bar_marker'}
							style={{ top: (v.vulnerability / 100) * innerHeight + 2 + 'px' }}
						></div>
					</Tooltip>
				))}
			</div>
		)
	}
}

export default withTranslation()(VulnerabilityScale)
