import React, { useCallback, useEffect } from 'react'

import { Switch, Route } from 'react-router-dom'
import { useHistory, useParams, useRouteMatch } from 'react-router'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/pro-light-svg-icons'
import { Button, Input, Select } from 'antd'

import { Indicators } from './Indicators/Indicators'
import Loader from 'components/Loading/Loader'
import ProjectModal from 'components/Modal/ProjectModal'
import ProjectNavigation from 'components/navigation/ProjectNavigation'

import SubHeaderPortal from '../../layout/header/SubHeaderPortal'

import { useGetProjectQuery, useUpdateProjectMutation, useUpdateStakefactorMutation } from '../../graphql/generated'

import Navigator from './Navigator/Navigator'
import { Constraints } from './Constraints/Constraints'
import { Scenarios } from './Scenarios/Scenarios'
import { Fishbone } from './Fishbone/Fishbone'

import './Project.sass'

let lastData: any = null

export function Project(props: any) {
	useEffect(() => {
		console.log('Project useEffect')
		if (props.setExpanded) props.setExpanded(true)
	}, [])

	let history = useHistory()
	const params: any = useParams()
	const [editedItem, setEditedItem] = React.useState<any | null>(null)
	const [searchTerm, setSearchTerm] = React.useState<any | null>('')
	const [currentScenarioIndex, setCurrentScenarioIndex] = React.useState<number | null>(null)

	const [updateData] = useUpdateProjectMutation()

	const { data, refetch, loading } = useGetProjectQuery({
		variables: { id: params?.id },
	})
	const _refetch = useCallback(() => {
		setTimeout(() => refetch(), 0)
	}, [refetch])

	// Update project
	const handleUpdateProject = async (id: any, values: any) => {
		const result = await updateData({
			variables: {
				id: id,
				...values,
			},
		})

		await _refetch()
	}

	const onGoNext = (url: string) => {
		history.push(url)
	}

	if (data) {
		lastData = data
	}

	const projectData = data || lastData
	const portalContent = (
		<>
			<div>
				<div className="Title">
					{projectData?.getProject?.name
						? projectData?.getProject?.name
						: projectData?.getProject
						? 'No name'
						: ''}
				</div>
				<div className="SubTitle">
					<small>
						{projectData?.getProject?.description
							? projectData?.getProject?.description
							: projectData?.getProject
							? null
							: null}
					</small>
				</div>
			</div>

			<FontAwesomeIcon
				style={{ marginLeft: '0.5rem', fontSize: '1.2rem', cursor: 'pointer' }}
				icon={faEdit}
				onClick={() => setEditedItem(projectData?.getProject)}
			/>
		</>
	)

	const currentScenario =
		currentScenarioIndex !== null ? projectData?.getProject?.scenarios[currentScenarioIndex] : null

	const handleGoToPreviousScenario = () => {
		if (currentScenarioIndex === 0 && projectData?.getProject?.scenarios?.length > 0) {
			setCurrentScenarioIndex(null)
		} else if (currentScenarioIndex === null) {
			setCurrentScenarioIndex(projectData?.getProject?.scenarios?.length - 1)
		} else if (currentScenarioIndex > 0) {
			setCurrentScenarioIndex(currentScenarioIndex - 1)
		}
	}

	const handleGoToNextScenario = () => {
		if (currentScenarioIndex === null && projectData?.getProject?.scenarios?.length > 0) {
			setCurrentScenarioIndex(0)
		} else if (currentScenarioIndex === projectData?.getProject?.scenarios?.length - 1) {
			setCurrentScenarioIndex(null)
		} else if (currentScenarioIndex < projectData?.getProject?.scenarios?.length - 1) {
			setCurrentScenarioIndex(currentScenarioIndex + 1)
		}
	}

	const handleGoToScenario = (index) => {
		setCurrentScenarioIndex(index)
	}

	const searchBar = (
		<Input
			onChange={(e) => {
				setSearchTerm(e.target.value)
			}}
			value={searchTerm}
			style={{ marginLeft: 'auto', marginRight: '1em' }}
			placeholder="Search"
			className="SearchInput"
		></Input>
	)

	return (
		<div className={['Project', !props.expanded ? 'visible' : ''].join(' ')}>
			<SubHeaderPortal>
				<ProjectNavigation
					{...props}
					onExpand={() => {
						props.setExpanded(true)
					}}
					onShrink={() => {
						props.setExpanded(false)
					}}
					expanded={props.expanded}
					setExpanded={props.setExpanded}
					project={projectData?.getProject}
					scenario={currentScenario}
					scenarioIndex={currentScenarioIndex}
					scenarioCount={projectData?.getProject?.scenarios?.length}
					onGoToPreviousScenario={handleGoToPreviousScenario}
					onGoToNextScenario={handleGoToNextScenario}
					onGoToScenario={handleGoToScenario}
					onUpdate={handleUpdateProject}
					{...projectData?.getProject}
					loading={loading}
				></ProjectNavigation>
			</SubHeaderPortal>

			{loading ? (
				<Loader></Loader>
			) : (
				<>
					<Switch>
						<Route key="navigator" exact path="/projects/:id">
							<Navigator
								{...projectData?.getProject}
								refetch={_refetch}
								{...props}
								searchTerm={searchTerm}
								setSearchTerm={setSearchTerm}
								expanded={props.expanded}
								scenario={currentScenario}
								scenarios={projectData?.getProject?.scenarios || []}
								portalContent={
									<>
										{portalContent}
										{searchBar}
									</>
								}
							/>
						</Route>

						<Route
							key="project"
							path="/projects/:id/indicators"
							render={() => (
								<Indicators
									onGoNext={onGoNext}
									refetch={_refetch}
									{...props}
									onEditProject={() => setEditedItem(projectData?.getProject)}
									expanded={props.expanded}
									scenario={currentScenario}
									scenarios={projectData?.getProject?.scenarios || []}
									{...projectData?.getProject}
									portalContent={portalContent}
								></Indicators>
							)}
						></Route>

						<Route
							key="project"
							path="/projects/:id/constraints"
							render={() => (
								<Constraints
									onGoNext={onGoNext}
									refetch={_refetch}
									{...props}
									onEditProject={() => setEditedItem(projectData?.getProject)}
									expanded={props.expanded}
									scenario={currentScenario}
									scenarios={projectData?.getProject?.scenarios || []}
									{...projectData?.getProject}
									portalContent={portalContent}
								></Constraints>
							)}
						></Route>

						<Route
							key="project"
							path="/projects/:id/scenarios"
							render={() => (
								<Scenarios
									onGoNext={onGoNext}
									refetch={_refetch}
									{...props}
									scenario={currentScenario}
									scenarioIndex={currentScenarioIndex}
									onGoToPreviousScenario={handleGoToPreviousScenario}
									onGoToNextScenario={handleGoToNextScenario}
									onEditProject={() => setEditedItem(projectData?.getProject)}
									expanded={props.expanded}
									{...projectData?.getProject}
									portalContent={portalContent}
								></Scenarios>
							)}
						></Route>

						<Route
							key="project"
							path="/projects/:id/fishbone"
							render={() => (
								<Fishbone
									onGoNext={onGoNext}
									refetch={_refetch}
									{...props}
									onUpdateProject={handleUpdateProject}
									scenario={currentScenario}
									scenarioIndex={currentScenarioIndex}
									onGoToPreviousScenario={handleGoToPreviousScenario}
									onGoToNextScenario={handleGoToNextScenario}
									onEditProject={() => setEditedItem(projectData?.getProject)}
									expanded={props.expanded}
									{...projectData?.getProject}
									portalContent={portalContent}
								></Fishbone>
							)}
						></Route>
					</Switch>
					{props.children}
				</>
			)}
			{editedItem && (
				<ProjectModal
					onHide={() => {
						setEditedItem(null)
					}}
					onSave={(values: Object) => {
						handleUpdateProject(editedItem.id, values)
						setEditedItem(null)
					}}
					id={editedItem?.id}
					values={{
						name: editedItem?.name,
						description: editedItem?.description,
					}}
				></ProjectModal>
			)}
		</div>
	)
}
