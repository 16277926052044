import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import { Modal } from 'antd'

export class ConfirmModal extends Component {
	render() {
		return (
			<Modal
				onOk={() => {
					this.props.onHide()
					this.props.onConfirm(this.state)
				}}
				visible={true}
				title={this.props.t ? this.props.t(this.props.title) : this.props.title}
				onCancel={this.props.onHide}
			>
				{this.props.children || this.props.t(this.props.message)}
			</Modal>
		)
	}
}

ConfirmModal.propTypes = {
	title: PropTypes.string.isRequired,
	message: PropTypes.string,
	onHide: PropTypes.func.isRequired,
	onConfirm: PropTypes.func.isRequired,
	t: PropTypes.func.isRequired,
}

export default withTranslation()(ConfirmModal)
