import React from 'react'
import { useTranslation } from 'react-i18next'

import { sortBy } from 'lodash'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faTimesCircle } from '@fortawesome/pro-light-svg-icons'

import './ScenarioResult.sass'
import { Slider } from 'components/controls/Slider'

import {
	getScoreColor,
	scenarioScore,
	getStakeholderScoreForScenarioDetailsColor,
	getStakeholderScoreForScenarioDetailsRanking,
	vulnerability,
} from '../../../utils/ranking'
import HorizontalVulnerabilityScale from 'components/Radar/HorizontalVulnerabilityScale'
import { getItemAlteredByScenario, vulnerabilityConstraints, vulnerabilityIndicators } from 'utils/rules'
import { Tooltip } from 'antd'

export const ScenarioResult = (props: any) => {
	const [index, setIndex] = React.useState<any | null>(0)
	const { t } = useTranslation()

	if (!props.scenarios) {
		return (
			<div className="ScenarioResult">
				<h2>No scenario</h2>
			</div>
		)
	}

	let score = scenarioScore(props, props.scenario)
	let stakeholderScore =
		100 - vulnerability(props.stakefactors.map((s: any) => getItemAlteredByScenario(s, props.scenario)))
	let constraintsScore = vulnerabilityConstraints(
		props.constraints.map((c: any) => getItemAlteredByScenario(c, props.scenario))
	)
	let indicatorsScore = vulnerabilityIndicators(
		props.indicators.map((i: any) => getItemAlteredByScenario(i, props.scenario))
	)

	let vulnerabilities = (props.scenarios || []).map((sc) => {
		return {
			id: sc.id,
			name: sc.name,
			vulnerability: scenarioScore(props, sc),
		}
	})

	vulnerabilities.push({
		id: null,
		vulnerability: scenarioScore(props, null),
	})

	const Nav = (props: any) => (
		<div
			style={{ textAlign: 'center' }}
			className="Navigation noselection d-flex justify-content-center w-100 mb-4"
		>
			<a onClick={props.onGoToPreviousScenario} className="action" style={{ marginRight: '1rem' }}>
				<FontAwesomeIcon icon={faChevronLeft} />
			</a>
			<div className={props.fullWidth ? 'w-100' : ''}>{props.children}</div>
			<a onClick={props.onGoToNextScenario} className="action" style={{ marginLeft: '1rem' }}>
				<FontAwesomeIcon icon={faChevronRight} />
			</a>
		</div>
	)

	return (
		<div className="ScenarioResult">
			<Nav
				fullWidth
				count={props.scenarios.length}
				options={props.scenarios}
				onGoToPreviousScenario={props.onGoToPreviousScenario}
				onGoToNextScenario={props.onGoToNextScenario}
			>
				<h2 title={props.scenarios ? props.scenario?.id : ''}>{props.scenario?.name || 'Baseline'} </h2>
			</Nav>

			<HorizontalVulnerabilityScale
				t={t}
				value={score ? score.toFixed(2) : null}
				vulnerabilities={vulnerabilities}
			></HorizontalVulnerabilityScale>

			<div className="ElementsRank d-flex mt-4">
				<div className="RankCriteriaColumn">
					<div className="title">
						{t('models.indicator.name_plural')}{' '}
						{indicatorsScore && indicatorsScore !== undefined && indicatorsScore !== null && (
							<Tooltip title="Score">
								<div className="ml-auto">
									<small>({indicatorsScore.toFixed(0)})</small>
								</div>
							</Tooltip>
						)}
					</div>

					{sortBy(props.indicators, (s: any) => -getItemAlteredByScenario(s, props.scenario).trend).map(
						(s: any) => (
							<div className="RankCriteriaWrapper">
								<div
									className="RankCriteria"
									style={{
										borderLeftWidth: '8px',
										borderLeftStyle: 'solid',
										borderLeftColor: getScoreColor(
											getItemAlteredByScenario(s, props.scenario).trend
										),
									}}
								>
									{s.name}
								</div>
							</div>
						)
					)}
				</div>
				<div className="RankCriteriaColumn">
					<div className="title">
						{t('models.constraint.name_plural')}{' '}
						{constraintsScore && constraintsScore !== undefined && constraintsScore !== null && (
							<Tooltip title="Score">
								<div className="ml-auto">
									<small>({constraintsScore.toFixed(0)})</small>
								</div>
							</Tooltip>
						)}
					</div>
					{sortBy(props.constraints, (s: any) => -getItemAlteredByScenario(s, props.scenario).trend).map(
						(s: any) => (
							<div className="RankCriteriaWrapper">
								<div
									className="RankCriteria"
									style={{
										borderLeftWidth: '8px',
										borderLeftStyle: 'solid',
										borderLeftColor: getScoreColor(
											getItemAlteredByScenario(s, props.scenario).trend
										),
									}}
								>
									{s.name}
								</div>
							</div>
						)
					)}
				</div>
				<div className="RankCriteriaColumn">
					<div className="title">
						{t('models.item.name_plural')}{' '}
						{stakeholderScore && stakeholderScore !== undefined && stakeholderScore !== null && (
							<Tooltip title="Score">
								<div className="ml-auto">
									<small>({stakeholderScore.toFixed(0)})</small>
								</div>
							</Tooltip>
						)}
					</div>
					{sortBy(
						props.stakefactors,
						(s: any) =>
							-getStakeholderScoreForScenarioDetailsRanking(getItemAlteredByScenario(s, props.scenario))
					).map((s: any) => (
						<div className="RankCriteriaWrapper">
							<div
								className="RankCriteria"
								style={{
									borderLeftWidth: '8px',
									borderLeftStyle: 'solid',
									borderLeftColor: getScoreColor(
										getStakeholderScoreForScenarioDetailsColor(
											getItemAlteredByScenario(s, props.scenario)
										)
									),
								}}
							>
								{s.name}{' '}
							</div>
						</div>
					))}
				</div>
			</div>

			<Nav
				fullWidth
				count={props.scenarios.length}
				options={props.scenarios}
				onGoToPreviousScenario={props.onGoToPreviousScenario}
				onGoToNextScenario={props.onGoToNextScenario}
			>
				<div className="d-flex h-100 justify-content-center align-items-center">
					{props.scenarioIndex + 1} / {props.scenarios.length + 1}
				</div>
			</Nav>
		</div>
	)
}
