import React, { useCallback } from 'react'

import { useHistory } from 'react-router'
import { useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import gql from 'graphql-tag'

import { sortBy, findIndex, pick, filter } from 'lodash'

import prompt from 'antd-prompt'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons'

import AddButton from 'components/action/AddButton'
import IndicatorEditionModal from 'components/Modal/IndicatorEditionModal'
import EditableListItem from 'components/List/EditableListItem'
import NavigationButton from 'components/action/NavigationButton'
import TrendSelector from 'components/controls/TrendSelector'
import IndicatorVulnerabilityScale from './IndicatorVulnerabilityScale'
import HeaderPortal from 'layout/header/HeaderPortal'
import { ConfirmModal } from 'components/Modal/ConfirmModal'

// Help
import TutorialPopover from 'components/Help/TutorialPopover'
import { getNextStep, getInitialStep } from 'help/indicators'

import {
	useCreateIndicatorMutation,
	useDeleteIndicatorMutation,
	useUpdateIndicatorMutation,
	useUpdateIndicatorScenariosMutation,
} from '../../../graphql/generated'

import './Indicators.sass'
import { MiniSlider } from 'components/controls/MiniSlider'
import { getItemAlteredByScenario, vulnerabilityIndicators } from 'utils/rules'
import HorizontalVulnerabilityScale from 'components/Radar/HorizontalVulnerabilityScale'

const indicatorsLimit = 20

export const Indicators = (props: any) => {
	const [editedItem, setEditedItem] = React.useState<any | null>(null)
	let history = useHistory()
	const params: any = useParams()

	const [createIndicator] = useCreateIndicatorMutation()
	const [updateIndicator] = useUpdateIndicatorMutation()
	const [updateIndicatorScenarios] = useUpdateIndicatorScenariosMutation()
	const [deleteIndicator] = useDeleteIndicatorMutation()

	const { t } = useTranslation()

	// Help
	const [visibleHelpStep, setVisibleHelpStep] = React.useState<any | null>(null)
	const closeHelp = () => setVisibleHelpStep(null)
	const initialStep = getInitialStep(props.indicators?.length)
	const nextStep = getNextStep(visibleHelpStep, props.indicators?.length)
	const onNext = () => setVisibleHelpStep(nextStep, props.indicators?.length)
	const hasNext = !!nextStep

	const otherScenarios = filter(props.scenarios || [], (sc) => sc.id !== props.scenario?.id).concat([{ id: null }])
	let alteredIndicators = props.indicators.map((c) => getItemAlteredByScenario(c, props.scenario))
	let score = vulnerabilityIndicators(alteredIndicators)

	let vulnerabilities = (props.scenarios || []).map((sc) => {
		return {
			id: sc.id,
			name: sc.name,
			vulnerability: vulnerabilityIndicators(props.indicators.map((c) => getItemAlteredByScenario(c, sc))),
		}
	})

	vulnerabilities.push({
		id: null,
		vulnerability: vulnerabilityIndicators(props.indicators),
	})

	const optimisticlyUpdateScenario = (id, type, newScenarios) => {
		let method = {
			//Stakefactor: updateStakefactorScenarios,
			Indicator: updateIndicatorScenarios,
		}

		method[type]({
			variables: { id: id, scenarios: JSON.stringify(newScenarios) },
			update(cache) {
				cache.modify({
					id: `${type}:${id}`,
					fields: {
						scenarios() {
							return JSON.stringify(newScenarios)
						},
					},
				})
			},

			optimisticResponse: {
				[`update${type}Scenarios`]: {
					__typename: type,
					id,
					scenarios: JSON.stringify(newScenarios),
				},
			},
		})
	}

	// Create
	const handleCreateIndicator = async () => {
		props.pushModal(
			<IndicatorEditionModal
				onHide={() => {
					props.popModal()
				}}
				onSave={async (values: any) => {
					let variables = {
						projectId: params.id,
						name: values.name,
						weight: values.weight || 1,
						trend: '50',
						description: values.description || '',
					}

					let result = await createIndicator({
						variables,
						update(cache, { data: { createIndicator } }) {
							cache.modify({
								id: `Project:${params.id}`,
								fields: {
									indicators(indicators = []) {
										const newIndicator = cache.writeFragment({
											id: 'Indicator:' + createIndicator.id,
											data: createIndicator,
											fragment: gql`
												fragment IndicatorFragment on Indicator {
													name
													weight
													projectId
													description
												}
											`,
										})

										return [...indicators, newIndicator]
									},
								},
							})
						},
						optimisticResponse: {
							createIndicator: {
								id: 'temp-id',
								...variables,
							},
						},
					})

					props.popModal()
				}}
				id={false}
				values={{
					name: '',
					weight: 1,
					description: '',
				}}
			></IndicatorEditionModal>
		)
	}

	// Update
	const handleUpdateIndicator = async (id: string, values: Object) => {
		let index = findIndex(props.indicators, (s: any) => s.id === id)
		console.log('index', index)
		let newIndicator = {
			...pick(props.indicators[index], ['weight', 'description', 'name', 'trend']),
			...pick(values, ['weight', 'description', 'name', 'trend']),
		}

		console.log('handleUpdateIndicator', newIndicator)

		if (props.scenario) {
			let newScenarios = {
				...(props.indicators[index].scenarios ? JSON.parse(props.indicators[index].scenarios) : {}),
			}
			if (!newScenarios[props.scenario.id]) newScenarios[props.scenario.id] = {}
			newScenarios[props.scenario.id].trend = values.trend

			optimisticlyUpdateScenario(props.indicators[index].id, 'Indicator', newScenarios)
		} else {
			const result = await updateIndicator({
				variables: {
					id: id,
					...newIndicator,
				},
				update(cache, { data: { updateIndicator } }) {
					cache.modify({
						id: `Indicator:${id}`,
						fields: {
							name() {
								return updateIndicator.name || newIndicator.name
							},
							description() {
								return updateIndicator.description || newIndicator.description
							},
							weight() {
								return updateIndicator.weight || newIndicator.weight
							},
							trend() {
								return updateIndicator.trend || newIndicator.trend
							},
						},
					})
				},
				optimisticResponse: {
					updateIndicator: {
						__typename: 'Indicator',
						id,
						...newIndicator,
					},
				},
			})
		}
	}

	const askDeleteConfirmation = async (id?: string) => {
		props.pushModal(
			<ConfirmModal
				title={t('models.indicator.messages.delete_confirmation')}
				t={t}
				onConfirm={() => handleDelete(id)}
				message={t('models.indicator.messages.delete_confirmation_explanation')}
			></ConfirmModal>
		)
	}

	// Delete
	const handleDelete = async (id: string | undefined) => {
		console.log('deleteIndicator', id)

		if (id == null) {
			return
		}

		await deleteIndicator({
			variables: { id: id },
			update(cache, { data: { deleteIndicator } }) {
				cache.modify({
					id: `Project:${params.id}`,
					fields: {
						indicators(existingIndicatorsRef, { readField }) {
							return existingIndicatorsRef.filter((ref: string) => id !== readField('id', ref))
						},
					},
				})
			},

			optimisticResponse: {
				deleteIndicator: {
					__typename: 'Indicator',
					id: id,
				},
			},
		})
	}

	return (
		<div className="Indicators pb-3">
			<HeaderPortal>
				{props.portalContent}{' '}
				<FontAwesomeIcon
					style={{ marginLeft: 'auto', marginRight: '0.5rem', cursor: 'pointer' }}
					icon={faInfoCircle}
					onClick={() => setVisibleHelpStep(initialStep)}
				/>
			</HeaderPortal>

			<h2>{t('pages.indicators.title')}</h2>

			<h3 className="mb-4">{t('pages.indicators.subtitle')}</h3>

			<TutorialPopover
				onClose={closeHelp}
				placement={'top'}
				style={{ maxWidth: '400px' }}
				visible={visibleHelpStep == 2}
				body={
					'This gauge is a weighted consolidation of your indicators assessments, to a variable extent the result of your work on stakeholders'
				}
				onNext={onNext}
				hasNext={hasNext}
			>
				<HorizontalVulnerabilityScale
					value={score}
					vulnerabilities={vulnerabilities}
				></HorizontalVulnerabilityScale>
			</TutorialPopover>

			<div className="mb-3 mt-4 w-100" style={{ maxWidth: '800px' }}>
				{sortBy(props.indicators || [], 'name').map((o: any) => {
					let item = getItemAlteredByScenario(o, props.scenario)
					return (
						<EditableListItem
							key={item.id}
							disabled={!!props.scenario}
							onEdit={() => {
								setEditedItem(o)
							}}
							onDelete={askDeleteConfirmation}
							onCycleWeight={(values: any) => {
								let newWeight = values.weight ? values.weight + 1 : 1
								if (newWeight > 3) newWeight = 0
								handleUpdateIndicator(item.id, { ...values, weight: newWeight })
							}}
							weight={item.weight}
						>
							<>
								{item.name} {item.description ? ' - ' + item.description : ''}
								<MiniSlider
									className="ml-auto mr-2"
									key={item.id + '-' + props.scenario?.id}
									value={item.trend}
									style={{ width: '200px', flexShrink: 0 }}
									onChange={(val) => handleUpdateIndicator(item.id, { trend: val })}
									additionalMarkers={otherScenarios.map((sc) => {
										return {
											id: sc.id,
											name: sc.name || 'Baseline',
											value: getItemAlteredByScenario(o, sc).trend,
										}
									})}
								></MiniSlider>
							</>
						</EditableListItem>
					)
				})}
			</div>

			{editedItem && (
				<IndicatorEditionModal
					onHide={() => {
						setEditedItem(null)
					}}
					onDelete={() => {
						askDeleteConfirmation(editedItem.id)
						setEditedItem(null)
					}}
					onSave={(values: Object) => {
						handleUpdateIndicator(editedItem.id, values)
						setEditedItem(null)
					}}
					id={editedItem?.id}
					values={{
						name: editedItem?.name,
						weight: editedItem?.weight || 1,
						description: editedItem?.description || '',
					}}
				></IndicatorEditionModal>
			)}

			<div className="w-100 d-flex justify-content-center align-items-center">
				<div className="w-100 d-flex justify-content-center align-items-center" style={{ width: '33%' }}></div>
				<div className="w-100 d-flex justify-content-center align-items-center" style={{ width: '33%' }}>
					{props.indicators?.length < indicatorsLimit && (
						<AddButton
							popover={
								<TutorialPopover
									onClose={closeHelp}
									placement={'top'}
									style={{ maxWidth: '400px' }}
									visible={visibleHelpStep == 1}
									body={
										<>
											Add indicators. <br />
											This is about measuring success. For example a goal of “being number 1 on
											the market” can have several measures: Ability to reach the target sales in
											the year, ability to reach the market share in the year, maybe a measure of
											brand awareness etc. Don’t forget that one measure of success usually
											carries side-effects. For example, a measure of volume needs to be
											counter-balanced by a measure of quality. Beyond 10 indicators, they may
											lose their significance
										</>
									}
									onNext={onNext}
									hasNext={hasNext}
								></TutorialPopover>
							}
							onClick={handleCreateIndicator}
						></AddButton>
					)}
				</div>
				<div className="w-100 d-flex justify-content-center align-items-center" style={{ width: '33%' }}>
					{' '}
					{props.indicators && props.indicators.length > 0 ? (
						<TutorialPopover
							onClose={closeHelp}
							placement={'top'}
							style={{ maxWidth: '400px' }}
							visible={visibleHelpStep == 3}
							body={
								<>
									Click here once you are done with the indicators to get into the Gerositus
									stakeholders navigator map
								</>
							}
							onNext={onNext}
							hasNext={hasNext}
						>
							<NavigationButton onClick={() => history.push('/projects/' + params?.id + '/constraints')}>
								Next step
							</NavigationButton>
						</TutorialPopover>
					) : (
						<NavigationButton onClick={() => history.push('/projects/' + params?.id)}>
							Skip
						</NavigationButton>
					)}
				</div>
			</div>
		</div>
	)
}
