import React, { useCallback } from 'react'

import { useHistory } from 'react-router'
import { useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import gql from 'graphql-tag'

import { sortBy, findIndex, pick, filter } from 'lodash'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons'

import AddButton from 'components/action/AddButton'
import ConstraintEditionModal from 'components/Modal/ConstraintEditionModal'
import EditableListItem from 'components/List/EditableListItem'
import NavigationButton from 'components/action/NavigationButton'

import HeaderPortal from 'layout/header/HeaderPortal'
import { ConfirmModal } from 'components/Modal/ConfirmModal'

// Help
import TutorialPopover from 'components/Help/TutorialPopover'
import { getNextStep, getInitialStep } from 'help/constraints'

import {
	useCreateConstraintMutation,
	useDeleteConstraintMutation,
	useUpdateConstraintMutation,
	useUpdateConstraintScenariosMutation,
} from '../../../graphql/generated'

import { MiniSlider } from 'components/controls/MiniSlider'
import { getItemAlteredByScenario, vulnerabilityConstraints } from 'utils/rules'
import HorizontalVulnerabilityScale from 'components/Radar/HorizontalVulnerabilityScale'

import './Constraints.sass'

const constraintsLimit = 60

export const Constraints = (props: any) => {
	const [editedItem, setEditedItem] = React.useState<any | null>(null)
	let history = useHistory()
	const params: any = useParams()
	const [createConstraint] = useCreateConstraintMutation()
	const [deleteConstraint] = useDeleteConstraintMutation()
	const [updateConstraint] = useUpdateConstraintMutation()
	const [updateConstraintScenarios] = useUpdateConstraintScenariosMutation()
	const { t } = useTranslation()

	// Help
	const [visibleHelpStep, setVisibleHelpStep] = React.useState<any | null>(null)
	const closeHelp = () => setVisibleHelpStep(null)
	const initialStep = getInitialStep(props.constraints?.length)
	const nextStep = getNextStep(visibleHelpStep, props.constraints?.length)
	const onNext = () => setVisibleHelpStep(nextStep, props.constraints?.length)
	const hasNext = !!nextStep

	console.log('current step', visibleHelpStep, 'next step', nextStep)

	const otherScenarios = filter(props.scenarios || [], (sc) => sc.id !== props.scenario?.id).concat([{ id: null }])
	let alteredConstraints = props.constraints.map((c) => getItemAlteredByScenario(c, props.scenario))
	let score = vulnerabilityConstraints(alteredConstraints)

	let vulnerabilities = (props.scenarios || []).map((sc) => {
		return {
			id: sc.id,
			name: sc.name,
			vulnerability: vulnerabilityConstraints(props.constraints.map((c) => getItemAlteredByScenario(c, sc))),
		}
	})

	vulnerabilities.push({
		id: null,
		vulnerability: vulnerabilityConstraints(props.constraints),
	})

	// Create
	const handleCreateConstraint = async () => {
		props.pushModal(
			<ConstraintEditionModal
				onHide={() => {
					props.popModal()
				}}
				onSave={async (values: any) => {
					let variables = {
						projectId: params.id,
						name: values.name,
						weight: values.weight || 1,
						trend: '50',
						description: values.description || '',
					}

					let result = await createConstraint({
						variables,
						update(cache, { data: { createConstraint } }) {
							cache.modify({
								id: `Project:${params.id}`,
								fields: {
									constraints(constraints = []) {
										const newConstraint = cache.writeFragment({
											id: 'Constraint:' + createConstraint.id,
											data: createConstraint,
											fragment: gql`
												fragment ConstraintFragment on Constraint {
													name
													weight
													projectId
													description
												}
											`,
										})

										return [...constraints, newConstraint]
									},
								},
							})
						},
						optimisticResponse: {
							createConstraint: {
								id: 'temp-id',
								...variables,
							},
						},
					})

					props.popModal()
				}}
				id={false}
				values={{
					name: '',
					weight: 1,
					description: '',
				}}
			></ConstraintEditionModal>
		)
	}

	const optimisticlyUpdateScenario = (id, type, newScenarios) => {
		let method = {
			//Stakefactor: updateStakefactorScenarios,
			Constraint: updateConstraintScenarios,
		}

		method[type]({
			variables: { id: id, scenarios: JSON.stringify(newScenarios) },
			update(cache) {
				cache.modify({
					id: `${type}:${id}`,
					fields: {
						scenarios() {
							return JSON.stringify(newScenarios)
						},
					},
				})
			},

			optimisticResponse: {
				[`update${type}Scenarios`]: {
					__typename: type,
					id,
					scenarios: JSON.stringify(newScenarios),
				},
			},
		})
	}

	// Update
	const handleUpdateConstraint = async (id: string, values: Object) => {
		let index = findIndex(props.constraints, (s: any) => s.id === id)
		console.log('index', index)
		let newConstraint = {
			...pick(props.constraints[index], ['weight', 'description', 'name', 'trend']),
			...pick(values, ['weight', 'description', 'name', 'trend']),
		}

		console.log('handleUpdateConstraint', newConstraint)

		if (props.scenario) {
			let newScenarios = {
				...(props.constraints[index].scenarios ? JSON.parse(props.constraints[index].scenarios) : {}),
			}
			if (!newScenarios[props.scenario.id]) newScenarios[props.scenario.id] = {}
			newScenarios[props.scenario.id].trend = values.trend

			optimisticlyUpdateScenario(props.constraints[index].id, 'Constraint', newScenarios)
		} else {
			const result = await updateConstraint({
				variables: {
					id: id,
					...newConstraint,
				},
				update(cache, { data: { updateConstraint } }) {
					cache.modify({
						id: `Constraint:${id}`,
						fields: {
							name() {
								return updateConstraint.name || newConstraint.name
							},
							description() {
								return updateConstraint.description || newConstraint.description
							},
							weight() {
								return updateConstraint.weight || newConstraint.weight
							},
							trend() {
								return updateConstraint.trend || newConstraint.trend
							},
						},
					})
				},
				optimisticResponse: {
					updateConstraint: {
						__typename: 'Constraint',
						id,
						...newConstraint,
					},
				},
			})
		}
	}

	const askDeleteConfirmation = async (id?: string) => {
		props.pushModal(
			<ConfirmModal
				title={t('models.constraint.messages.delete_confirmation')}
				t={t}
				onConfirm={() => handleDelete(id)}
				message={t('models.constraint.messages.delete_confirmation_explanation')}
			></ConfirmModal>
		)
	}

	// Delete
	const handleDelete = async (id: string | undefined) => {
		console.log('deleteConstraint', id)

		if (id == null) {
			return
		}

		await deleteConstraint({
			variables: { id: id },
			update(cache, { data: { deleteConstraint } }) {
				cache.modify({
					id: `Project:${params.id}`,
					fields: {
						constraints(existingConstraintsRef, { readField }) {
							return existingConstraintsRef.filter((ref: string) => id !== readField('id', ref))
						},
					},
				})
			},

			optimisticResponse: {
				deleteConstraint: {
					__typename: 'Constraint',
					id: id,
				},
			},
		})
	}

	return (
		<div className="Constraints pb-3">
			<HeaderPortal>
				{props.portalContent}{' '}
				<FontAwesomeIcon
					style={{ marginLeft: 'auto', marginRight: '0.5rem', cursor: 'pointer' }}
					icon={faInfoCircle}
					onClick={() => setVisibleHelpStep(initialStep)}
				/>
			</HeaderPortal>

			<h2>{t('pages.constraints.title')}</h2>

			<h3 className="mb-4">{t('pages.constraints.subtitle')}</h3>

			<TutorialPopover
				onClose={closeHelp}
				placement={'top'}
				style={{ maxWidth: '400px' }}
				visible={visibleHelpStep == 2}
				body={
					'This gauge is a weighted consolidation of your constraints assessments, to a variable extent the result of your work on stakeholders'
				}
				onNext={onNext}
				hasNext={hasNext}
			>
				<HorizontalVulnerabilityScale
					value={score}
					vulnerabilities={vulnerabilities}
				></HorizontalVulnerabilityScale>
			</TutorialPopover>

			<div className="mb-3 mt-4 w-100" style={{ maxWidth: '800px' }}>
				{sortBy(props.constraints || [], 'name').map((o: any) => {
					let item = getItemAlteredByScenario(o, props.scenario)
					return (
						<EditableListItem
							key={item.id}
							onEdit={() => {
								setEditedItem(o)
							}}
							disabled={!!props.scenario}
							onDelete={askDeleteConfirmation}
							onCycleWeight={(values: any) => {
								let newWeight = values.weight ? values.weight + 1 : 1
								if (newWeight > 3) newWeight = 0
								handleUpdateConstraint(item.id, { ...values, weight: newWeight })
							}}
							weight={item.weight}
						>
							<>
								{item.name} {item.description ? ' - ' + item.description : ''}
								<MiniSlider
									className="ml-auto mr-2"
									key={item.id + '-' + props.scenario?.id}
									value={item.trend}
									style={{ width: '200px', flexShrink: 0 }}
									onChange={(val) => handleUpdateConstraint(item.id, { trend: val })}
									additionalMarkers={otherScenarios.map((sc) => {
										return {
											id: sc.id,
											name: sc.name || 'Baseline',
											value: getItemAlteredByScenario(o, sc).trend,
										}
									})}
								></MiniSlider>
							</>
						</EditableListItem>
					)
				})}
			</div>

			{editedItem && (
				<ConstraintEditionModal
					onHide={() => {
						setEditedItem(null)
					}}
					onDelete={() => {
						askDeleteConfirmation(editedItem.id)
						setEditedItem(null)
					}}
					onSave={(values: Object) => {
						handleUpdateConstraint(editedItem.id, values)
						setEditedItem(null)
					}}
					id={editedItem?.id}
					values={{
						name: editedItem?.name,
						weight: editedItem?.weight || 1,
						description: editedItem?.description || '',
					}}
				></ConstraintEditionModal>
			)}

			<div className="w-100 d-flex justify-content-center align-items-center">
				<div className="w-100 d-flex justify-content-center align-items-center" style={{ width: '33%' }}></div>
				<div className="w-100 d-flex justify-content-center align-items-center" style={{ width: '33%' }}>
					{props.constraints?.length < constraintsLimit && (
						<AddButton
							popover={
								<TutorialPopover
									onClose={closeHelp}
									placement={'top'}
									style={{ maxWidth: '400px' }}
									visible={visibleHelpStep == 1}
									body={<>Add constraints.</>}
									onNext={onNext}
									hasNext={hasNext}
								></TutorialPopover>
							}
							onClick={handleCreateConstraint}
						></AddButton>
					)}
				</div>
				<div className="w-100 d-flex justify-content-center align-items-center" style={{ width: '33%' }}>
					{' '}
					{props.constraints && props.constraints.length > 0 ? (
						<TutorialPopover
							onClose={closeHelp}
							placement={'top'}
							style={{ maxWidth: '400px' }}
							visible={visibleHelpStep == 3}
							body={
								<>
									Click here once you are done with the constraints to get into the Gerositus
									stakeholders navigator map
								</>
							}
							onNext={onNext}
							hasNext={hasNext}
						>
							<NavigationButton onClick={() => history.push('/projects/' + params?.id)}>
								Next step
							</NavigationButton>
						</TutorialPopover>
					) : (
						<NavigationButton onClick={() => history.push('/projects/' + params?.id)}>
							Skip
						</NavigationButton>
					)}
				</div>
			</div>
		</div>
	)
}
