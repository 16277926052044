import React from 'react'

import { useHistory, useParams, useRouteMatch } from 'react-router'
import { useTranslation } from 'react-i18next'
import gql from 'graphql-tag'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faClone, faEdit, faHome } from '@fortawesome/pro-light-svg-icons'
import ProjectModal from 'components/Modal/ProjectModal'
import ScenarioEditionModal from 'components/Modal/ScenarioEditionModal'

import { scenarioScore } from 'utils/ranking'
import { Tag, Tooltip } from 'antd'
import {
	useCreateScenarioMutation,
	useDeleteScenarioMutation,
	useUpdateConstraintScenariosMutation,
	useUpdateIndicatorScenariosMutation,
	useUpdateScenarioMutation,
	useUpdateStakefactorScenariosMutation,
} from 'graphql/generated'

import './ProjectNavigation.sass'
import ConfirmModal from 'components/Modal/ConfirmModal'
import { findIndex, pick } from 'lodash'
import { handleCreateScenario } from 'utils/graphql'

function ProjectNavigation(props: any) {
	let history = useHistory()
	const params: any = useParams()
	const { t } = useTranslation()

	const [editedItem, setEditedItem] = React.useState<any | null>(null)
	const [editedScenario, setEditedScenario] = React.useState<any | null>(null)

	let score = scenarioScore(props, props.scenario)

	const [createScenario] = useCreateScenarioMutation()
	const [deleteScenario] = useDeleteScenarioMutation()
	const [updateScenario] = useUpdateScenarioMutation()

	const [updateIndicatorScenarios] = useUpdateIndicatorScenariosMutation()
	const [updateConstraintScenarios] = useUpdateConstraintScenariosMutation()
	const [updateStakefactorScenarios] = useUpdateStakefactorScenariosMutation()

	const handleAskToCreateScenario = async (referenceScenario: any) => {
		let scenarioCount = props.scenarios.length

		props.pushModal(
			<ScenarioEditionModal
				onHide={() => {
					props.popModal()
				}}
				onSave={async (values: any) =>
					handleCreateScenario(
						params.id,
						props,
						referenceScenario,
						values,
						() => {
							props.popModal()
							props.onGoToScenario(scenarioCount)
						},
						{
							createScenario,
							updateIndicatorScenarios,
							updateConstraintScenarios,
							updateStakefactorScenarios,
						}
					)
				}
				id={false}
				values={{
					name:
						referenceScenario && referenceScenario.name !== null ? referenceScenario.name + ' - Copy' : '',
					description:
						referenceScenario && referenceScenario.description !== null
							? referenceScenario.description
							: '',
				}}
			></ScenarioEditionModal>
		)
	}

	// Update
	const handleUpdateScenario = async (id: string, values: Object) => {
		let index = findIndex(props.scenarios, (s: any) => s.id === id)
		console.log('index', index)
		let newScenario = {
			...pick(props.scenarios[index], ['description', 'name']),
			...pick(values, ['description', 'name']),
		}

		console.log('handleUpdateScenario', newScenario)

		const result = await updateScenario({
			variables: {
				id: id,
				...newScenario,
			},
			update(cache, { data: { updateScenario } }) {
				cache.modify({
					id: `Scenario:${id}`,
					fields: {
						name() {
							return updateScenario.name || newScenario.name
						},
						description() {
							return updateScenario.description || newScenario.description
						},
					},
				})
			},
			optimisticResponse: {
				updateScenario: {
					__typename: 'Scenario',
					id,
					...newScenario,
				},
			},
		})
	}

	const askDeleteConfirmation = async (id?: string) => {
		props.pushModal(
			<ConfirmModal
				title={t('models.scenario.messages.delete_confirmation')}
				t={t}
				onConfirm={() => handleDelete(id)}
				message={t('models.scenario.messages.delete_confirmation_explanation')}
			></ConfirmModal>
		)
	}

	// Delete
	const handleDelete = async (id: string | undefined) => {
		console.log('deleteScenario', id)

		if (id == null) {
			return
		}
		props.onGoToPreviousScenario()
		await deleteScenario({
			variables: { id: id },
			update(cache, { data: { deleteScenario } }) {
				cache.modify({
					id: `Project:${params.id}`,
					fields: {
						scenarios(existingScenariosRef, { readField }) {
							return existingScenariosRef.filter((ref: string) => id !== readField('id', ref))
						},
					},
				})
			},

			optimisticResponse: {
				deleteScenario: {
					__typename: 'Scenario',
					id: id,
				},
			},
		})
	}

	const fishboneIsActive = useRouteMatch('/projects/' + params?.id + '/fishbone')
	const indicatorsIsActive = useRouteMatch('/projects/' + params?.id + '/indicators')
	const constraintsIsActive = useRouteMatch('/projects/' + params?.id + '/constraints')

	const disableConstraints = process.env.REACT_APP_MODE === 'insiderisk'
	const disableIndicators = process.env.REACT_APP_MODE === 'insiderisk'
	const disableFishbone = process.env.REACT_APP_MODE === 'insiderisk'

	return (
		<div className="ProjectNavigation noselection">
			<a
				onClick={() => {
					props.setExpanded(false)
					history.push('/projects')
				}}
				className="mr-1 home"
			>
				<FontAwesomeIcon size="2x" icon={faHome} />
			</a>

			<div className="title text-nowrap d-flex align-items-center">
				{props.project?.name}{' '}
				<FontAwesomeIcon
					style={{ marginLeft: '0.5rem', fontSize: '1.2rem', cursor: 'pointer' }}
					icon={faEdit}
					onClick={() => setEditedItem(props.project)}
				/>
			</div>

			<div className="separator"></div>

			{!disableIndicators && (
				<>
					<a
						className={[indicatorsIsActive ? 'selected' : '', 'mr-2'].join(' ')}
						onClick={() => {
							history.push('/projects/' + params?.id + '/indicators')
						}}
					>
						{t('pages.indicators.title')}
					</a>
				</>
			)}
			{!disableConstraints && (
				<>
					<a
						className={[constraintsIsActive ? 'selected' : '', 'mr-2'].join(' ')}
						onClick={() => {
							history.push('/projects/' + params?.id + '/constraints')
						}}
					>
						{t('pages.constraints.title')}
					</a>
				</>
			)}
			<a
				className={[
					useRouteMatch({ path: '/projects/' + params?.id, exact: true }) ? 'selected' : '',
					'mr-2',
				].join(' ')}
				onClick={() => {
					history.push('/projects/' + params?.id)
				}}
			>
				{t('pages.navigator.title')}
			</a>
			<a
				className={[useRouteMatch('/projects/' + params?.id + '/scenarios') ? 'selected' : '', 'mr-2'].join(
					' '
				)}
				onClick={() => {
					history.push('/projects/' + params?.id + '/scenarios')
				}}
			>
				{t('pages.scenarios.title')}
			</a>

			{!disableFishbone && (
				<>
					<a
						className={[fishboneIsActive ? 'selected' : ''].join(' ')}
						onClick={() => {
							history.push('/projects/' + params?.id + '/fishbone')
						}}
					>
						{t('pages.fishbone.title')}
					</a>
				</>
			)}

			<div className="separator"></div>

			{!fishboneIsActive && (
				<div className="scenario">
					<div className="navigation mr-1">
						<a onClick={props.onGoToPreviousScenario} className="chevron">
							<FontAwesomeIcon icon={faChevronLeft} />
						</a>
						{props.scenarioIndex !== null ? props.scenarioIndex + 2 : 1} /
						{props.scenarioCount !== undefined ? props.scenarioCount + 1 : 1}
						<a onClick={props.onGoToNextScenario} className="chevron">
							<FontAwesomeIcon icon={faChevronRight} />
						</a>
					</div>
					{props.scenario?.description ? (
						<Tooltip title={props.scenario?.description}>{props.scenario?.name || 'Baseline'}</Tooltip>
					) : (
						props.scenario?.name || 'Baseline'
					)}

					<Tooltip title="Scenario score">
						<small className="ml-1">({score.toFixed(0)})</small>
					</Tooltip>

					<div className="ml-2">
						{props.scenarioIndex !== null && (
							<Tooltip title={t('models.scenario.actions.clone')}>
								<Tag
									onClick={() => {
										handleAskToCreateScenario(props.scenario)
									}}
									className={'tag unselected'}
								>
									<FontAwesomeIcon icon={faClone}></FontAwesomeIcon>
								</Tag>{' '}
							</Tooltip>
						)}

						{props.scenarioIndex !== null && (
							<Tooltip title={t('models.scenario.actions.edit')}>
								<Tag
									onClick={() => {
										setEditedScenario(props.scenario)
									}}
									className={'tag unselected'}
								>
									<FontAwesomeIcon icon={faEdit}></FontAwesomeIcon>
								</Tag>{' '}
							</Tooltip>
						)}

						{props.scenarioIndex === null && (
							<Tooltip title={t('models.scenario.actions.create_new')}>
								<Tag onClick={() => handleAskToCreateScenario()} className={'tag unselected'}>
									+
								</Tag>{' '}
							</Tooltip>
						)}
					</div>
				</div>
			)}

			{editedItem && (
				<ProjectModal
					onHide={() => {
						setEditedItem(null)
					}}
					onSave={(values: Object) => {
						props.onUpdate(editedItem.id, values)
						setEditedItem(null)
					}}
					id={editedItem?.id}
					values={{
						name: editedItem?.name,
						description: editedItem?.description,
					}}
				></ProjectModal>
			)}

			{editedScenario && (
				<ScenarioEditionModal
					onHide={() => {
						setEditedScenario(null)
					}}
					onDelete={() => {
						askDeleteConfirmation(editedScenario.id)
						setEditedScenario(null)
					}}
					onSave={(values: Object) => {
						handleUpdateScenario(editedScenario.id, values)
						setEditedScenario(null)
					}}
					id={editedScenario?.id}
					values={{
						name: editedScenario?.name,
						description: editedScenario?.description || '',
					}}
				></ScenarioEditionModal>
			)}
		</div>
	)
}

export default ProjectNavigation
