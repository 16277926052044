// @ts-nocheck
import { useSpring, animated, interpolate } from 'react-spring';
import { useGesture } from 'react-with-gesture';

import './NavigationButton.sass';

export const NavigationButton = (props: any) => {
	const [bind, { down }] = useGesture();
	const { x, size } = useSpring({
		size: down ? 1.1 : 1,
		immediate: (name: string) => down && name === 'x',
	});

	return (
		<animated.div {...bind()} style={{ transform: interpolate([x, size], (x, s) => `scale(${s})`) }}>
			<div onClick={props.onClick} className="NavigationButton">
				{props.children}
			</div>
		</animated.div>
	);
};

export default NavigationButton;
