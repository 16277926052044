import React, { Component } from 'react'

import moment from 'moment'

import { Modal, Button, Form, Input, DatePicker } from 'antd'

const formLayout = {
	labelCol: { span: 24 },
	wrapperCol: {
		span: 24,
	},
}

export const StakefactorCommentModal = (props: any) => {
	const [form] = Form.useForm()

	const handleFormSubmit = () => {
		console.log('handleFormSubmit')
		form.validateFields()
			.then((values) => {
				props.onSave(values)
				props.onHide()
			})
			.catch((errorInfo) => {
				console.log('error', errorInfo)
			})
	}

	const footer = (
		<div style={{ display: 'flex' }}>
			<Button
				onClick={() => {
					props.onDelete(props)
					props.onHide()
				}}
				danger={true}
				style={{ marginRight: 'auto' }}
			>
				Delete
			</Button>
			<Button
				onClick={() => {
					props.onHide()
				}}
			>
				Cancel
			</Button>
			<Button onClick={handleFormSubmit} type="primary">
				Save
			</Button>
		</div>
	)

	return (
		<Modal
			visible={true}
			title="Comment"
			okButtonProps={{ style: { display: 'none' } }}
			onCancel={props.onHide}
			cancelText="Close"
			footer={footer}
		>
			<Form
				{...formLayout}
				form={form}
				onFinish={(values) => {
					props.onChange(props.id, values)
					props.onHide()
				}}
				initialValues={{
					...props.values,
					commentedAt: props.values && props.values.commentedAt ? moment(props.values.commentedAt) : moment(),
				}}
			>
				<Form.Item name="description" label="Description">
					<Input.TextArea></Input.TextArea>
				</Form.Item>
				<Form.Item name="commentedAt" label="Date">
					<DatePicker />
				</Form.Item>
			</Form>
		</Modal>
	)
}

export default StakefactorCommentModal
