import React, { Component } from 'react'
import { NavBar } from './components/navigation/NavBar'
import { Router, Route, Switch } from 'react-router-dom'
import { useTranslation, initReactI18next } from 'react-i18next'

import SelectionContext from './components/Utils/SelectionContext'
import SelectionProvider from './components/Utils/SelectionProvider'
import ModalContext from './components/Utils/ModalContext'
import ModalProvider from './components/Utils/ModalProvider'
import SubscriptionProvider from './components/Utils/SubscriptionProvider'
import SubscriptionContext from './components/Utils/SubscriptionContext'

import { Layout } from 'antd'
import { Profile } from './pages/profile/Profile'
import { Projects } from './pages/Projects/Projects'
import { Project } from './pages/Project/Project'

import { history } from './utils/history'
import Header from './layout/header/Header'

import { PrivateRoute as PrivateRouteWithAuth0 } from './components/navigation/PrivateRoute'
import { PrivateRoute as PrivateRouteWithoutAuth0 } from './components/navigation/PrivateRouteWithoutAuth0'

import 'bootstrap/scss/bootstrap.scss'

import 'antd/dist/antd.css'

import './App.sass'
import { ThankYou } from 'pages/thankyou/ThankYou'

const SelectionConsumer = SelectionContext.Consumer
const ModalConsumer = ModalContext.Consumer
const SubscriptionConsumer = SubscriptionContext.Consumer

const PrivateRoute = process.env.REACT_APP_FEATURE_TOKEN_LOGIN ? PrivateRouteWithoutAuth0 : PrivateRouteWithAuth0

interface Props {
	returnTo: string
	paymentPublicKey: string
}
interface State {}

export default class App extends Component<Props, State> {
	state = {
		selection: null,
		isExpanded: false,
	}

	render() {
		const forceHeader =
			!history.location.pathname.match(/\/projects\/[a-f0-9\-]+.*/) ||
			history.location.pathname == '' ||
			history.location.pathname == '/'
		console.log(history.location)

		return (
			<div
				id="App"
				className={[
					history.location.pathname.match(/^\/projects\/$/) ? 'light' : 'dark',
					this.state.isExpanded && !forceHeader ? 'expanded' : '',
				].join(' ')}
			>
				<Router history={history}>
					<SubscriptionProvider>
						<SelectionProvider>
							<ModalProvider>
								<SubscriptionConsumer>
									{(subscriptionProps) => (
										<SelectionConsumer>
											{(selectionProps) => (
												<Layout
													className={'Layout'}
													style={{ paddingTop: this.state.isExpanded ? '0px' : '88px' }}
												>
													<Header visible={!this.state.isExpanded || forceHeader}></Header>
													<Layout.Content>
														<ModalConsumer>
															{(modalProps) => (
																<Switch>
																	<PrivateRoute key="profile" path="/profile">
																		<Profile
																			paymentPublicKey={
																				this.props.paymentPublicKey
																			}
																			returnTo={this.props.returnTo}
																			{...subscriptionProps}
																		></Profile>
																	</PrivateRoute>
																	<PrivateRoute key="project" path="/projects/:id">
																		<Project
																			{...modalProps}
																			{...selectionProps}
																			{...subscriptionProps}
																			expanded={this.state.isExpanded}
																			setExpanded={(value) => {
																				this.setState({ isExpanded: value })
																			}}
																		></Project>
																	</PrivateRoute>
																	<PrivateRoute key="projects" path="/projects">
																		<Projects
																			{...modalProps}
																			{...subscriptionProps}
																			expanded={true}
																			setExpanded={(value) => {
																				this.setState({ isExpanded: value })
																			}}
																		></Projects>
																	</PrivateRoute>
																	<PrivateRoute key="thankyou" path="/thankyou">
																		<ThankYou
																			{...modalProps}
																			{...subscriptionProps}
																		></ThankYou>
																	</PrivateRoute>

																	<PrivateRoute
																		key="projectsRoot"
																		exact={true}
																		path=""
																	>
																		<Projects
																			{...modalProps}
																			{...subscriptionProps}
																			isFeatureEnabledProfile={
																				!process.env
																					.REACT_APP_FEATURE_PROFILE ||
																				process.env
																					.REACT_APP_FEATURE_PROFILE ===
																					'enabled'
																			}
																		></Projects>
																	</PrivateRoute>
																</Switch>
															)}
														</ModalConsumer>

														<ModalConsumer>
															{({ modals, popModal }) => {
																return modals.map((m, index) => {
																	return React.cloneElement(m, {
																		key: m.key || index,
																		show: true,
																		visible: true,
																		onHide: popModal,
																	})
																})
															}}
														</ModalConsumer>
													</Layout.Content>
												</Layout>
											)}
										</SelectionConsumer>
									)}
								</SubscriptionConsumer>
							</ModalProvider>
						</SelectionProvider>
					</SubscriptionProvider>
				</Router>
				<div id="copyright">
					© {new Date().getFullYear()} by Marco Mancesti – All rights reserved. Developed by Visto&Vista SARL
					<small>
						{process.env.REACT_APP_MODE} - {process.env.REACT_APP_ENV}
					</small>
				</div>
			</div>
		)
	}
}
