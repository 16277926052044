import { faEdit } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Popover, Tooltip } from 'antd'
import { FishboneBranchPopover } from './FishboneBranchPopover'
import { FishbonePoint } from './FishbonePoint'
import { sortBy, sumBy, range } from 'lodash'

import './FishboneBranch.sass'
import { colorToPercent, getScoreColor } from 'utils/ranking'

const sizes = { 1: 5, 2: 7, 3: 9 }

export const FishboneBranch = (props: any) => {
	const startX = 10
	const startY = 30
	const endX = props.width
	const innerWidth = endX - startX
	const innerHeight = props.height - startY

	const y = props.position == 'bottom' ? 2 * props.height - startY : startY
	const vulnerabilityY = props.position == 'bottom' ? y + 8 : y - 11
	const initialY = props.position == 'bottom' ? props.height * 2 - startY - 5 : startY + 5

	const branchLength = Math.sqrt((props.height - y) * (props.height - y) + innerWidth * innerWidth)
	props.debug && console.log('branchLength', innerWidth, innerHeight, branchLength)

	const allPoints = props.points || []
	//sortBy(props.points || [], (p) => (p && p.completion) || 0).reverse()
	const segmentSize = branchLength / allPoints.length

	props.debug && console.log('segmentSize', segmentSize)

	let pointsWeight = 0
	allPoints.forEach((p) => (pointsWeight += p.weight || 1))

	const vulnerability =
		allPoints && allPoints.length > 0
			? sumBy(allPoints, (p) => colorToPercent(p.trend !== null ? p.trend : '50') * (p.weight || 1)) /
			  pointsWeight
			: 50

	console.log('FishboneBranch pointsWeight', pointsWeight)
	console.log('FishboneBranch vulnerability', vulnerability)

	const completion =
		allPoints && allPoints.length > 0
			? sumBy(allPoints, (p) => (p.completion || 0) * (p.weight || 1)) / pointsWeight
			: 0
	console.log('FishboneBranch completion', completion)
	const color = getScoreColor(vulnerability)

	let numberOfRaysMax = 39
	let numberOfRays = Math.floor(((completion || 0) / 100) * numberOfRaysMax)

	const points = allPoints.map((p, index) => {
		const availableHeight = innerHeight * 0.95
		const availableWidth = innerWidth * 0.95

		const segmentWidth = availableWidth / allPoints.length
		const segmentHeight = availableHeight / allPoints.length

		const posX = segmentWidth * index + 45 + segmentWidth * 0.2
		const posY =
			initialY +
			(props.position == 'bottom' ? -1 : 1) * segmentHeight * index +
			(props.position == 'bottom' ? -1 : 1) * segmentHeight * 0.15

		return (
			<FishbonePoint
				key={p.id}
				{...p}
				x={posX}
				y={posY}
				height={availableHeight / allPoints.length}
				size={0.15 * segmentSize}
				onUpdate={props.onUpdateItem}
				onEdit={props.onEditItem}
			></FishbonePoint>
		)
	})

	return (
		<g className="FishboneBranch" transform={'translate(' + props.leftOffset + ')'}>
			<Popover
				trigger={'click'}
				title={
					<div>
						{props.name}{' '}
						<FontAwesomeIcon
							style={{ marginLeft: '0.5rem', fontSize: '1.2rem', cursor: 'pointer' }}
							icon={faEdit}
							onClick={() => props.onEditBranch(props)}
						/>
					</div>
				}
				content={<FishboneBranchPopover onUpdate={props.onUpdateBranch} {...props}></FishboneBranchPopover>}
			>
				<g>
					<Tooltip
						title={
							<div>
								<div className="d-flex mb-2">
									<div style={{ width: '110px', flexShrink: 0 }}>Completion</div>
									<div>
										<b>{completion.toFixed(0)}%</b>
									</div>
								</div>

								<div className="d-flex mb-2">
									<div style={{ width: '110px', flexShrink: 0 }}>Status</div>
									<div
										className="MiniIndicatorVulnerabilityScale"
										style={{ width: '200px', marginTop: '10px' }}
									>
										<div id={'bar_marker'} style={{ left: vulnerability + '%' }}></div>
									</div>
								</div>

								<div className="d-flex">
									<div style={{ width: '110px', flexShrink: 0 }}>Description</div>
									<div>
										<small>{props.description || 'No description'}</small>
									</div>
								</div>
							</div>
						}
					>
						<g
							transform={
								'translate(-50, ' + (props.position == 'bottom' ? props.height * 2 - 5.5 : 6.5) + ')'
							}
						>
							<clipPath id="cut-off">
								<rect x="0" y="-9" width="18" height="18" />
							</clipPath>

							<circle r={sizes[props.weight || 1]} fill={vulnerability == '50' ? '#E70549' : color} />
							<circle
								r={sizes[props.weight || 1]}
								fill={vulnerability == '50' ? '#24CCB8' : color}
								clipPath="url(#cut-off)"
							/>

							{range(0, numberOfRays).map((r, index) => {
								return (
									<g
										key={index}
										className={'completion '}
										transform={'rotate(' + ((index / numberOfRaysMax) * 360 - 90) + ') '}
									>
										<line stroke="#FFFFFF66" x1={12} x2={15} y1={0} y2={0}></line>
									</g>
								)
							})}
						</g>
					</Tooltip>

					<text
						className={'title'}
						fontSize={16}
						dx={startX - 40}
						dy={props.position == 'bottom' ? props.height * 2 - 2 : 11}
						fill={'white'}
					>
						{props.name}
					</text>
				</g>
			</Popover>

			<line x2={startX} x1={endX} y2={y} y1={props.height} stroke="#A6B9C8" strokeLinecap="round"></line>
			<g
				className="add"
				onClick={props.onCreateItem}
				transform={'translate(' + ((endX - startX) / 2 - 35) + ' ' + (y / 2 + props.height / 2) + ')'}
			>
				<circle r={15} fill={'#CCCCCC'}></circle>
				<text fill="white" fontSize={36} dx={-10} dy={13}>
					+
				</text>
			</g>
			<g className="points">{points}</g>
		</g>
	)
}
